import React from "react";
import styled from "styled-components";
import gql from "graphql-tag";
import { Query, withApollo } from "react-apollo";
import EventSection from "./Events";
import { FlatButton } from "../../commons";

const Container = styled.div`

  & .contain {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  }

  & .head-title {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.66px;
    line-height: 23px;
    color: #141d60;
  }

  & .latest {
    position: relative;
    left: 50px;
    @media (max-width: ${props => props.theme['mobileBreakpoint']}) {
      position: relative !important;
      left: 80px
    }
    @media (max-width: 767px) {
      position: static !important;
    }
  }

  & .event-time-description {
    @media (max-width: ${props => props.theme['mobileBreakpoint']}) {
      max-width: 100%;
    }
  }

  & .date-time {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.59px;
    line-height: 23px;
    color: #3a3a3a;
  }

  & .next-event {
    color: #000000;
    font-family: Lato;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    @media (max-width: ${props => props.theme['mobileBreakpoint']}) {
     margin-top: 50px;
    }
  }

  & .events-img {
    width: 262px;
    height: 152px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
    @media (max-width: ${props => props.theme['mobileBreakpoint']}) {
      width: 100%;
      height: auto;
      max-height: 390px;
    }
  }

  & .blog-read-more {
    position: absolute;
    bottom: -5px;
  }

  & .description{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  }

  .event-background {
    background-color: #e9e3ce;
    padding: 50px;
    @media (max-width: ${props => props.theme['mobileBreakpoint']}) {
      padding: 10px;
    }
  }

`;

const LIST_LATEST_EVENTS_BLOGS = gql`
  query ListLatestBlogEvents {
    upcomingEvents{
      id
      title
      slug
      textDescription
      image
      commenceDate
      eventType
      city
    }
  }
`;


const IndexEventSection = ({history}) => {
  return (
    <Container>
      <Query query={LIST_LATEST_EVENTS_BLOGS}>
      {
        ({ loading, data, error }) => {
          if(loading) return<div></div>;
          if(error) return <div></div>;
          return ( <>
          { data.upcomingEvents && data.upcomingEvents.length &&
             <div className="event-background contain my-5">
               <div className="row">
                 <div className="col-10 mx-auto">
                  <h6 className="next-event md-blog-event-title">NEXT EVENT</h6>
                  </div>
                  </div>
              <EventSection events={data.upcomingEvents} />
              <div className="row mb-3 mb-md-0">
                <div className="col-10 mx-auto">
              <a href="/event">
              <FlatButton>VIEW ALL</FlatButton>
              </a>
              </div>
              </div>
            </div>
          }
        </>
        );
        }
      }
      </Query>
    </Container>
  );
};

export default withApollo(IndexEventSection);

