import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { FlatButton } from "../../commons";
import styled from "styled-components";
import {
  replaceStaticUrl,
} from "./../../../utils/";


function BlogSection({ blogs, history }) {
  return blogs.map((blog) => (
        <div className="col-lg-4 pl-4 my-3 my-lg-0 col-sm-12">
          <Link to={`/blog/${blog.slug}`}>
            <img
              className="img-fluid d-block blog-img"
              src={replaceStaticUrl(blog.image)}
              alt="No image"
            />
          </Link>
          <Link to={`/blog/${blog.slug}`}>
            <h6 className="head-title mt-3 mb-0">{blog.title}</h6>
          </Link>
          <Link to={`/blog/${blog.slug}`}>
          <p className="date-time">
            {blog.publicationDate}&nbsp; |&nbsp;
            <span className="date-author-color">By:&nbsp;</span>
            {blog.authors.map(({ name }) => name).join(", ")}
          </p>
          </Link>
          <p className="description">{ReactHtmlParser(blog.textDescription)}</p>
          {/* Added HTML parser for quotes to work */}
          <FlatButton onClick={(e) => history.push(`/blog/${blog.slug}`)}>
            READ MORE
          </FlatButton>
        </div>
  ));
}

export default withRouter(BlogSection);