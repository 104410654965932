import { Footer } from './Footer';
import { connect } from 'react-redux'

const mapStateToProps = ({
  cmsData: {
    subscription
  }
}, ownProps) => {
  return {
    subscriptionHead: subscription && subscription.pageData && subscription.pageData.footerHead,
    subscriptionText: subscription && subscription.pageData && subscription.pageData.footer
  }
}

export default connect(
  mapStateToProps,
)(Footer);

