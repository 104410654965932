import React, { useEffect, useState } from 'react';
import './BottomBar.css';
import styled from "styled-components";
import { replaceStaticUrl } from '../../utils';


const CardContainerInsideIssue = styled.div`
  cursor: pointer;
  // & > div {
  //   @media (min-width: ${props => props.theme["mobileBreakpoint"]}) {
  //     padding-left: 0;
  //     padding-right: 0;
  //   }
  // }

  .firstslide .title {
      position: absolute;
      top: 10px;
      right: 10px;
      left: 15px;
    color: white;
      padding:15px;
      font-size: 15px;
      line-height: 17px;
      @media (max-width:  1139px) {
        font-size: 12px;
        line-height: 14px;
      }
  }

  .credits {
    position: absolute;
    bottom: 10px;
    font-size: 8px;
    line-height: 10px;
    font-weight: normal;
    // left:0px;
    // right:15px;
    color: white;
    // margin-left: 10px;
    margin-right:10px;
    padding: 10px 15px 10px;
    text-align: left;
    @media (max-width:  1139px) {
        font-size: 8px;
        line-height: 10px;
        font-weight: 300;
      }
   
}

.leftslide .title{
    color: white;
    padding-bottom: 30px;
    font-weight: 600;
    font-size: 25px;
    line-height: 28px;
    @media (max-width:  1139px) {
      font-size: 18px;
      line-height: 21px;
    }
    
}

.leftslide, .rightslide {
    padding: 10px;
}

.leftslide .author-name{
    color: white;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    @media (max-width:  1139px) {
      font-size: 14px;
      line-height: 17px;
    }
}

.leftslide .author-profile{
    color: white;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    @media (max-width:  1139px) {
      font-size: 14px;
      line-height: 17px;
    }
}

.rightslide p {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    @media (max-width:  1139px) {
      font-size: 14px;
      line-height: 17px;
    }
}

.img-fluid-carousel {
  max-width: 100%;
  width: 400px;
  @media (max-width: 1139px) {
      width: 300px:
    }
    @media (max-width: 767px) {
      width: 200px:
     }
  }
  
  // .carousel-inside {
  //   aspect-ratio: 400/450;
    
  //   @media (max-width: 767px) {
  //     aspect-ratio: 200/250;
  //   }
  // }

  // .carousel-inside-small {
  //   aspect-ratio: 400/350;
    
  //   @media (max-width: 767px) {
  //     aspect-ratio: 200/250;
  //   }
  // }

//   p {
//     @media (max-width:  ${props => props.theme["mobileBreakpoint"]}) {
//         font-size: 10px;
//       }
//   }



 
`;

function CarouselCard({ slide }) {

    const {
        id,
        slideType,
        slideNumber,
        leftCardTitle,
        leftCardAuthorName,
        leftCardAuthorProfile,
        leftCardBackgroundColour,
        leftCardFontColour,
        centerCardImage,
        centerCardTitle,
        centerCardCredits,
        centerCardFontColour,
        rightCardSynopsis,
        rightCardBackgroundColour,
        rightCardFontColour,
        slideSide
    } = slide || {};

    // const newCenterCardImage = 'http://ec2-15-207-223-84.ap-south-1.compute.amazonaws.com/'+centerCardImage;
    const newCenterCardImage = replaceStaticUrl(centerCardImage);
    

    const leftCardFont = leftCardFontColour != undefined? leftCardFontColour.value: 'white';
    const rightCardFont =  rightCardFontColour != undefined? rightCardFontColour.value: 'black';
    const centerCardFont =  centerCardFontColour != undefined? centerCardFontColour.value: 'white';

    const leftCardBackground = leftCardBackgroundColour;
    const rightCardBackground = rightCardBackgroundColour;
    



    return (
        <CardContainerInsideIssue
            className={'col-lg-12 inside-issue'}
        >
            {
                slideSide === 'left' &&
                <div className="img-fluid-carousel carousel-inside text-center d-flex flex-column justify-content-center " style={{height:'100%'}}>
                    <div className=" inside-card leftslide carousel-inside-small text-center d-flex flex-column"  style={{backgroundColor:leftCardBackground, alignContent: 'center', justifyContent: 'center', display: 'flex', height:'100%' }}>
                        <div className="title" style={{color:leftCardFont}}>{leftCardTitle}</div>
                        <div className="author-name" style={{color:leftCardFont}}>{leftCardAuthorName}</div>
                        <div className="author-profile" style={{color:leftCardFont}}>{leftCardAuthorProfile}</div>
                    </div>
                </div>

            }
            {
                slideSide === 'right' &&
                <div className="img-fluid-carousel carousel-inside text-center d-flex flex-column justify-content-center " style={{height:'100%'}}>
                    <div className="inside-card rightslide carousel-inside-small text-center d-flex flex-column " style={{backgroundColor:rightCardBackground, alignContent: 'center', justifyContent: 'center', display: 'flex', height:'100%' }}>
                        <p style={{ color: rightCardFont}}>{rightCardSynopsis}</p>
                    </div>
                </div>

            }
            { slideType === 'A_1' && slideSide === "center" &&
                <div className="inside-card">
                    <img
                        alt=""
                        className='img-fluid-carousel carousel-inside '
                        src={replaceStaticUrl(newCenterCardImage)}
                    />
                    <div className="credits" style={{ textAlign: 'left', color: centerCardFont }}> {centerCardCredits}</div>
                </div>
            }

            { slideType === 'A_0' && slideSide === "center" &&
                <div className="inside-card firstslide">
                    <img
                        alt=""
                        className='img-fluid-carousel carousel-inside '
                        src={newCenterCardImage}
                    />
                    <div className="title" style={{ textAlign: 'left', color: centerCardFont }}>{centerCardTitle} </div>
                    <div className="credits" style={{ textAlign: 'left', color: centerCardFont }}> {centerCardCredits}</div>
                </div>
            }

            { slideType === 'A_2' && slideSide === "center" &&
                <div className="inside-card">
                    <img
                        alt=""
                        className='img-fluid-carousel carousel-inside '
                        src={replaceStaticUrl(newCenterCardImage)}
                    />
                </div>
            }



        </CardContainerInsideIssue>
    );
}

export default CarouselCard;

