import React,{ useEffect, useState } from "react";
import { withRouter } from "react-router";
import styled, { ThemeProvider } from "styled-components";
// import background from "./images/background4.jpg";
import historyBackground from "../../images/historyBackground.jpg";
import { Route, Switch } from "react-router";
import MobileHeader from "../MobileHeader";
import Header from "../Header";
import Home from "../Home/";
import ProductList from "../ProductList/index.js";
import ProductDetails from "../ProductDetails/";
import LoginForm from "../LoginForm";
import SignupForm from "../SignupForm";
import About from "../About/";
import Team from "../Team/";
import Advertise from "../Advertise/";
import Collaborate from "../Collaborate/";
import Supporters from "../Supporters/";
import Trustees from "../Trustees/";
import Donate from "../Donate.jsx";
import ContactusForm from "../Contactus/";
import Checkout from "../Checkout/";
import ProtectedRoute from "../commons/ProtectedRoute";
import Account from "../Account/";
import SearchResultsContainer from "../Search/SearchResultsContainer";
import ResetPassword from "../ResetPassword/index.jsx";
import NotificationManager from "../NotificationManager/";
import { PrivacyPolicy } from "../PrivacyPolicy.jsx";
import { TermsAndConditions } from "../TermsAndConditions.jsx";
import { NotFound } from "../404.jsx";
import Footer from "../Footer";
import Subscriptions from "../Subscriptions";
import History from '../History/index';
import Blog from '../Blogs'
import BlogDetailPage from '../Blogs/BlogDetailPage'
import Events from "../Events";
import SingleEvent from "../Events/SingleEvent";
import SubmitProposals from "../SubmitProposals/";
import Bookstores from '../Bookstores/';
import Player from "./../Player/";
import MovieList from "./../MovieList/";
import Banner from "../Banner"
import BottomBar from '../ProductDetails/BottomBar';


const MainContainer = styled.div`
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  padding-bottom: 25vh;
  animation: fadeInAnimation ease 3s;

  @keyframes fadeInAnimation { 
  0% { 
      opacity: 0;
  } 
  100% { 
      opacity: 1;
   } 
  }
  @media (min-width: ${props => props.theme["mobileBreakpoint"]}) {
    padding: 10vh 2.5vw 25vh;
  }
  min-height: 100%;
  & > div {
    background: white;
  }
  #app {
    max-width: 1440px !important;
    // appHorizontalPadding
    padding-left: ${props => props.theme["appHorizontalPadding"]};
    padding-right: ${props => props.theme["appHorizontalPadding"]};
    // This counters the appHorizontalPadding above
    .full-width {
      margin-left: -15px;
      margin-right: -15px;
    }
    .full-width-lg-below {
      @media (max-width: 992px) {
        margin-left: -15px;
        margin-right: -15px;
      }
    }
  }
  @media (min-width: 1200px) {
    .container {
      max-width: 1440px;
    }
  }
  .color-red {
    color: ${props => props.theme["primaryColor"]};
  }
  .color-black {
    color: black;
  }
  .bg-gray {
    background-color: #F8F8F8;
  }
  .heading {
    color: #000000;
    font-family: ${props => props.theme["$font-secondary-medium"]};
    font-size: ${props => props.theme["$font-size-lg"]};
    font-weight: ${props => props.theme["$weight-regular"]};
    letter-spacing: 1px;
    line-height: 57px;
    text-align: center;
    margin-bottom: 10px;
  }
`;
const BgImageToggler = ({ location: {pathname}, siteBackgroundImage}) =>{
  const backGround = { backgroundImage : `url(${siteBackgroundImage})` };
  const backGroundColor = { backgroundColor : "white" };
  const [showBanner, toggleBanner]= useState(true);

  return(
    <MainContainer style = { backGround }>
    {showBanner && <Banner toggleBanner={toggleBanner}/>}
      <div id="app" style = { backGroundColor } className="mx-auto position-relative">
        <MobileHeader />
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/categories" component={ProductList} />
          <Route
            exact
            path="/product/:id"
            component={ProductDetails}
          />
          <Route exact path="/login" component={LoginForm} />
          <Route exact path="/signup" component={()=><SignupForm columnSize='col-12 col-md-8 offset-lg-1 col-lg-5 row'/>} />
          <Route exact path="/aboutus" component={About} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/advertise" component={Advertise} />
          <Route
            exact
            path="/collaborate"
            component={Collaborate}
          />
          <Route exact path="/supporters" component={Supporters} />
          <Route exact path="/trustees" component={Trustees} />
          <Route
            exact
            path="/contactus"
            component={ContactusForm}
          />
          <Route exact path="/donate" component={Donate} />
          <Route path="/checkout" component={Checkout} />
          <ProtectedRoute
            exact
            path="/myaccount"
            component={Account}
          />
          <Route
            exact
            path="/search"
            component={SearchResultsContainer}
          />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route
            path="/subscriptions"
            component={Subscriptions}
          />
          <Route
              exact
              path="/history"
              component={History}
          />
          <Route
              exact
              path="/blog"
              component={Blog}
          />
          <Route
              exact
              path="/blog/:slug"
              component={BlogDetailPage}
          />
          <Route
              exact
              path="/event"
              component={Events}
          />
          <Route
              exact
              path="/event/:slug"
              component={SingleEvent}
          />
          <Route
              exact
              path="/submit-proposals"
              component={SubmitProposals}
          />
          <Route
              exact
              path="/bookstores"
              component={Bookstores}
          />
          <Route
              exact
              path="/player"
              component={Player}
          />
          <Route
              exact
              path="/movies"
              component={MovieList}
          />
          <Route path="*" component={NotFound} />
        </Switch>
        <Footer applyBackGroundColor = { true } />
        
        <NotificationManager />
      </div>
    </MainContainer>
  );
}
export default withRouter(BgImageToggler);
