import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import CheckoutPayment from "../CheckoutPayment/";
import { withRouter } from "react-router-dom";
import { RaisedButton } from "./../../commons/";
import VoucherInput from "./VoucherInput/";
import FbPixel from "../../../utils/fbPixel"

const Wrapper = styled.div`
  h2 {
    color: #000000;
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.7px;
    line-height: 34px;
  }

  .bg-gray {
    background-color: #f8f8f8;

    p {
      color: #000000;
      font-family: Lato;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.7px;
      line-height: 34px;
    }
  }

  .checkout-proceed-button {
    background-color: #ec1d24;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .shipping-pending {
    color: #3a3a3a;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.7px;
    line-height: 34px;
  }

  z-index: 9 !important;

`;

const CheckoutSidebar = ({
  location,
  currency_preference,
  cart, 
  setCheckoutStatus
}) => {
  const [requiresShipping, setRequiresShipping] = useState(false);
  const {
    checkoutId,
    lines,
    subtotalPrice: { gross: { localized } = {} } = {},
    totalPrice: { gross: { localized: totalLocalized, amount } = {} } = {},
    shippingMethod,
    shippingAddress,
    discountAmount,
  } = cart;
  useEffect(() => {
    lines.map(lineItem => {
      if (lineItem && lineItem.variant && !lineItem.variant.isDigital) {
        setRequiresShipping(true);
      }
    });
  }, [lines]);

  return (
    <Wrapper className="sticky-top">
      { lines && lines.length > 0 && (
        <Row>
          <Col xs="12">
            <h2 style={{ margin: 15 }}>ORDER SUMMARY</h2>
            <div className="bg-gray px-3 py-4">
              <p>
                SUBTOTAL: <span className="float-right">{localized}</span>
              </p>
              <p>
                SHIPPING:
                {Object.entries(shippingMethod).length === 0 &&
                shippingMethod.constructor === Object ? (
                  <span className="shipping-pending float-right">
                    {!requiresShipping ? "FREE" : "To be determined"}
                  </span>
                ) : (
                  <span className="float-right">
                    {currency_preference === "USD"
                      ? shippingMethod.priceUsd.localized
                      : shippingMethod.priceInr.localized}
                  </span>
                )}
              </p>
              {
                discountAmount > 0 && amount > 0 && 
                [
                  <hr />,
                  <p>
                    Discount:
                    {
                      <span className="float-right">
                        -{currency_preference === "INR"? "₹": "$"}{discountAmount? discountAmount: "To be determined"}
                      </span>
                    }
                  </p>
                ]
              }
              <hr />
              {
                amount > 0 && <p>
                  TOTAL:
                  {
                    <span className="float-right">{totalLocalized? totalLocalized: "To be determined"}</span>
                  }
                </p>
              }
              {
                amount <= 0 && <p className="text-center">
                  Free
                </p>
              }
              {location.pathname === "/checkout/cart" && (
                <Link to={"/checkout/address"} class="checkout-proceed-button">
                  <RaisedButton
                    className="w-100 btn-hover"
                    
                    onClick={()=> {
                    const contentIds = cart.lines.map((line) => line.variant.product.id);
                    const contents = cart.lines.map((line) => {return {"id": line.variant.product.id, 
                                                                      "quantity": line.quantity}});
                    FbPixel.track('InitiateCheckout', 
                    {"content_category": "product",
                      "content_type": "product",
                      "value": cart.totalPrice.net.amount,
                      "content_ids": contentIds,
                      "contents": contents,
                      "num_items": cart.totalQuantity,
                      "currency": cart.totalPrice.net.currency})
                    }}
                  >
                    Checkout
                  </RaisedButton>
                </Link>
              )}
              {location.pathname === "/checkout/address" && <CheckoutPayment setCheckoutStatus={setCheckoutStatus}/>}
            </div>
            {
              checkoutId && <VoucherInput />
            }
          </Col>
        </Row>
      )}
    </Wrapper>
  );
};

export default withRouter(CheckoutSidebar);
