import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";

import Carousel from "react-multi-carousel";

const TestimonialContainer = styled.div`
  max-width: 1100px;
  margin-left: auto !important;
  margin-right: auto !important;
  .text-center{
    font-family: ${props => props.theme['$font-secondary-medium']};
    font-size: 42px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 57px;
  }
  
  p{
    margin-bottom: 0px;
font-weight: 400;
  }
  .blockquote {
font-size: 16px;
font-weight: 400;
letter-spacing: 0.59px;
line-height: 23px;
  }

  .closing-quote, .opening-quote {
  color: #ec1d24;
  font-family: "Cormorant Medium";
  font-size: 42px;
  font-weight: 500;
  }

  .closing-quote {
    position: absolute;
    right: 50px;
  }

  .opening-quote {
    position: absolute;
    top: -50px;
  }
`;


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const TestimonialSection = ({ testimonials, title }) => {
  return (
    <TestimonialContainer className="full-width">
      <div className="mt-5 mb-5">
        <p className={(testimonials.length > 0 ? 'text-center d-block' : 'text-center d-none')} >{title}</p>
        <Carousel
          className="row mt-5"
          responsive={responsive}
          showDots={true}
          removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
        >
        {
          testimonials.map(testimonial =>  {
            return  <div className="col-12 my-5 box-size">
              <p className="hello"><span className="opening-quote">“</span>
              {ReactHtmlParser(testimonial.text)}
              <span className="closing-quote">”</span>
              </p>
              <p className="mt-4">{testimonial.date}</p>
              <p className="font-weight-bold">{testimonial.reviewerName}</p>
              <p>{testimonial.reviewerTitle}</p>
            </div>
          })
        }
        </Carousel>
      </div>
    </TestimonialContainer>
  );
  
}

export default TestimonialSection;