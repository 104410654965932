import React, { useState,useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import UserMenu from "./UserMenu";
import styled from "styled-components";
import logo from "./../../images/new-logo.svg";
import fb from "./../../images/fb.svg";
import twitter from "./../../images/tweet.svg";
import youtube from "./../../images/yt.svg";
import insta from "./../../images/ig.svg";
import { FlatButton, Menu } from "./../commons/";
import { Container, Row, Col } from "reactstrap";
import FontAwesome from "react-fontawesome";
import { withApollo } from 'react-apollo';
import gql from "graphql-tag";

const HeaderContainer = styled.div`
  display: none;

  @media (min-width: ${props => props.theme["mobileBreakpoint"]}) {
    display: block;
  }

  #headerLogoLink {
    cursor: pointer;
    z-index: 11;
  }

  .logo-text {
    color: #37312f;
    font-size: ${props => props.theme["$font-size-xxs"]};
    font-weight: ${props => props.theme["$weight-regular"]};
    letter-spacing: 3.6px;
    text-transform: uppercase;
    margin-top: 0.5rem;
  }

  hr {
    border-top: 1px solid #cccccc;
  }

  .advertise-main-menu-visibility {
    display: block;
    @media (max-width: 1425px) {
      display: none;
    }
  }

  .advertise-more-menu-visibility {
    display: block;
    @media (min-width: 1425px) {
      display: none;
    }
  }

  .donate-main-menu-visibility {
    display: block;
    @media (max-width: 1250px) {
      display: none;
    }
  }

  .donate-more-menu-visibility {
    display: block;
    @media (min-width: 1250px) {
      display: none;
    }
  }

  .blog-main-menu-visibility {
    display: block;
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .blog-more-menu-visibility {
    display: block;
    @media (min-width: 1200px) {
      display: none;
    }
  }

  

  .menu {

    z-index:12;
    flex-grow: 1;
    flex-direction: row;
    justify-content: flex-end;

    & > * {
      color: #000000;
      font-size: 14px;
      font-weight: ${props => props.theme["$weight-bold"]};
      letter-spacing: 3px;
      text-transform: uppercase;
      cursor: pointer;
      margin-left: 40px;

      &.active {
        border-bottom: 1px solid ${props => props.theme["primaryColor"]};
      }
    }
    & > *:hover,& > div > div > *:hover {
          color: ${props => props.theme.primaryColor};
    }
  }

  .menu-sec {
    margin-bottom: 25px;
  }

  #searchIcon {
    margin-left: 40px;
  }

  .menu-titles, .header, .menu-dropdown-container > a {
    color: #141d60 !important;
    & > * {
      color: #141d60;
    }
  }

  .social-media-logo {
    width: 30px;
    object-fit: contain;
  }
`;

const LOAD_DIARY = gql`
  query diary {
    productTypes(first:2){
      edges{
        node{
          id
          name
          products(first:1){
            edges{
              node{
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const Header = ({
  history: {
    push,
    location: { pathname }
  },
  cartQuantity,
  client
}) => {
  const [diaryId, setDiaryId] = useState('');
  useEffect(() => {
    client
      .query({
        query: LOAD_DIARY,
      })
      .then(({ data, loading }) => {
        if(!loading){
          const type = data.productTypes.edges.find((productType) =>  productType.node.name === 'Diary')
          if(type && type.node && type.node.products && type.node.products.edges)
            setDiaryId(type.node.products.edges[0].node.id)
        }
      });
  }, [])
  return(
    <HeaderContainer>
    <Container className="py-4 d-none d-lg-block">
      <Row style={{ minHeight: 110 }}>
        <Col
          lg="2"
          className="d-flex align-items-center justify-content-center flex-column"
        >
          <Link id="headerLogoLink" to="/">
            <img alt="" className="img-fluid" src={logo} />
          </Link>
        </Col>
        <Col
          id="rightCol"
          lg="10"
          className="d-flex flex-column justify-content-between"
        >
          <Row>
            <Col xs="12" className="d-flex justify-content-end align-items-center px-4">
              <div className="social-media mr-5">
             <a target="_blank" href="https://www.facebook.com/TheMargFoundation/"> <img className="social-media-logo mr-2" src={fb} alt=""/> </a>
             <a target="_blank" href="https://www.instagram.com/margmagazine/"> <img className="social-media-logo mr-2" src={insta} alt=""/> </a>
             <a target="_blank" href="https://www.youtube.com/channel/UCxJBmH_JO-viFrSjWZiOzcQ"> <img className="social-media-logo mr-2" src={youtube} alt=""/> </a>
             <a target="_blank" href="https://twitter.com/_margfoundation"> <img className="social-media-logo mr-2" src={twitter} alt=""/> </a>
              </div>
              <FlatButton
                onClick={e => push("/subscriptions")}
                className="mr-4"
                colorType="primary"
              >
                Subscribe to marg
              </FlatButton>
              <UserMenu />
              <FlatButton
                onClick={e => cartQuantity > 0 && push("/checkout/cart")}
                className="ml-4"
                colorType="primary"
              >
                <span className="color-black">Cart—</span>
                {cartQuantity}
              </FlatButton>
            </Col>
          </Row>
          <Row className="menu-sec">
            <Col xs="12" md="12" className="d-flex justify-content-end ml-auto mr-0 row">
              <div className="col-11 menu menu-titles d-flex align-items-center flex-wrap px-0">
                <Menu className="drop" directLink={(e) => push("/categories")} label="Publications">
                  <Link to="/categories?product-type=magazine">Magazine</Link>
                  <Link to="/categories?product-type=books">Books</Link>
                  <Link to="/categories?product-type=articles">Articles</Link>
                  <Link to="/categories?product-type=gift-sets">Gift Sets</Link>
                </Menu>
                <Menu directLink={(e) => push("/aboutus")} label="About Us">
                  <Link to="/aboutus">Our Story</Link>
                  {/* <Link to="/collaborate">Historical Timeline</Link> */}
                  <Link to="/team">Marg team</Link>
                  <Link to="/supporters">SUPPORTERS/SPONSORS</Link>
                  <Link to="/trustees">TRUSTEES/ADVISORY</Link>
                </Menu>
                <Link
                  className={pathname.match("donate") ? "active donate-main-menu-visibility" : "donate-main-menu-visibility"}
                  to="/donate"
                >
                  Donate
                </Link>
                <Link
                  className={pathname.match("contactus") ? "active" : ""}
                  to="/contactus"
                >
                  Contact
                </Link>
                <Link
                  className={pathname.match("advertise") ? "active advertise-main-menu-visibility" : "advertise-main-menu-visibility"}
                  to="/advertise"
                >
                  Advertise
                </Link>
                
                
                <Link
                  className={pathname.match("blog") ? "active blog-main-menu-visibility" : " blog-main-menu-visibility"}
                  to="/blog"
                >
                  MARG-E-ZINE
                </Link> 

                <Menu label="More">
                  <Link to="/event">Events</Link>
                  <Link
                    className={"advertise-more-menu-visibility"}
                    to="/advertise"
                  >
                    Advertise
                  </Link>
                  <Link
                    className={"donate-more-menu-visibility"}
                    to="/donate"
                  >
                    Donate
                  </Link>
                  {/* <Link
                    className={"blog-more-menu-visibility"}
                    to="/blog"
                  >
                    Blog
                  </Link> */}
                  <Link to="/submit-proposals">Submit Proposals</Link>
                  <Link to="/collaborate">Collaborate</Link>
                  <Link to="/bookstores">Book stores</Link>
                  <Link to="/movies">Films</Link>
                  {diaryId && <Link to={"/product/" + diaryId}>Diary</Link>}
                  {/* <Link to="/blog">Blog</Link> */}
                  {/*
                  
                  <Link>Film Archive</Link>
                  <Link
                    className={pathname.match("contactus") ? "active" : ""}
                    to="/contactus"
                  >
                    Contact
                  </Link> */}
                </Menu>
              </div>
              <div className="col-1 menu mr-0">
                <Link className="m-0" to="/search">
                  <FontAwesome
                    id="searchIcon"
                    name="search fa-lg"
                    className="color-red"
                  />
                </Link>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </HeaderContainer>
  )
};

export default withApollo(withRouter(Header));
