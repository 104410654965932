import { Advertise } from './Advertise';
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo';


const mapStateToProps = ({
	cmsData: {
		advertise
	}
}, ownProps) => ({
  ...advertise
})

export default connect(
  mapStateToProps,
)(withApollo(Advertise));

