import React, { Component } from 'react';
import styled from 'styled-components';
import { FlatButton } from '../../commons';
import { getParamsObjFromString } from './../../../utils/';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 800px;

  div{
    margin: 10px;
  }

  @media (min-width: ${props => props.theme['mobileBreakpoint']}) {
    justify-content: center;
  }

  @media (min-width: ${props => props.theme['mobileBreakpoint']}) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  span.link {
    font-size: 14px;
    @media (min-width: ${props => props.theme['mobileBreakpoint']}) {
      font-size: 16px;
    }
  }

  & > div {
    @media (min-width: ${props => props.theme['mobileBreakpoint']}) {
      margin: 20px;
    }

    &.active {
      border-bottom: 1px solid ${props => props.theme.primaryColor};
    }
  }
`;

class CategoryFilterComponent extends Component {

  constructor(props) {
    super(props);
    this.filterMap = {};
    this.state = {
      urlProductType: null,
    }
  }


  componentDidMount() {
    this.updateProductType()
  }

  componentDidUpdate(prevProps, nextProps) {
    if(prevProps.location.search != this.props.location.search) {
      this.updateProductType()
    }
  }

  updateProductType() {
    const {
      categories,
      selectedCategories,
      replaceCategoryFilters,
      location: {
        search
      }
    } = this.props;
    const queryObj = getParamsObjFromString(search);
    const queryKeys = Object.keys(queryObj);
    let urlProductType;
    if(queryKeys.length > 0) {
      if(queryKeys[0] === "product-type") {
        urlProductType = queryObj['product-type'];
        this.setState({
          urlProductType: queryObj['product-type'],
        })
      }
    }
    // Map category link names to their objects
    const filterMap = this.filterMap;
    categories.forEach(category => {
      if(category.name === 'Magazine' || category.name === 'Books'){
        filterMap['Magazine & Books'] = filterMap['Magazine & Books'] || []
        filterMap['Magazine & Books'].push(category)
      }
      else{
        filterMap[category.name] = filterMap[category.name] || []
        filterMap[category.name].push(category)
      }
    });

    if(urlProductType) {
      return replaceCategoryFilters(categories.filter(({slug}) => slug === urlProductType));
    }

    replaceCategoryFilters(this.filterMap['Magazine & Books']);      
  }

  render() {
  
    const {
      selectedCategories,
      replaceCategoryFilters,
      categories,
    } = this.props;

    const isSelected = (category) => {
      if(!selectedCategories) return;
      return selectedCategories.some(({name}) => name === category);
    }
    const otherCategories = categories.filter(({name}) => name !== 'Magazine' && name !== 'Books');
    return (
      <Wrapper className="row mx-auto mb-2">
          <FlatButton 
            key={"Magazine & Books"} 
            className={isSelected('Magazine')? 'active': ''}
            onMouseDown={() => replaceCategoryFilters(this.filterMap['Magazine & Books'])}
            type="secondary"> 
            <span className="link">Magazine & Books</span> 
          </FlatButton>
          {otherCategories.map(({name}) => 
            <FlatButton 
              key={name} 
              className={isSelected(name)? 'active': ''}
              onMouseDown={() => replaceCategoryFilters(this.filterMap[name])}
              type="secondary"> 
              <span className="link">{name}</span> 
            </FlatButton>
          )}
      </Wrapper>
    )
  }

}

export default CategoryFilterComponent;