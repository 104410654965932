import BgImageToggler from './bgImageToggler';
import { connect } from 'react-redux'

const mapStateToProps = ({
  cmsData: {
    home
  }
}, ownProps) => {
  return {
    siteBackgroundImage: home && home.pageData && home.pageData.siteBackgroundImage ? home.pageData.siteBackgroundImage : null
  }
}

export default connect(
  mapStateToProps,
)(BgImageToggler);

