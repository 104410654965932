import React from "react";
import styled from "styled-components";
import gql from "graphql-tag";
import { Query, withApollo } from "react-apollo";

import BlogSection from "./Blogs";

const Container = styled.div`
  & .contain {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }

  & .head-title {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.66px;
    line-height: 23px;
    color: #000000;
  }

  & .latest {
    position: relative;
    left: 50px;
    @media (max-width: ${(props) => props.theme["mobileBreakpoint"]}) {
      position: relative !important;
      left: 80px;
    }
    @media (max-width: 767px) {
      position: static !important;
    }
  }

  & .event-time-description {
    max-width: 300px;
    @media (max-width: ${(props) => props.theme["mobileBreakpoint"]}) {
      max-width: 100%;
    }
  }

  & .md-blog-event-title {
    @media (max-width: ${(props) => props.theme["mobileBreakpoint"]}) {
      position: relative !important;
      left: 80px;
    }
    @media (max-width: 767px) {
      position: static !important;
    }
  }

  & .date-time {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.59px;
    line-height: 23px;
    color: #3a3a3a;
  }

  & .next-event {
    padding-left: 15px;
    margin-bottom: 40px;
    @media (max-width: ${(props) => props.theme["mobileBreakpoint"]}) {
      margin-top: 50px;
    }
  }

  & .blog-img {
    width: 366px;
    height: 212px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
    @media (max-width: ${(props) => props.theme["mobileBreakpoint"]}) {
      width: 100%;
      height: auto;
      max-height: 390px;
    }
  }

  & .blog-read-more {
    position: absolute;
    bottom: -5px;
  }

  & .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 90%;
  }

  .blog-posts {
    color: #37312f;
    font-family: Lato;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`;

const LIST_LATEST_EVENTS_BLOGS = gql`
  query ListLatestBlogEvents {
    recentBlogs {
      id
      title
      slug
      textDescription
      image
      publicationDate
      authors {
        name
      }
    }
  }
`;

const IndexBlogSection = () => {
  return (
    <Container>
      <Query query={LIST_LATEST_EVENTS_BLOGS}>
        {({ loading, data, error }) => {
          if (loading) return <div></div>;
          if (error) return <div></div>;
          return (
            <>
              { data.recentBlogs && data.recentBlogs.length &&
                <div className="contain my-5">
                  <div>
                    <h6 className="blog-posts pl-2">LATEST ARTICLES FROM MARG-E-ZINE</h6>
                  </div>

                  <div className="row mt-4 mb-5">
                    <BlogSection blogs={data.recentBlogs} />
                  </div>
                </div>
              }
            </>
          );
        }}
      </Query>
    </Container>
  );
};

export default withApollo(IndexBlogSection);
