import { takeLatest, call, put, select } from "redux-saga/effects";
import gql from 'graphql-tag';
import client from './../apollo/';
import actions from '../actions/';


const GET_CMS_DATA = gql`
  query getCmsData {
    heroSliders {
      type
      textColor
      title
      subtitle
      image
      buttonText
      buttonRoute
      position
    }
    testimonials
      {
        reviewerName
        date
        reviewerTitle
        text
        featureOnHome
      }
    persons {
      name
      category {
        name
      }
      photo
      designation
      bio
      sortOrder
    }
    advertisePage {
      title
      section1Content
      section1Image
      section2Content
      section2Image
      file
    }
    homePage {
      title
      siteBackgroundImage
      aboutSectionText
      aboutSectionTitle
      aboutSectionImage
      testimonialTitle
      aboutSectionVideoEmbed
    }
    aboutPage {
      title
      bannerImage
      text
    }
    sponsorsPage {
      title
      content
    }
    collaboratePage {
      title
      content
      image1
      image2
    }
    proposalPage {
      title
      content
      image1
      image2
    }
    bookStores {
      name
      location {
        name
      }
      addressLine1
      addressLine2
      addressLine3
      city
      pincode
      phoneNumber
      url
      urlLabel
    }
    subscriptionPage {
      title
      text
      footerHead
      footer
      featuredSubscriptionTitle
      regularSubscriptionTitle
    }
    historical {
      mainHeading
      mainSubHeading
      sectionHeader
      header
      description
      image
      nextText
    }
    banner {
      header
      description
      image
    }
    blogIndexPage {
      relatedBlogsTitle
      productsTitle
      featuredSubscriptions {
        id
        name
        subHeading
        description
        issueType
        isFeatured
        notesInr
        notesUsd
        pricings{
          id
          duration
          accessToIssues
          term
          discountText
          originalInrPrice{
            amount
            currency
            localized
          }
          inrPrice{
            amount
            currency
            localized
          }
          originalUsdPrice{
            amount
            currency
            localized
          }
          usdPrice{
            amount
            currency
            localized
          }
        }
      }
    }
  }
`;

const getCmsData = () => client.query({ query: GET_CMS_DATA });

// Action capturer
function* cmsActionCapturer() {
  yield takeLatest('FETCH_ALL_CMS_DATA', fetchDataCmsData);
}

const getCmsDataFromReduxStore = (state) => {
  console.log("FHSKFHKSFHSKFHSF", state);
  return state.cmsData;
}


function* fetchDataCmsData() {
  try {
    const res = yield call(getCmsData);
    const cmsData = yield select(getCmsDataFromReduxStore);

    res.data.persons.forEach(person => {
      if (person.category.name === 'TEAM' || person.category.name === 'DIRECTOR' || person.category.name === 'CONSULTANT' || person.category.name === 'PAST LEADERSHIP') {
        cmsData['team'].persons.push(person);
      } else {
        cmsData['trustees'].persons.push(person);
      }
    });

    console.log("RES", res.data);
    // Manually assigning content pieces to pages inside the cmsData state
    cmsData['home'].heroSliders = res.data.heroSliders;
    cmsData['home'].testimonials = res.data.testimonials;
    cmsData['home'].pageData= res.data.homePage[0];
    cmsData['advertise'].pageData= res.data.advertisePage[0];
    cmsData['about'].pageData= res.data.aboutPage[0];
    cmsData['supporters'].pageData= res.data.sponsorsPage[0];
    cmsData['collaborate'].pageData= res.data.collaboratePage[0];
    cmsData['proposal'].pageData= res.data.proposalPage[0];
    cmsData['bookStores'].pageData = res.data.bookStores;
    cmsData['subscription'].pageData = res.data.subscriptionPage[0];
    cmsData['historical'].pageData = res.data.historical;
    cmsData['banner'].pageData = res.data.banner[0];
    cmsData['blogIndexPage'] = res.data.blogIndexPage;

    yield put(actions.writeCmsDataToStore(cmsData));

  } catch (err) {
    console.log("Error fetching CMS data: ", err)
  }
}

export default cmsActionCapturer;






