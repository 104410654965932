import React from "react";
import SubscriptionItem from "../../Subscriptions/SubscriptionItem/SubscriptionItemContainer";


export const SubscriptionsForBlog = ({ blogIndexPage, subscriptions: boughtSubscriptions, isAuthenticated, auth, successNotification, errorNotification }) => {
      
      if(isAuthenticated) {
        console.log("isAuthenticated", boughtSubscriptions)
      }
  return (
    <>
      {blogIndexPage && blogIndexPage.featuredSubscriptions && blogIndexPage.featuredSubscriptions.length > 0 && (
        <div>
          <div className="container">
            <h2 className="blog__ym_like-this mt-5 mb-3">Subscribe to Marg now</h2>
            <div className="row">
              {blogIndexPage && blogIndexPage.featuredSubscriptions && blogIndexPage.featuredSubscriptions.map((subscription) => (
                <SubscriptionItem
                  {...subscription}
                  isAuthenticated={isAuthenticated ? true : false}
                  subscriptions={boughtSubscriptions}
                  key={subscription.id}
                  className="col-12 col-sm-12 col-md-6 col-lg-4 subscription mb-5"
                  successNotification={successNotification}
                  errorNotification={errorNotification}
                />))}
            </div>
          </div>
        </div>)}
    </>
  )
}
