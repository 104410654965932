import React, { useState } from 'react';
import styled from 'styled-components';
import { Container,Row,Modal, ModalHeader, ModalBody } from 'reactstrap';
import { RaisedButton, FlatButton } from '../commons/';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import default_profile from '../../images/default_profile.jpeg';
import Breadcrumbs from "../Breadcrumbs";


// import Shyam from './../images/trustees/Shyam.jpg';
// import Bahram from './../images/trustees/Bahram.jpg';
// import Cyrus from './../images/trustees/Cyrus.jpg';
// import Divya from './../images/trustees/Divya.jpg';
// import Geeta from './../images/trustees/Geeta.jpg';
// import Issat from './../images/trustees/Issat.jpg';
// import Kapila from './../images/trustees/Kapila.jpg';
// import Malvika from './../images/trustees/Malvika_Singh.PNG';
// import Rahul from './../images/trustees/Rahul_Mehrotra.jpg';
// import Rustom from './../images/trustees/Rustom_Bharucha.jpg';
// import Sadanand from './../images/trustees/Sadanand_Menon.jpg';
// import Sundar from './../images/trustees/Sundar-Sarukkai.jpg';


const Wrapper = styled.div`
`

const TrusteeWrapper = styled.div`
  animation: trusteeFadeIn ease 1s;

  @keyframes trusteeFadeIn { 
  0% { 
  opacity: 0;
  transform: translate3d(0, -2%, 0);
  } 
  100% { 
  opacity: 1;
  transform: none;
  } 
  }

  div.content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-around;
    }
`;

const TrusteeDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-bottom: 10px;

  & > div.img {
    & > img {
      height: 400px;
      width: 100%;
      object-fit: cover;
    }
  }

  & > .date {
    color: #3a3a3a;
    font-size: ${props => props.theme['$font-size-xxs']};
    font-weight: ${props => props.theme['$weight-regular']};
    letter-spacing: 0.59px;
    line-height: 23px;
    text-transform: uppercase;
  }

  & > .name {
    margin-top: 10px;
    font-size: ${props => props.theme['$font-size-xxs']};
    font-weight: ${props => props.theme['$weight-bold']};
    line-height: 23px;
  }

  & > .designation {
    color: #3a3a3a;
    font-size: ${props => props.theme['$font-size-xxs']};
    font-weight: ${props => props.theme['$weight-regular']};
    line-height: 23px;
  }
`;

const TRUSTEES = [
//   {
//     name: "Ishaat Hussain",
//     image: Issat,
//     designation: "Chairman",
//     bio: 'He is a corporate veteran. He joined Tata Sons as Executive Director in 1999 and held key positions in other companies within the group. Prior to Tata Sons, he was Senior Vice President and Executive Director of Finance at Tata Steel for nearly a decade. For most of his tenure in Tata Sons, he was a member of Ratan Tata’s core team, contributing actively to a long period of rapid and sustained growth.  He is Fellow at the Institute of Chartered Accountants in England and Wales and Member of the Securities and Exchange Board of India committees on insider trading and primary capital markets. Till recently he was also a Trustee of India Foundation for the Arts.'
//   },
//   {
//     name: "Shyam Benegal",
//     image: Shyam,
//     bio: 'He has made award-winning films, documentaries and TV series, notably a series on the history of India titled Bharat Ek Khoj and on the making of the Indian Constitution titled Samvidhaan. He runs a production company in Mumbai and was a Member of the Rajya Sabha, 2006‒12. The Government of India has conferred on him two of its most prestigious awards – Padma Shri in 1976 and Padma Bhushan 1991. He received the Indira Gandhi National Integration Award in 2004 and the Dadasaheb Phalke Award for Lifetime Achievement in 2005. In 2007, he was conferred a D. Litt from Jamia Millia University, New Delhi and in 2011 from the University of Calcutta.'
//   },
//   {
//     name: "Divyabhanusinh Chavda",
//     image: Divya,
//     bio: 'He is the author of The End of a Trail: The Cheetah in India, The Story of Asia’s Lions and The Story of India’s Unicorns (the last two published by Marg). He is actively involved in conservation and was the president of WWF–India and Vice President of the Bombay Natural History Society. He is a member of the Species Survival Commission, Cat Specialist Group of World Conservation Union (IUCN). He was a member of the National Board for Wildlife from 2008 to 2013 and its Standing Committee. He had a long stint in the hospitality sector working with Indian Hotels Company Limited.'
//   },
//   {
//     name: "Cyrus Guzder",
//     image: Cyrus,
//     bio: 'He is the Chairman and Managing Director of AFL Private Limited. He also serves as a Director on the Boards of the Great Eastern Shipping Company Limited and Mahindra Holidays Limited.  He was a Founder Director of The Indian Institute for Human Settlements and has also served on the boards of Air India Limited, Tata Infomedia Limited, Tata Honeywell Limited, Alfa Laval India Limited and BP (India) Limited. He has also served on the Local Advisory Board of Barclays Bank, India and was a member of the Reserve Bank of India’s Banking Codes and Standard Board of India. He is currently a Member of the Court of Governors of the Administrative Staff College and a Trustee of the Asiatic Society of Bombay.'
//   },
//   {
//     name: "Bahram Navroz Vakil",
//     image: Bahram,
//     bio: 'He is one of the founding partners of AZB & Partners and  one of India’s foremost finance lawyers. He has been on several government-constituted committees and played a lead role in drafting the Bankruptcy Code passed by Parliament. His key practice areas are Infrastructure and Project Finance, Joint Ventures and M&A. He supports Wishing Well, an organization that attempts to bridge the gap by matching volunteers, services and donations with the needs of NGOs. He is also a keen art collector.',
//   },
//   {
//     name: "Kapila Vatsyayan",
//     image: Kapila,
//     bio: 'She is a distinguished scholar of Indian classical dance, art, architecture, and art history. She has been a Member of the Rajya Sabha and a bureaucrat and served as Founding Director of the Indira Gandhi National Centre for the Arts. She is also a Trustee of the India international Centre and Chairperson of the IIC-Asia Project.'
//   }
];

const ADVISORIES = [
//   {
//     name: "Rustom Bharucha",
//     image: Rustom,
//     bio: 'He recently retired as Professor of Theatre and Performance Studies from the School of Arts and Aesthetics, Jawaharlal Nehru University, New Delhi.  A former Fellow of the International Research Centre/Interweaving Performance Cultures in Berlin, Germany, he is the author of several books on interculturalism, secularism, and performance including Theatre and the World: Performance and the Politics of Culture (1993), The Politics of Cultural Practice: Thinking Through Theatre in an Age of Globalization (2000), The Question of Faith (1993), In the Name of the Secular (1998), Rajasthan: An Oral History (2003), Another Asia: Rabindranath Tagore and Okakura Tenshin (2006), and Terror and Performance (2014).  He has recently completed a critical edition of Performing the Ramayana Tradition: Enactments, Interpretations, and Arguments, co-edited with Paula Richman.'
//   },
//   {
//     name: "Rahul Mehrotra",
//     image: Rahul,
//     bio: 'He is a practising architect, urban designer and educator. He works in Mumbai and Boston, and teaches at the Graduate School of Design at Harvard University where he is professor of urban design and planning. His practice, RMA Architects (www.RMAarchitects.com), founded in 1990, has executed a range of projects and has also initiated several unsolicited projects driven by the firm’s commitment to advocacy in the city of Mumbai. He has written, co-authored and edited a vast repertoire of books on urban history, historic buildings, public spaces , planning processes  and architecture in India.'
//   },
//   {
//     name: "Sadanand Menon",
//     image: Sadanand,
//     bio: 'He works in the charged space between culture and politics. Arts editor, columnist, pedagogue, photographer, curator and stage lights designer, he has been in executive/advisory capacities at the National Museum, LKA, NSD, NGMA, IIAS, Shimla, Board for ICH, Delhi, and is Managing Trustee of the arts foundation, SPACES, Chennai. He has taught at universities across India. With dancer/choreographer Chandralekha, he was involved with issues connected with the creation of a contemporary Indian dance. He has curated landmark Retrospectives of senior artists Dashrath Patel, Balan Nambiar and S.G. Vasudev. His essays/photographs are part of volumes on art, performance and politics.'
//   },
//   {
//     name: "Sundar Sarukkai",
//     image: Sundar,
//     bio: 'He was till recently a professor of philosophy at the National Institute of Advanced Studies, Bengaluru. He was also the Founder-Director of the Manipal Centre for Philosophy and Humanities. His work is primarily in the philosophy of natural and social sciences. He is the author of the following books: Translating the World: Science and Language,  Philosophy of Symmetry, Indian Philosophy and Philosophy of Science, What is Science? and two books co-authored with Gopal Guru: The Cracked Mirror: An Indian Debate on Experience and Theory and most recently Experience, Caste and the Everyday Social. His book JRD Tata and the Ethics of Philanthropy will be published this year. He is the Series Editor for Science and Technology Studies, Routledge and the Co-Chief Editor of the Springer Handbook of Logical Thought in India.  He has also been very active in outreach programmes to take philosophy to different communities and places, including philosophy workshops for children and bringing philosophy to the public through his writing in the media and through his initiative Barefoot Philosophers (www.barefootphilosophers.org).'
//   },
//   {
//     name: "Malvika Singh",
//     image: Malvika,
//     bio: 'She is the publisher of Seminar, a monthly magazine of ideas, founded in 1959. She has authored several books: Bhutan: Through the Lens of the King, New Delhi: Making of a Capital, Delhi: India in One City and Snowdon’s India. She has edited Delhi: The First City, Chennai: A City of Change, Hyderabad: A City of Hope, Kolkata: A Soul City, Lucknow: A City Between Cultures, Mumbai: A City of Dreams, and Freeing the Spirit: Iconic Women of India. She was the editor of The India Magazine and has also worked extensively in theatre and film, and was decorated as a Dame in the civil merit honours list of the King of Spain in 2009.'
//   },
]


const TrusteeDetails = ({name, bio, photo, designation}) => {
  const [ isOpen, setIsOpen ] = useState(false);
  return (
    <TrusteeDetailsWrapper className="col-6 col-md-4 col-lg-3 my-4">
      <div className="img">
        <img src={photo} />
      </div>
      {/*<div className="date">
        2011 - CURRENT
      </div>*/}
      <div className="name">
        {name}
      </div>
      {
        designation && <div className="designation">
          {designation}
        </div>
      }
      <FlatButton onClick={(e) => setIsOpen(!isOpen)}>Know More</FlatButton>
      <Modal isOpen={isOpen} style={{marginTop: 100}}>
        <ModalHeader toggle={() => setIsOpen(!isOpen)}>{name}</ModalHeader>
        <ModalBody>
          {ReactHtmlParser(bio)}
        </ModalBody>
      </Modal>
    </TrusteeDetailsWrapper>
  );
}

const PeopleList = ({name, list, className}) => (<TrusteeWrapper>
  <div className={className}>
    <div className="heading">{name}</div>
    <div className="row">
      {
        list.map((trustee) => (<TrusteeDetails {...trustee}></TrusteeDetails>))
      }
    </div>
  </div>    
</TrusteeWrapper>);



export const Trustees = props => {

  console.log(props.trustees);

  return <Wrapper>
    <Container className="my-5">
        <PeopleList
          name="Trustees"
          list={
            props
              .trustees
              .persons
              .sort(({sortOrder: sortOrderA}, {sortOrder: sortOrderB}) => sortOrderA - sortOrderB)
              .filter(person => person.category.name === 'TRUSTEE')
          }
          >
        </PeopleList>
        <PeopleList
          className={'my-5'}
          name="Editorial Advisory Board"
          list={
            props
              .trustees
              .persons
              .sort(({sortOrder: sortOrderA}, {sortOrder: sortOrderB}) => sortOrderA - sortOrderB)
              .filter(person => person.category.name === 'ADVISORY')
          }>
        </PeopleList>
    </Container>
  </Wrapper>;
}