import Bookstores from './Bookstores';
import { connect } from 'react-redux'

const mapStateToProps = ({
	cmsData: {
		bookStores,
	}
}, ownProps) => {
	return {
    bookStores: {
				...bookStores,
    }
	}
}

export default connect(
  mapStateToProps,
)(Bookstores);

