import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col } from "reactstrap";
import { Link } from 'react-router-dom';
import ProductCard from './ProductCard'
import placeholder from '../../images/viewmore_placeholder.png'

import Carousel from "react-multi-carousel";



const Wrapper = styled.div`

  // @media (min-width: ${props => props.theme['mobileBreakpoint']}) {
  //   padding: 100px 0;
  // }

  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;

  // & > .heading {
  //   color: #000000;
  //   font-family: ${props => props.theme['$font-secondary-medium']};
  //   font-size: ${props => props.theme['$font-size-lg']};
  //   font-weight: ${props => props.theme['$weight-regular']};
  //   letter-spacing: 1px;
  //   line-height: 57px;
  // }

  // & > .link {
  //   padding-top: 0;
  //   padding-bottom: 50px;
  // }

  // & > .row {
  //   width: 100%;
  //   justify-content: center;
  //   @media (min-width: ${props => props.theme['mobileBreakpoint']}) {
  //     padding: 0px 50px;
  //   }
  // }

 

 


`;

const LOAD_PRODUCTS = gql`
  query LoadProducts($first: Int, $categories: [ID]!) {
    products(first: $first, categories: $categories, sortBy:{ field:DATE,direction:DESC }) {
      totalCount
      edges {
        node {
          id
          name
          subTitle
          variants{
            sku
            isDigital
            inrPrice {
              amount
              currency
              localized
            }
            usdPrice {
              amount
              currency
              localized
            }
            inrPricing{	
              onSale	
              price{	
                net{	
                  localized	
                }	
              }	
            }	
            usdPricing{	
              onSale	
              price{	
                net{	
                  localized	
                }	
              }	
            }
          }
          images{
            url
          }
          attributes{
            attribute{
              name
              slug
            }
            value {
              name
            }
          }
          editors {
            id
            name
          }
        }
      }
    }
  }
`;

const responsive = {

  tablet: {
    breakpoint: { max: 1139, min: 768 },
    items: 2,
    partialVisibilityGutter: 75,
    showDots: false
    // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
    partialVisibilityGutter: 75,
    showDots: true
    // optional, default to 1.
  }
};

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <i onClick={previous} className="arrow-left">  </i>
      <i onClick={next} className="arrow-right">  </i>
    </div>
  );
};



export const PreviousIssues = (props) => (
  <Wrapper>

    <Query
      query={LOAD_PRODUCTS}
      fetchPolicy="no-cache"
      variables={
        {
          sortBy: "updatedAt",
          first: 3,
          categories: ["Q2F0ZWdvcnk6MQ=="]

        }
      }
    >
      {
        ({ loading, error, data }) => {
          if (loading) {

            return <h1>loading..</h1>;
          }
          if (error) {

            console.log(error);
            return <div>Error loading products</div>
          }
          if (data && data.products && data.products.length === 0) {
            return (<div>No Issues found</div>)
          }


          return <>
            <Row className="webview">
              <Col className="d-inline-flex col-lg-12 px-0 ">
                {
                  data.products.edges.map(
                    (product, id) => (
                      <ProductCard key={id} className="col product-smooth-open" {...product.node} />
                    )
                  )

                }
                <div className="col product-smooth-open">
                  <Link to="/categories?product-type=magazine">
                    <div style={{height:'min-content',position:'relative'}}>
                        <img
                          alt=""
                          className="img-fluid carousel"
                          src={placeholder}
                        />
                        <p  className="view-more" style={{ color: 'black' }}>VIEW MORE</p>

                      </div>
                  </Link>
                </div>


              </Col>

            </Row>
            <Row className="tabview">
              <Col lg='12' className="px-3 col-sm-12" style={{
                paddingBottom: '40px',
                position: 'relative',
                marginBottom: '30px'
              }}>




                <Carousel

                  additionalTransfrom={0}
                  arrows={false}
                  autoPlaySpeed={3000}
                  centerMode={false}
                  className=""
                  // customRightArrow={<CustomRightArrow />}
                  // customLeftArrow={<CustomLeftArrow />}
                  customButtonGroup={<CustomButtonGroupAsArrows />}
                  containerClass="container"
                  dotListClass="react-multi-carousel-dot-list previous-dots"
                  draggable
                  focusOnSelect={false}
                  partialVisbile={true}
                  itemClass=""
                  keyBoardControl
                  minimumTouchDrag={80}
                  renderButtonGroupOutside={true}
                  removeArrowOnDeviceType={["mobile"]}
                  renderArrowsWhenDisabled={true}

                  showDots
                  renderDotsOutside

                  responsive={responsive}
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                >
                  {data.products.edges.map(
                    (product, id) => (
                      <ProductCard key={id} className="col product-smooth-open" {...product.node} />
                    )
                  )

                  }

                  <div className="col product-smooth-open">
                    <Link to="/categories?product-type=magazine">
                      {/* <div className="img-fluid carousel text-center flex flex-column" style={{ alignContent: 'center', justifyContent: 'center', display: 'flex' }}>
                        <p style={{ color: 'black' }}>VIEW MORE</p>
                      </div> */}
                      <div style={{height:'min-content',position:'relative'}}>
                        <img
                          alt=""
                          className="img-fluid carousel"
                          src={placeholder}
                        />
                        <p  className="view-more" style={{ color: 'black' }}>VIEW MORE</p>

                      </div>


                    </Link>
                  </div>

                </Carousel>

              </Col>

            </Row>


          </>
        }
      }
    </Query>
  </Wrapper>

)