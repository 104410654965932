import { connect } from 'react-redux'
import { withApollo } from 'react-apollo';
import VoucherInput from './VoucherInput.jsx';
import actions from './../../../../actions';
const mapStateToProps = ({
  cart: {
    checkoutId,
    voucherCode,
  },
}, ownProps) => ({
  cartId: checkoutId,
  voucherCode,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setDiscount: (discountInfo) => dispatch(actions.setDiscount(discountInfo)),
  setVoucherCode: (voucherCode) => dispatch(actions.setVoucherCode(voucherCode)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withApollo(VoucherInput));