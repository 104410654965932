import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { RaisedButton } from "./../commons";
import {useHistory} from 'react-router-dom';
import FlatButton from "./../commons/FlatButton";
import SignUpForm from '../SignupForm';
function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    fontFamily: `Cormorant Garamond,serif`,
  },
  formTextField: {
    height: "50px",
    paddingLeft: "20px",
    fontFamily: `Lato`,
  },
  serifFont: {
    fontFamily: `Cormorant Garamond,serif`,
  },
  modalBtnRow:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  }
}));
export default function SimpleModal({ open, setOpen }) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  // const [open, setOpen] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const history = useHistory();
  const body = (
    <div style={modalStyle} className={`${classes.paper} p-2 mailChimp__info_mod`}>
      <SignUpForm columnSize='col-12 row m-0 p-0' signUpDialog/>
    </div>
  );
  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
}