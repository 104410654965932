import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import RightArrow from './../images/RightArrow.svg';


const Breadcrumb = styled.div`
  display: flex;
  padding-top: 50px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.59px;
  line-height: 23px;
  font-size:14px;

  @media (max-width: 1139px) {
    font-size:12px;
  }
  
  @media (max-width: ${props => props.theme["mobileBreakpoint"]}) {
    margin-left: 0;
    flex-flow: wrap;
  }

  a {
    color: ${(props) => props.theme["mainTextColor"]};
  }

  a:last-child {
    color: ${(props) => props.theme["disable"]};
    pointer-events: none;
    cursor: default;
  }

  a .chev-right {
    width: 7px;
    height: 11px;
    pointer-events: none !important;
    cursor: default !important;
    vertical-align: baseline;
    color: ${(props) => props.theme["mainTextColor"]};
  }

  a:last-child .chev-right {
    display: none;
  }
`;


function Breadcrumbs({routes}) {
  return <Breadcrumb className="breadcrumb-align">
  {
    routes.map((route) => (
      <Link to={route.url}>
          {route.name}
          <img className="mx-3 chev-right" src={RightArrow} alt=""/>
      </Link>
    ))
  }
</Breadcrumb>;
}

export default Breadcrumbs;
