import { Team } from './Team';
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo';


const mapStateToProps = ({
	cmsData: {
		team
	}
}, ownProps) => {
	return {
    team: {
        ...team
    }
	}
}

export default connect(
  mapStateToProps,
)(withApollo(Team));

