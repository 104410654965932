import React, { useEffect } from "react";
import videojs from 'video.js'



export default class VideoPlayer extends React.Component {
  componentDidMount() {
    var expDate=new Date();
    expDate.setHours(expDate.getHours() + 5);
    document.cookie = `authToken=${localStorage.getItem('authToken')}; path=/; expires=Tue, expDate.toUTCString()`;

    // instantiate video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      console.log('onPlayerReady', this);
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <video
        poster={this.props.poster}
        className={`video-js vjs-default-skin vjs-big-play-centered ${this.props.className}`}
        ref={node => (this.videoNode = node)}
        onContextMenu={(e) => e.preventDefault() } />
    );
  }
}