// picked up by saga
const fetchAllCmsData = () => ({
  type: 'FETCH_ALL_CMS_DATA'
});

// emitted by saga and then picked up by reducer
const writeCmsDataToStore = (cmsData) => ({
  type: 'WRITE_CMS_DATA_TO_STORE',
  cmsData: cmsData
});

export default {
  fetchAllCmsData,
  writeCmsDataToStore
}