import React, {useState} from "react";

import styled from "styled-components";
import facebookImg from "./../../images/facebook.svg";
import instagramImg from "./../../images/instagram.svg";
import twitterImg from "./../../images/twitter.svg";
import youtubeImg from "./../../images/youtube.svg";

import whiteLogo from "./../../images/white-logo.svg";
import bkgrdSubscribe from "./../../images/subsc-background.png";
import { Link } from "react-router-dom";
import { FlatButton, RaisedButton } from "./../commons/";
import { Container, Row, Col } from "reactstrap";
import FbPixel from "../../utils/fbPixel";

const SIGNUP_URI = `${process.env.REACT_APP_BACKEND_URL}/zoho/signup-newsletter/`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;

  .footer-link {
    font-size: $font-size-xxs;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 1.5rem;

    span {
      color: red;
    }

    a {
      color: #f8f8f8;
    }
  }

  .subscribe-heading {
    color: #f8f8f8;
    font-family: ${(props) => props.theme["$font-secondary-medium"]};

    font-size: ${(props) => props.theme["$font-size-sm"]};
    @media (min-width: 992px) {
      font-size: ${(props) => props.theme["$font-size-sm"]};
    }
    @media (min-width: 1200px) {
      font-size: ${(props) => props.theme["$font-size-md"]};
    }

    font-weight: ${(props) => props.theme["$weight-regular"]};
    letter-spacing: 1px;
  }

  input.subscribe {
    border: 1px solid #cccccc;
    background-color: #f8f8f8;
    padding: 10px;
    margin-right: 10px;
    width: 50%;

    &::placeholder {
      color: ${(props) =>
        props.theme[props.type ? `${props.type}Color` : "#000000"]};
      font-size: ${(props) => props.theme["$font-size-xxxs"]};
      font-weight: ${(props) => props.theme["$weight-bold"]};
      letter-spacing: 3px;
      text-transform: uppercase;
    }
  }

  #magazineSubscribe {
    .subc-heading {
      color: ${(props) => props.theme["primaryColor"]};
      font-family: Lato;
      font-size: ${(props) => props.theme["$font-size-xxs"]};
      font-weight: ${(props) => props.theme["$weight-bold"]};
      letter-spacing: 3px;
      text-transform: uppercase;
    }
    p {
      position: unset !important;
    }
    a {
      position: unset !important;
    }
    p {
      color: black;
      font-family: Lato;
      font-size: ${(props) => props.theme["$font-size-xxs"]};
      font-weight: ${(props) => props.theme["$weight-regular"]};
      letter-spacing: 0.59px;
      line-height: 23px;
    }
  }

  .legal {
    color: #f8f8f8;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.52px;
    line-height: 21px;
    a,
    a:visited {
      color: #f8f8f8;
    }
  }

  .newsletter-signup-btn {
    background-color: #f8f8f8;
    color: #ec1d24;
  }

  .newsletter-signup-btn:hover {
    background-color: #ec1d24;
    color: #f8f8f8;
  }

  .custom-col {
    position: unset !important;
  }
`;



export const Footer = (props) => { 
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const formSubmit = (event) => {
    event.preventDefault();
    if (email === '') {
      return;
    }
    fetch(SIGNUP_URI, {
      method: 'POST',
      body: JSON.stringify({ email: email }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.status >= 200 && response.status < 300) {
        console.log('zoho ' + response.json);
        setEmail('');
        setSubscribed(!subscribed);
        FbPixel.trackCustom('NewsletterSignUp', {
          'product_type': 'newsletter',
          'content_type': 'blog',
          'content_name': 'footer',
        });

      } else {
        console.log('zoho Somthing went wrong');
      }
    }).catch(err => err);
  };

  return (
  <FooterContainer>
    <div
      style={{
        backgroundColor: props.applyBackGroundColor ? "#fcf8d1" : "initial",
        backgroundImage: `url(${bkgrdSubscribe})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      id="magazineSubscribe"
      className="row text-center py-4 px-3 full-width"
    >
      <p className="col-12 subc-heading mx-auto"> {props.subscriptionHead} </p>
      <p className="mx-auto my-3 col-12 col-sm-8 col-md-5">
        {props.subscriptionText}
      </p>
      <a href="/subscriptions" className="col-12 subscribe-btn">
        <RaisedButton className="mt-3 btn-hover" colorType="primary">
          SUBSCRIBE NOW
        </RaisedButton>
      </a>
    </div>
    <div
      style={{
        backgroundColor: props.applyBackGroundColor ? "#141d60" : "initial",
      }}
      className="pt-3 full-width bg-gray"
    >
      <Container className="px-4 px-lg-5">
        <Row>
          <Col lg="6" className="mb-5 mb-lg-0 custom-col">
            <div className="subscribe">
              <div className="subscribe-heading">Sign up for our Newsletters:</div>
              <div className="form my-3">
              <form
                onSubmit={formSubmit}
              >
               <input
                  type="email"
                  name="email"
                  className="email"
                  id="email"
                  placeholder="email address"
                  required
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  placeholder="Your Email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  title="Invalid email address"
                  required
                  className="subscribe"
                  disabled={subscribed}
                />
                <RaisedButton
                  disabled={subscribed}
                  className='btn-hover'
                  type="submit"
                  colorType="primary"
                  onClick={() => { 
                  }}
                >
                  {subscribed ? 'Subscribed' : 'Sign up'}
                </RaisedButton>
              </form>
                <div className="d-flex mt-5">
                  <img className="mr-5" src={whiteLogo} alt="marg logo" />
                  <div className="mt-auto mb-0 py-4">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/TheMargFoundation/"
                    >
                      <img src={facebookImg} />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/margmagazine/"
                    >
                      <img className="pl-2" src={instagramImg} />
                    </a>
                    <a
                      target="_blank"
                      fill="red"
                      href="https://twitter.com/_margfoundation"
                    >
                      <img className="pl-2" src={twitterImg} />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/channel/UCxJBmH_JO-viFrSjWZiOzcQ"
                    >
                      <img className="pl-2" src={youtubeImg} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="6" className="mt-4 custom-col">
            <Row>
              <Col lg="6 custom-col">
                <FlatButton className="footer-link" colorType="secondary">
                  <Link to="/">Home</Link>
                </FlatButton>
                <FlatButton className="footer-link" colorType="secondary">
                  <Link to="/categories">Publications</Link>
                </FlatButton>
                <FlatButton className="footer-link" colorType="secondary">
                  <Link to="/collaborate">COLLABORATE</Link>
                </FlatButton>
                <FlatButton className="footer-link" colorType="secondary">
                  <Link to="/signup">REGISTER FOR MARG</Link>
                </FlatButton>
                <FlatButton className="footer-link" colorType="secondary">
                  <Link to="/movies">MARG FILMS</Link>
                </FlatButton>

                {/* <FlatButton className='footer-link' colorType="secondary"><Link to="/aboutus">About
                                    Marg</Link></FlatButton>
                                <FlatButton hidden={true} className='footer-link'
                                            colorType="secondary">Blog</FlatButton> */}
              </Col>
              <Col lg="6 custom-col">
                <FlatButton className="footer-link" colorType="secondary">
                  <Link to="/donate">Donate</Link>
                </FlatButton>
                <FlatButton className="footer-link" colorType="secondary">
                  <Link to="/advertise">Advertise</Link>
                </FlatButton>

                <FlatButton className="footer-link" colorType="secondary">
                  <Link to="/contactus">CONTACT US</Link>
                </FlatButton>
                <FlatButton className="footer-link" colorType="secondary">
                  <Link to="/bookstores">FIND BOOKSTORES </Link>{" "}
                </FlatButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col lg="6 custom-col">
            <p className="legal">
              The digitization of the Magazine has been supported by Tata Trusts
            </p>
          </Col>
          <Col lg="6 custom-col">
            <span className="legal">
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
              &nbsp; | &nbsp;
              <Link to="/privacy-policy">Privacy Policy</Link>
              &nbsp; | &nbsp;
              <p className="d-inline">© 2019 The Marg Foundation</p>
            </span>
            <span>
              <p className="legal mb-1">
                Designed and built by{" "}
                <a target="_blank" href="https://1stmain.co" rel="noopener">
                  1st Main
                </a>
              </p>
              <p className="legal">
                Branding by{" "}
                <a href="http://hanno.in/" target="_blank" rel="noopener">
                  {" "}
                  Hanno
                </a>
              </p>
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  </FooterContainer>
)};
