import { Home } from './Home';
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo';
import actions from './../../actions/'


const mapStateToProps = ({
	cmsData: {
		home
	}
}, ownProps) => ({
  ...home
})

export default connect(
  mapStateToProps,
)(withApollo(Home));

