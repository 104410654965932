import React, { useEffect, useReducer, useState } from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Row, Col } from "reactstrap";
import styled from "styled-components";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { FlatButton, RaisedButton } from "./../commons/";
import { replaceStaticUrl, getParamsObjFromString } from "../../utils";
import SignUpDialog from "./EmailForBlogs";
import axios from "axios";
import ProductCard from "../ProductList/ProductListWrapper/ProductCard/ProductCard.jsx";
import { BlogContainer as SubscriptionsForBlog } from "./index";
import auth from "../../actions/auth";
import actions from '../../actions'
import { Card } from "./BlogCard/Card.jsx";
import FbPixel from "../../utils/fbPixel";


const Blog = styled.div`
  padding : 0rem 6rem 2rem;
  
  .img-fluid {
    width: 100%;
  }

  .title {
    font-size: ${(props) => props.theme["$font-size-lg"]}
    font-weight: 700;
    font-family: ${(props) => props.theme["$font-secondary-medium"]};
  }
  
  .CategoryText{
    color: #ec1d24;
    font-family: ${(props) => props.theme["$font-primary-medium"]};
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  h6{
    font-family: ${(props) => props.theme["$font-primary-medium"]};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.59px;
    line-height: 23px;
  }
  .BlogTitle{
    color: #000000;
    font-family: ${(props) => props.theme["$font-primary-medium"]};
    font-size: 18px !important;
    font-weight: 700;
    letter-spacing: 0.66px;
    line-height: 23px;
  }
  .ReadPostLink{
    color: #ec1d24;
    font-family: ${(props) => props.theme["$font-primary-medium"]};
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  .flow-html-content{
    overflow-wrap: break-word;
    padding-top: 5em;
  }
  @media (max-width: 768px) {
    .tab-content{
      padding:0;
    }
    padding : 0rem;
  }

  & iframe {
    @media (max-width: ${props => props.theme['mobileBreakpoint']}) {
      width: 100% !important;
    }
  }
}

.thumbnail-in-related-blogs {
  width: 100%;
  height: 100%;
  max-height: 400px;
  object-fit: cover;
  object-position: center;
}
.date-author-color {
  color: #3a3a3a;
}

.related-blog-container {
  width: 100%;
  @media (max-width: ${props => props.theme['mobileBreakpoint']}) {
    padding: 10px;
  }
}

input.subscribe {
  border: 1px solid #cccccc;
  background-color: #f8f8f8;
  padding: 10px;
  margin-right: 10px;

  &::placeholder {
    color: ${(props) =>
    props.theme[props.type ? `${props.type}Color` : "#000000"]};
    font-size: ${(props) => props.theme["$font-size-xxxs"]};
    font-weight: ${(props) => props.theme["$weight-bold"]};
    letter-spacing: 3px;
    text-transform: uppercase;
  }
}


.subscribe-heading {
  color: #000000;
  font-family: ${(props) => props.theme["$font-secondary-medium"]};

  font-size: ${(props) => props.theme["$font-size-sm"]};
  @media (min-width: 992px) {
    font-size: ${(props) => props.theme["$font-size-sm"]};
  }
  @media (min-width: 1200px) {
    font-size: ${(props) => props.theme["$font-size-md"]};
  }

  font-weight: ${(props) => props.theme["$weight-regular"]};
  letter-spacing: 1px;
}

.subscribe-description {
  font-size: 14px;
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 1140px) {
    font-size: 16px;

  }

}


`;

const Category = styled.div`
  div:not(:last-of-type)::after {
    content: ",\u00A0";
   }
  div:hover {
    text-decoration: underline;
  }
`;

const BlogContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const INITIAL_STATE = {
  blog: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "HANDLE_BLOG_DATA":
      return {
        ...state,
        blog: action.data,
      };
    default:
      return state;
  }
};

const LOAD_BLOG_DETAILS = gql`
  query blog($slug: String!) {
    blog(slug: $slug) {
      id
      title
      description
      image
      publicationDate
      authors {
        id
        name
      }
      relatedBlogs {
        id
        slug
        title
        description
        image
        publicationDate
        categories {
          id
          name
        }
        authors {
          id
          name
        }
      }
      products(first: 4) {
        edges {
          node {
            id
            name
            publicationDate
            images {
              url
            }
            attributes{
              value{
                id
                name
                value
                slug
              }
              attribute{
                id
                name
                slug
              }
            }
            variants{
              sku
              isDigital
              inrPrice {
                amount
                currency
                localized
              }
              usdPrice {
                amount
                currency
                localized
              }
              inrPricing{	
                onSale	
                price{	
                  net{	
                    localized	
                  }	
                }	
              }	
              usdPricing{	
                onSale	
                price{	
                  net{	
                    localized	
                  }	
                }	
              }
            }
            editors {
              name
            }
          }
        }
      }
      categories {
        id
        name
      }
      authors {
        id
        name
      }
    }
  }
`;

const INTERESTED_USERS = gql(`
mutation UserToBlog($email: String!, $blogSlug: String!){
  addInterestedUserToBlog(input: {email: $email, blogSlug: $blogSlug}){
    errors{
      field
      message
    }
  }
}
`);

const SIGNUP_URI = `${process.env.REACT_APP_BACKEND_URL}/zoho/signup-newsletter/`;

const BlogDetailPage = (props) => {
  const [singleBlog, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { slug: blogSlug } = useParams();
  const [scroll, setScroll] = useState(false);
  const [submitUser, setSubmitUser] = useState(false);
  const [loginDialog, showLoginDialog] = useState(false);
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const blogContentRef = React.useRef();

  const { client, history: { push }, authState, location: { search } } = props;
  const { currency } = authState;
  const cmsData = props.cmsData;

  const UsersToBlog = (email) => {
    return client
      .mutate({
        mutation: INTERESTED_USERS,
        variables: {
          email,
          blogSlug,
        },
      })
      .then((res) => {
        // Toast message
        alert(`You have successfully submitted your email id.`);
        setSubmitUser(true);
        // Logout user
      });
  };

  const UserInfoBlog = async (firstName, lastName, email) => {
    // sendUserToZoho(firstName, lastName, email);
    UsersToBlog(email);
  };

  const sendUserToZoho = async (firstName, lastName, email) => {
    const body = {
      First_Name: firstName,
      Last_Name: lastName,
      Email: email,
    };
    const authToken = "";
    const MODULE_NAME = "";
    try {
      await axios.post(`https://www.zohoapis.com/crm/v2/${MODULE_NAME}`, body, {
        headers: {
          Authorization: `Zoho-oauthtoken ${authToken}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

 

  useEffect(() => {
    const { br = false } = getParamsObjFromString(search);
    if (authState.authToken === null && authState.isLoading == false) {
      if (br) return;
      const interval = setInterval(() => {
        showLoginDialog(true);
      }, 20000);
      return () => clearInterval(interval);
    }
    showLoginDialog(false);
  }, [authState]);

  useEffect(() => {
    client
      .query({
        query: LOAD_BLOG_DETAILS,
        variables: {
          slug: blogSlug,
        },
      })
      .then(({ data, loading }) => {
        if (!loading && data) {
          dispatch({ type: "HANDLE_BLOG_DATA", data: data.blog });
        }
      });
  }, [blogSlug]);

  const formSubmit = (event) => {
    event.preventDefault();
    if (email === '') {
      return;
    }
    fetch(SIGNUP_URI, {
      method: 'POST',
      body: JSON.stringify({ email: email }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.status >= 200 && response.status < 300) {
        console.log('zoho ' + response.json);
        setEmail('');
        setSubscribed(!subscribed);
        FbPixel.trackCustom('NewsletterSignUp', {
          'product_type': 'newsletter',
          'content_type': 'blog',
          'content_name': singleBlog.blog.title,
        });

      } else {
        console.log('zoho Somthing went wrong');
      }
    }).catch(err => err);
  };

  return (
    <>
      <Blog>
        <Row>
          <Col md="12" className="mt-5">
            <img
              src={replaceStaticUrl(singleBlog.blog.image)}
              style={{ objectFit: "cover", maxHeight: "616px" }}
              className="img-fluid"
            />
            <div
              ref={blogContentRef}
              style={{ minHeight: "35em" }}
              className="my-4"
            >
              <BlogContent>
                <h6 class="mb-1">
                  {singleBlog.blog.publicationDate}
                  <span> | </span>
                  {singleBlog.blog.authors &&
                    singleBlog.blog.authors.map(({ name }) => name).join(", ")}
                </h6>

                {singleBlog.blog.categories &&
                  <Category className="row ml-0" style={{
                    color: 'grey', cursor: 'pointer', marginBottom: '5px',
                    paddingLeft: "0", fontSize: "large"
                  }}>
                    {singleBlog.blog.categories.map((category) => (
                      <div className="meta"
                        onClick={() => {
                          push(`/blog?category-id=${category.id}`);
                        }}
                      >
                        {category.name}
                      </div>
                    ))}
                  </Category>}


                <h3 className="title">{singleBlog.blog.title}</h3>
              </BlogContent>
              <br />
              <div className="flow-html-content row">
                <p className="mb-0 col-12 col-md-9">
                  {ReactHtmlParser(singleBlog.blog.description)}
                </p>
              </div>
            </div>
            <br />


          </Col>
          <div className="related-blog-container mb-5">
            <div className="subscribe-heading mb-2">Sign up for our Newsletters:</div>
            <div className="subscribe-description" >Receive articles from the Marg archives and our weekly essay, Marg Marches, which explores the latest developments at the intersection of art and science.</div>
            <div className="form my-3">
              <form

                onSubmit={formSubmit}


              >
                <input
                  type="email"
                  name="email"
                  className="email"
                  id="email"
                  placeholder="email address"
                  required
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  placeholder="Your Email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  title="Invalid email address"
                  required
                  className="subscribe"
                  disabled={subscribed}
                />
                <RaisedButton
                  disabled={subscribed}
                  className='btn-hover'
                  type="submit"
                  colorType=""
                  onClick={() => {
                  }}
                >
                  {subscribed ? 'Subscribed' : 'Sign up'}
                </RaisedButton>
              </form>

            </div>

          </div>



          {singleBlog &&
            singleBlog.blog.relatedBlogs && singleBlog.blog.relatedBlogs.length > 0 && (
              <div className="related-blog-container">
                <div className="container row">
                  <h2 className="blog__ym_like-this">{cmsData && cmsData.relatedBlogsTitle}</h2>
                </div>
                <div className="row">
                  {singleBlog &&
                    singleBlog.blog.relatedBlogs &&
                    singleBlog.blog.relatedBlogs.map((blog) => {
                      return (
                        <Card props={props} blog={blog} />
                      );
                    })}
                </div>
              </div>)}
          {singleBlog &&
            singleBlog.blog &&
            singleBlog.blog.products && singleBlog.blog.products.edges && singleBlog.blog.products.edges.length > 0 && (
              <Col xs="12" className="mt-5">
                <Row>
                  <Col xs={12}><h2 className="blog__ym_like-this">{cmsData && cmsData.productsTitle}</h2></Col>
                  {singleBlog.blog.products.edges.length > 0 &&
                    singleBlog.blog.products.edges.map((product, id) => (
                      <ProductCard currency={currency} key={id} {...product.node} className="col-6 col-md-3" />
                    ))}
                </Row>
              </Col>
            )}
        </Row>
        <SubscriptionsForBlog
          auth={authState} isAuthenticated={authState && authState.id}
          subscriptions={authState && authState.subscriptions}
          errorNotification={props.errorNotification} successNotification={props.successNotification} />
      </Blog>
      {/* {authState.authToken === null && <SignUpDialog open={loginDialog} setOpen={showLoginDialog}/>} */}
    </>
  );
};

const mapStateToProps = state => {
  return {
    authState: state.auth,
    cmsData: state.cmsData && state.cmsData.blogIndexPage
  }
};
const mapDispatchToProps = (dispatch) => ({
  successNotification: (message) => dispatch(actions.successNotification(message)),
  errorNotification: (message) => dispatch(actions.errorNotification(message)),
})

const enhance = compose(withApollo, withRouter, connect(mapStateToProps, mapDispatchToProps));

export default enhance(BlogDetailPage);