import React, { useEffect, useReducer, useState } from "react";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import BlogCard from "./BlogCard/index.jsx";
import RaisedButton from "../commons/RaisedButton";
import { getParamsObjFromString } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  Heading: {
    color: "#000000",
    fontFamily: "Cormorant Garamond, serif",
    fontSize: "42px",
    fontWeight: "500",
    letterSpacing: "1px",
    lineHeight: "57px",
  },
  tabPanel: {
    "& button": {
      outline: "none",
    },
    "& .Mui-selected span": {
      color: "#ec1d24",
    },
    "& .MuiTabs-scroller > div.MuiTabs-flexContainer": {
      [theme.breakpoints.up('lg')]: {
        justifyContent: "center",
      }
    },
    "& span": {
      fontFamily: "Lato",
      color: "#3a3a3a",
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: 3,
      textTransform: `uppercase`,
    },
    "& span.MuiTabs-indicator": {
      backgroundColor: "#ec1d24",
    },
  },
}));

const INITIAL_STATE = {
  current_category: null,
  currentCategoryId: 0, //Only used by tab component to show newly selected tab.
  showCounter: 5,
  categories: [
    {
      name: "All",
    },
  ],
  blogs: [],
  visibleBlogs: [],
  errors: null,
  loading: true,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "HANDLE_CHANGE_CATEGORY":
      if (action.selected_category !== state.current_category) {
        const newCounter = 5;
        return {
          ...state,
          hasMoreBlogs: state.blogs.filter((blog) => {
            if (!action.selected_category.id) {
              return true;
            }
            return (
              blog.categories.filter(
                (cat) => cat.id === action.selected_category.id
              ).length > 0
            );
          }).length > newCounter,
          current_category: action.selected_category,
          visibleBlogs: state.blogs.filter((blog) => {
            if (!action.selected_category.id) {
              return true;
            }
            return (
              blog.categories.filter(
                (cat) => cat.id === action.selected_category.id
              ).length > 0
            );
          }).slice(0, newCounter),
          currentCategoryId: state.categories.findIndex((category) => {
            if (!action.selected_category.id) return true;
            if (category.id === action.selected_category.id) return true;
          }),
          showCounter: newCounter,
          loading: false,
        };
      }
    return state;

    case "HANDLE_VIEW_MORE":
      const newCounter = state.showCounter + 4;
      return {
        ...state,
        showCounter: newCounter,
        hasMoreBlogs: state.blogs.filter((blog) => {
          if (!action.selected_category || !action.selected_category.id) {
            return true;
          }
          return (
            blog.categories.filter(
              (cat) => cat.id === action.selected_category.id
            ).length > 0
          );
        }).length > newCounter,
        visibleBlogs: state.blogs.filter((blog) => {
          if (!state.current_category || state.current_category.name === 'All') {
            return true;
          }
          return (
            blog.categories.filter(
              (cat) => cat.id === state.current_category.id
            ).length > 0
          );
        }).slice(0, state.showCounter + 4),
      };
      

    case "HANDLE_BLOG_DATA":
      return {
        ...state,
        blogs: action.blogs,
        visibleBlogs: action.blogs.slice(0, state.showCounter),
        loading: false,
        hasMoreBlogs: action.blogs.length > state.showCounter,
      };

    case "HANDLE_CATEGORY_DATA":
      return {
        ...state,
        categories: state.categories.concat(action.categories),
        loading: false,
      };

    case "HANDLE_ERROR":
      return {
        ...state,
        error: true,
        loading: false,
      };

    default:
      return state;
  }
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const LOAD_BLOGS = gql`
  query blogs {
    blogs {
      id
      title
      slug
      description
      textDescription
      image
      publicationDate
      categories {
        id
        name
      }
      authors {
        id
        name
      }
    }
    blogCategories {
      id
      name
    }
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function BlogListingPage({ client, location: { search } }) {
  const classes = useStyles();
  const [blogState, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    client
      .query({
        query: LOAD_BLOGS,
      })
      .then(({ data, loading }) => {
        if (!loading) {
          dispatch({ type: "HANDLE_BLOG_DATA", blogs: data.blogs });
          dispatch({
            type: "HANDLE_CATEGORY_DATA",
            categories: data.blogCategories,
          });
          const categoryId = search.replace("?category-id=", "");
          if (!categoryId) return;
          dispatch({
            type: "HANDLE_CHANGE_CATEGORY",
            selected_category: { id: categoryId },
          });
        }
      });
  }, []);

  return (
    <div className={`${classes.root} mb-3`}>
      <div className="text-center py-5 px-xl-5">
        <h4 className={classes.Heading}>MARG-E-ZINE</h4>
      </div>
      <Tabs
        centered
        value={blogState.currentCategoryId}
        onChange={(e, catId) => {
          dispatch({
            type: "HANDLE_CHANGE_CATEGORY",
            selected_category: blogState.categories[catId],
          });
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        className={classes.tabPanel}
      >
        {blogState.categories.map((blogType, index) => (
          <Tab key={index} label={blogType.name} {...a11yProps(index)} />
        ))}
      </Tabs>
      <BlogCard
        blogs={blogState.visibleBlogs}
        handle_selected_category={(selected_category) => {
          dispatch({ type: "HANDLE_CHANGE_CATEGORY", selected_category });
        }}
      />
      {blogState.hasMoreBlogs ? (
        <center>
          <RaisedButton
            className="btn-hover mb-2" 
            onClick={() => dispatch({ type: "HANDLE_VIEW_MORE" })}
          >
            VIEW MORE
          </RaisedButton>
        </center>
      ) : null}
    </div>
  );
}

export default withApollo(BlogListingPage);
