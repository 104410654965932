const INITIAL_CMS_STATE = {
  "home": {
    "persons": [],
    "pageData": [],
    "testimonials": [],
  },
  "about": {
    "persons": [],
    "pageData": []
  },
  "collaborate": {
    "persons": [],
    "pageData": []
  },
  "team": {
    "persons": [],
    "pageData": []
  },
  "trustees": {
    "persons": [],
    "pageData": []
  },
  "proposal": {
    "persons": [],
    "pageData": []
  },
  "donate": {
    "persons": [],
    "pageData": []
  },
  "advertise": {
    "persons": [],
    "pageData": {}
  },
  "supporters": {
    "persons": [],
    "pageData": {}
  },
  "bookStores": {
    "pageData": {}
  },
  "subscription": {
    "pageData": {}
  },
  "historical": {
    "pageData": []
  },
  "banner": {
    "pageData": {}
  }
}

export const CmsReducer = (state = INITIAL_CMS_STATE, action) => {
  switch(action.type) {
    case 'WRITE_CMS_DATA_TO_STORE':
      console.log(action.cmsData)
      return {
        ...state,
        ...action.cmsData,
      };
      
    default:
      return state;
  }
};



