import React from "react";
import styled from "styled-components";


const StyledWrapper = styled.div`

margin: 50px 0;
li {
  font-size: 14px;
  color: #747474;
}
h5 {
  font-size: 16px;
  margin-left: 23px;
}
`;

function DigitalTerms() {
  return (
    <StyledWrapper>
    <div className="ml-md-0 ml-lg-4 dt-subscription">
      <h5>Terms of Use of Digital Magazines and Articles</h5>
      <ul>
        <li>
        Digital product purchases of magazine, books, etc. and subscription based purchases are for perpetuity and will not expire
        </li>
        <li>
        To purchase a digital product, the user will be prompted to register on the website. 
        Once the purchase is complete the user can access the content by logging into the account and 
        going to the 'Orders & Profile' section under 'My Account'.
        </li>
        <li>
        The magazines, articles or any other digital content purchased on the website cannot be printed, 
        downloaded or stored offline.
        </li>
        <li>
        Users should not share their login details with others and access is permitted only from a single device at a time. 
        A second login to the same account will cause the earlier session to terminate automatically.
        </li>
      </ul>
    </div>
    </StyledWrapper>
  );
}


export default DigitalTerms;
