import React, { useEffect, useState } from "react";
import styled from "styled-components";

import gql from "graphql-tag";

import { MovieCard } from "./MovieCard.jsx";

const Wrapper = styled.div`

  animation: aboutFadeIn ease 1s;
  @keyframes aboutFadeIn { 
  0% { 
  opacity: 0;
  transform: translate3d(0, -5%, 0);
  } 
  100% { 
  opacity: 1;
  transform: none;
  } 
  }
  div.header {
    font-family: ${props => props.theme['$font-secondary-medium']};
    font-size: ${props => props.theme['$font-size-lg']};
    font-weight: ${props => props.theme['$weight-regular']};
    letter-spacing: 1px;
    line-height: 57px;
    padding: 100px 0px 60px;
    text-align: center;
  }
  div.description {
    margin-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  div.no-films {
    font-family: ${props => props.theme['$font-primary-medium']};
    font-size: ${props => props.theme['$font-size-xs']};
  }
`;

const MOVIES_QUERY = gql`
  query ListMovies{
    videos{
      id
      name
      images{
        url
      }
      thumbnailUrl
      textDescription
      videoWriters {
        name
      }
      videoProducers {
        name
      }
      videoDirectors{
        name
      }
    }
    me{
      orders(first:10, videoOnly: true){
        edges{
          node{
            id
            lines{
              id
              variant{
                id
                product {
                  id
                  name
                  category{
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const MovieListComponent = ({client}) => {

  const [ videos, setVideos ] = useState();
  const [ boughtVideoIds, setBoughtVideoIds ] = useState();

  useEffect(() => {
    client.query({
      query: MOVIES_QUERY
    }).then(({ data }) => {
      const {videos, me} = data;
      setVideos(videos);
      if(!me) return;
      const {
        orders: {
          edges,
        } = {}
      } = me || {};
      let prodIds = [];
      edges.forEach(({node: {id: orderId, lines} }) => {
        lines.forEach(({ id: lineId, variant: {product: { id, category }={} }={} }) => {
          if(category.name === "Videos") {
            prodIds = prodIds.concat({id, orderId, lineId });
          }
        });
      });
      setBoughtVideoIds(prodIds);
    })
  }, []);

  const productAlreadyBought = (prodId) => boughtVideoIds && boughtVideoIds.find(({id}) => id === prodId)



  return <Wrapper>
    <div className="my-5 container">
      <h1 className="heading mb-5">Marg Films</h1>
      {
        (!videos || videos.length === 0) && (
          <div className="text-center no-films">No Films available yet</div>
        )
      }
      <div className="row">
        {
          videos && videos.map(
            (movie) => 
              <MovieCard
                className="col-12 col-md-6"
                alreadyBought={productAlreadyBought(movie.id)}
                {...movie}
              />
          )
        }
      </div>
      
    </div>
  </Wrapper>
}

export default MovieListComponent;