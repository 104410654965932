import React, { useEffect , useState } from "react";
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import styled from 'styled-components';

import EventList from "./EventList.jsx";

const Event = styled.div`

  animation: eventsFadeIn ease 1s;

  @keyframes eventsFadeIn { 
  0% { 
  opacity: 0;
  transform: translate3d(0, -2%, 0);
  } 
  100% { 
  opacity: 1;
  transform: none;
  } 
  }
  .Title{
    color: #000000;
    font-family: ${props => props.theme['$font-secondary-medium']};
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 57px;
    text-align : center;
  }
`;

const LOAD_EVENTS = gql`
  query events {
    futureEvents{
      id
      slug
      title
      description
      image
      commenceDate
      eventType
      city
      canRegister
    }
    pastEvents{
      id
      slug
      title
      description
      image
      commenceDate
      eventType
      city
      canRegister
    }
  }
`;

const Events = ({client}) =>{

  const [ futureEvents , setFutureEvents ] = useState([]);
  const [ pastEvents , setPastEvents ] = useState([]);


  useEffect(()=>{

    client.query({
      query: LOAD_EVENTS,
    }).then(({ data, loading }) => {
      if(!loading) {
        setFutureEvents(data.futureEvents);
        setPastEvents(data.pastEvents);
      }
    });

    

  },[]);
  return(
    <Event>
      <p className = "Title my-5">Events</p>
      <EventList
        events = {futureEvents}
        event_type = "next events"
      />
      <EventList
        events = {pastEvents}
        event_type = "past events"
        noEvents="No Past events"
      />
    </Event>
  );
};

export default withApollo(Events);
