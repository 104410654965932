import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  replaceStaticUrl,
} from "./../../utils/";
import { FlatButton } from "./../commons/";

const Wrapper = styled.div`
  div.header {
    font-family: ${props => props.theme['$font-secondary-medium']};
    font-size: ${props => props.theme['$font-size-lg']};
    font-weight: ${props => props.theme['$weight-regular']};
    letter-spacing: 1px;
    line-height: 57px;
    padding: 100px 0px 60px;
    text-align: center;
  }

  img {
    height: 300px;
    max-height: 300px;
    min-height: 300px;
    object-fit: cover;
    width: 100%;
  }

  div.name {
    color: ${props => props.theme.mainTextColor};
    letter-spacing: 0.66px;
    font-family: ${props => props.theme["$font-primary-medium"]};
    font-size: ${props => props.theme["$font-size-xs"]};
    line-height: ${props => props.theme["$font-size-sm"]};
    font-weight: ${props => props.theme["$weight-bold"]};
    margin-top: 15px;
  }

  div.description {
    color: ${props => props.theme.mainTextColor};
    letter-spacing: 0.59px;
    font-family: ${props => props.theme["$font-primary-medium"]};
    font-size: ${props => props.theme["$font-size-xxs"]};
    font-weight: ${props => props.theme["$weight-regular"]};
    line-height: ${props => props.theme["$font-size-sm"]};
  }

  div.meta {
    margin-top: 10px;
    font-family: ${props => props.theme["$font-primary-medium"]};
    font-size: ${props => props.theme["$font-size-xxs"]};
    letter-spacing: 0.59px;
    line-height: 23px;

    & .key {
      color: ${props => props.theme.mainTextColor};
      font-weight: ${props => props.theme["$weight-regular"]};
    }

    & .value {
      color: ${props => props.theme.secondaryColor};
      font-weight: ${props => props.theme["$weight-bold"]};
    }
  }
`;

const MetaDetails = ({label, values}) => {
  return [
    values && values.length > 0 && <span className="key">{label}</span>,
    <span className="value">
      {
        values && values.map(({name}) => name).join(', ')
      }
    </span>
  ]
}

export const MovieCard = ({
  id,
  name,
  textDescription,
  images,
  thumbnailUrl,
  videoDirectors,
  videoProducers,
  videoWriters,

  className,
  alreadyBought,
}) => {

  const movieUrl = () => {
    if(!alreadyBought) {
      return `/product/${id}`;
    }
    return `/player/?order-id=${alreadyBought.orderId}&line-id=${alreadyBought.lineId}`
  }

  return <Link to={movieUrl()}>
    <Wrapper className={className}>
    <img
      className="img-fluid"
      src={replaceStaticUrl(
        images && images.length > 0 ? images[0].url : thumbnailUrl
      )}
    />
    <div className="name">{name}</div>
    <div className="description">{textDescription}</div>
    <div className="meta">
      <div>
        <MetaDetails label="Directed by:" values={videoDirectors} />
        {
          videoDirectors && videoDirectors.length > 0 && videoProducers && videoProducers.length > 0
            && <span>&nbsp;,&nbsp;&nbsp;</span>
        }
        <MetaDetails label="Produced by:" values={videoProducers} />
      </div>
      <MetaDetails label="Research & Writing:" values={videoWriters} />
    </div>
    <FlatButton type="primary" >{alreadyBought? "Watch now": "Buy now"}</FlatButton>
  </Wrapper>
  </Link>
}