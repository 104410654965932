import React, { useState, useEffect, useReducer } from 'react';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import ReactGA from 'react-ga';
import FbPixel from "../../../utils/fbPixel"
import logo from "./../../../images/logo.png";
import { DropDown, RaisedButton } from "./../../commons";
import { AddressList } from "./../AddressList.jsx";
import { withRouter } from 'react-router';
import { withApollo } from "react-apollo";

const Wrapper = styled.div`
  padding: 25px;
  cursor: pointer;

  .iDfFzh > .label:hover {
    background-color: unset;
  }

  & > .header {
    color: #000000;
    font-family: ${props => props.theme['$font-primary-medium']};
    font-size: ${props => props.theme['$font-size-sm']};
    font-weight: ${props => props.theme['$weight-bold']};
    letter-spacing: 0.96px;
    line-height: 23px;
    padding-top: 25px;
    text-align: center;
    background-color: #f8f8f8;
  }

  & > .body {
    padding: 20px 15px;
    background-color: #f8f8f8;
    height: 90%;

    & > .desc {
      color: #000000;
      font-family: ${props => props.theme['$font-primary-medium']};
      font-weight: ${props => props.theme['$weight-regular']};
      font-size: ${props => props.theme['$font-size-xxs']};
      font-weight: 400;
      letter-spacing: 0.66px;
      line-height: 23px;

      & .sub-heading {
        text-align: center;
        color: ${props => props.theme.primaryColor};
        font-family: ${props => props.theme['$font-primary-medium']};
        font-size: ${props => props.theme['$font-size-xs']};
        font-weight: ${props => props.theme['$weight-regular']};
        min-height: 46px;
        margin-bottom: ${props => props.theme['$font-size-xs']};
      }

      & > .sub-desc {
        min-height: 138px;
      }

      & p {
        margin-bottom: 0px;
      }
    }

    & > .price {
      color: #000000;
      padding-top: 30px;
      & > .details {
        font-family: ${props => props.theme['$font-primary-medium']};
        font-weight: ${props => props.theme['$weight-bold']};
        font-size: 21px;
        letter-spacing: 1.62px;
        & > .original {
          text-decoration: line-through;
        }
      }
      & > .term {
        font-family: ${props => props.theme['$font-primary-medium']};
        font-weight: ${props => props.theme['$weight-bold']};
        font-size: 14px;
        letter-spacing: 1.08px;
      }
    }

    & > .discount {
      font-size: 21px;
      letter-spacing: 0.77px;
      line-height: 23px;
      text-align: center;
      font-family: ${props => props.theme['$font-primary-medium']};
      font-weight: ${props => props.theme['$weight-bold']};
      color: ${props => props.theme.primaryColor};
    }

    & > .plan-subscribed {
      color: ${props => props.theme.primaryColor};
      font-family: ${props => props.theme['$font-primary-medium']};
      font-size: 21px;
      font-weight: ${props => props.theme['$weight-bold']};
      font-style: normal;
      letter-spacing: 0.77px;
      line-height: 23px;
      text-align: center;
    }

    & .footer {
      margin-top: 30px;
      & .dropdown {
        padding-left: 0px !important;
        & > button {
          padding: 10px;
          & .selected-options {
            display: none;
          }
        }
      }
      & > .notes {
        margin-top: 12px;
        color: #000000;
        font-family: ${props => props.theme['$font-primary-medium']};
        font-size: ${props => props.theme['$font-size-xxxs']};
        font-weight: ${props => props.theme['$weight-regular']};
        font-style: normal;
        letter-spacing: 0.05px;
        line-height: 18px;
        text-align: center;
      }

      & > .featured-notes {
        min-height: 72px;
        & > p {
          margin: 0px !important;
        }
      }
    }

  }

`;

const SAVE_SUBSCRIPTION = gql`
  mutation SaveSubscription(
    $subscriptionId: ID!,
    $pricingId: ID!,
    $currency: String!,
    $shippingAddress: AddressInput!
  ) {
    buySubscription(
      input:{
        subscriptionId: $subscriptionId,
        pricingId: $pricingId,
        currency: $currency,
        address: $shippingAddress,
      }
    ) {
      razorpaySubscriptionToken
      errors{
        message
      }
    }
  }
`;

const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription(
    $userSubscriptionId: ID!,
  ) {
    cancelSubscription(
      userSubscriptionId: $userSubscriptionId
    ) {
      cancelled
      errors{
        field
        message
      }
    }
  }
`;


const INITIAL_STATE = {
  id: null,
  currency: null,
  amount: null,
  duration: 1,
  accessToIssues: 4,
  selectedAddress: null,
  showAddressSelection: false,
  showPaymentLink: false,
  addresses: [],//This will be set to redux's auth.addresses in the first load of the component.
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PRICE':
      return{
        ...state,
        ...action.payload,
      };
    case 'SET_ADDRESS':
      return{
        ...state,
        selectedAddress: {...action.payload},
      };
    case 'SHOW_ADDRESSES':
      return {
        ...state,
        showAddressSelection: true,
      };
    case 'HIDE_ADDRESSES':
      return {
        ...state,
        showAddressSelection: false,
      };
    case 'SHOW_PAYMENT_LINK':
      return {
        ...state,
        showAddressSelection: false,
        showPaymentLink: true,
      };
    case 'HIDE_PAYMENT_LINK':
      return {
        ...state,
        showAddressSelection: false,
        showPaymentLink: false,
      };
    case 'ADD_NEW_ADDRESS':
      return {
        ...state,
        addresses: state.addresses.concat(action.payload),
      }
    case 'ADD_USERS_ADDRESSES':
      return {
        ...state,
        addresses: [
          ...action.payload,
        ],
      }
  }
}

const SUBSCRIPTION_STATUS = {
  NOT_APPLICABLE: "NOT_APPLICABLE",
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  LOADING: "LOADING",
};

export const SubscriptionItem = withApollo(withRouter(({
  id,
  name,
  description,
  subHeading,
  pricings,
  issueType,
  // User details start
  email,
  firstName,
  lastName,
  currency,
  isAuthenticated,
  subscriptions,
  addresses,
  // User details end
  history: { push }={},
  location,
  reloadAuthenticatedUser,
  client,
  successNotification,
  errorNotification,
  className,
  readOnly = false,
  startDate,
  endDate,
  isFeatured,
  notesInr,
  notesUsd,
}) => {

  let successCallback, errorCallback;
  if(successNotification && errorNotification) {
    successCallback = successNotification.bind(null, `Success, you are now suscribed to ${name}`);
    errorCallback = errorNotification.bind(null, "Something went wrong during payment please try again later.");
  }
  

  const [ priceState, dispatch ] = useReducer(reducer, INITIAL_STATE);
  const [ subscriptionStatus, setSubscriptionStatus ] = useState(SUBSCRIPTION_STATUS.NOT_APPLICABLE);

  useEffect(() => {
    if(!subscriptions || subscriptions.length === 0) return;
    const boughtSubscription = checkIfSubscriptionBought(id);
    if(!boughtSubscription) return;
    setSubscriptionStatus(
      boughtSubscription.isCancelled? SUBSCRIPTION_STATUS.CANCELLED: SUBSCRIPTION_STATUS.ACTIVE
    );
  }, [subscriptions]);

  useEffect(() => {
    if(pricings != null && pricings.length > 0) {
      setDuration(pricings[0]);
    }
  }, [pricings]);

  const setDuration = (pricing) => {
    if(!pricing){
      return;
    }
    dispatch({type: "SET_PRICE", payload: { ...pricing } });
  }

  const createSubscriptionTemplate = () => {
    ReactGA.event({
      category: 'E-Commerce Action',
      action: `Subscription razorpay payment complete`,
      label: name,
    });
    const shippingAddress = { ...priceState.selectedAddress };
    delete shippingAddress.id;
    delete shippingAddress.__typename;
    shippingAddress.country = typeof(shippingAddress.country) == "object"? shippingAddress.country.code: shippingAddress.country;
    return client.mutate({
      mutation: SAVE_SUBSCRIPTION,
      variables: {
        subscriptionId: id,
        pricingId: priceState && priceState.id? priceState.id: null,
        shippingAddress,
        currency: currency,
      }
    }).then(({data}, errors) => {
      ReactGA.event({
        category: 'E-Commerce Action',
        action: `Subscription purchase success`,
        label: name,
      });
      FbPixel.track('InitiateCheckout', {
        'product_type': 'subscription',
        'label': name,
        'content_ids': [id], 
        'content_type': 'product',
        'currency': currency,
        'value': localizedPrice
      });
      return data && data.buySubscription ? data.buySubscription.razorpaySubscriptionToken: null;
    }).catch((err) => {
      errorCallback();
      return null
    });
  }
  
  const RAZORPAY_OPTIONS = {
    key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
    name: "Marg",
    image: logo,
    handler: function(response) {
      reloadAuthenticatedUser()
      if (response.razorpay_payment_id) {
          FbPixel.track('Purchase', {
          'product_type': 'subscription', 
          'label': name,
          'content_ids': [id], 
          'content_type': 'product',
          'currency': currency,
          'value': localizedPrice
        });
        FbPixel.trackCustom('SubscriptionPurchaseSuccess', {
          'label': name,
          'content_ids': [id], 
          'content_type': 'product',
          'currency': currency,
          'value': localizedPrice
        });
        FbPixel.track('Subscribe', {
          'label': name,
          'content_ids': [id],
          'content_type': 'product',
          'currency': currency,
          'value': localizedPrice
        });
        return successCallback();
      }
    },
    prefill: {
      email,
      "name": `${firstName} ${lastName}`,
    },
    theme: {
      color: "#F37254"
    },
    modal: {
      ondismiss: function() {
        errorCallback();
        ReactGA.event({
          category: 'E-Commerce Action',
          action: `Subscription razorpay payment failed`,
          label: name,
        });
        FbPixel.trackCustom('SubscriptionPurchaseCancelled', {
          'product_type': 'subscription', 
          'label': name,
          'content_ids': [id], 
          'content_type': 'product',
          'currency': currency,
          'value': localizedPrice
        });
      }
    }
  };

  const subscriptionClicked = () => {
    if(!isAuthenticated) {
      return push(`/login`, {from: location})
    }
    ReactGA.event({
      category: 'E-Commerce Action',
      action: 'Adding subscription',
      label: name
    });
    FbPixel.track('SubscriptionSelected', {'label': name});
    FbPixel.track('AddToCart', {'type': 'Subscription',
                                'content_name': priceState.name, 
                                'content_ids':[priceState.id],
                                'content_type': 'product',
                                'currency': currentCurrency=="₹"?"INR":"USD", 
                                'value': localizedPrice,
                                'duration': priceState.duration
                               });
    dispatch({type: "SHOW_ADDRESSES"});
  }

  const showPaymentPopup = (subId) => {
    if(subId) {
      RAZORPAY_OPTIONS.subscription_id = subId;
      RAZORPAY_OPTIONS.notes = {name, type: "Subscriptions", duration: priceState.duration};
      const razpay = new window.Razorpay(RAZORPAY_OPTIONS);
      razpay.on('payment.failed', (response) => {
        ReactGA.event({
          category: 'E-Commerce Action',
          action: `Razorpay subscription payment failed`,
          label: response.razorpay_payment_id,
        });
        FbPixel.trackCustom('SubscriptionPurchaseFailed', {
          'product_type': 'subscription', 
          'label': name,
          'content_ids': [id], 
          'content_type': 'product',
          'currency': currency,
          'value': localizedPrice
        });
      });
      setTimeout(() => razpay.open(), 1000);
    }
  }

  useEffect(() => {
    if(addresses && addresses.length > 0) {
      dispatch({type: "ADD_USERS_ADDRESSES", payload: addresses});
    }
  }, [addresses])

  const checkIfSubscriptionBought = (id) => 
    subscriptions.find(({ isActive, subscription: {id: sId} }) => sId === id && isActive)

  let localizedPrice, originalPrice, term, discountText, currentCurrency;
  if(priceState && priceState.usdPrice && priceState.inrPrice) {
    currentCurrency = currency === 'INR'? "₹": "$";
    localizedPrice = currency === 'INR' ? priceState.inrPrice.amount: priceState.usdPrice.amount;
    originalPrice = currency === 'INR' ? priceState.originalInrPrice.amount: priceState.originalUsdPrice.amount;
    term = priceState.term;
    discountText = priceState.discountText;
  }

  const cancelSubscription = (subscriptionId) => {
    const boughtSubscription = checkIfSubscriptionBought(subscriptionId);
    if(!boughtSubscription) return;
    const userSubscriptionId = boughtSubscription.id;
    setSubscriptionStatus(SUBSCRIPTION_STATUS.LOADING);
    return client.mutate({
      mutation: CANCEL_SUBSCRIPTION,
      variables: {
        userSubscriptionId,
      }
    }).then(({data}, errors) => {
      setSubscriptionStatus(SUBSCRIPTION_STATUS.CANCELLED);
      return data && data.buySubscription ? data.buySubscription.razorpaySubscriptionToken: null;
    }).catch((err) => {
      setSubscriptionStatus(SUBSCRIPTION_STATUS.ACTIVE);
      errorCallback();
      return null
    });
  }

  return (
    <Wrapper className={className}>
      <div className="header">{name}</div>
      <div className="body d-flex flex-column justify-content-between">
        <div className="desc">
          {
            subHeading && subHeading != "<p><br></p>" && <div className="sub-heading">
              {ReactHtmlParser(subHeading)}
            </div>
          }
          <div className="sub-desc">{ReactHtmlParser(description)}</div>
        </div>
        {
          readOnly && 
            <div className="row">
              <div className="col-6">From: {startDate}</div>
              <div className="col-6">To: {endDate}</div>
            </div>
        }
        {
          !readOnly && isFeatured && !checkIfSubscriptionBought(id) &&
            <div className="price" style={{textAlign: 'center'}}>
              <span className="details">
                {currentCurrency}
                <span className="original">
                  {
                    originalPrice
                  }
                </span>
                &nbsp;
                {
                  localizedPrice
                }
              </span>
              <span className="term">
                {term}
              </span>
            </div>
        }
        {
          !readOnly && !isFeatured && !checkIfSubscriptionBought(id) &&
            <div className="price" style={{textAlign: 'left'}}>
              <span className="details">
                {
                  `${currentCurrency} ${localizedPrice}`
                }
              </span>
            </div>
        }
        {
          !checkIfSubscriptionBought(id) && isFeatured && discountText &&
            <div className="discount">
              {discountText}
            </div>
        }
        {
          checkIfSubscriptionBought(id) && 
          [
            <div className="plan-subscribed">
              You are currently subscribed to this plan
            </div>,
            <RaisedButton
              className={`btn-hover`}
              onClick={() => cancelSubscription(id)}
              disabled={
                subscriptionStatus == SUBSCRIPTION_STATUS.CANCELLED ||
                subscriptionStatus == SUBSCRIPTION_STATUS.LOADING
              }
            >
            {subscriptionStatus == SUBSCRIPTION_STATUS.CANCELLED && "Cancelled"}
            {subscriptionStatus == SUBSCRIPTION_STATUS.LOADING && "Loading.."}
            {subscriptionStatus == SUBSCRIPTION_STATUS.ACTIVE && "Cancel"}
          </RaisedButton>
          ]
        }
        {
          !readOnly && !checkIfSubscriptionBought(id) &&
            <div className="container footer">
              <div className="row">
                {
                  pricings && pricings.length > 1 &&
                    <DropDown
                      key="year-selector"
                      className="col-4 col-sm-6 dropdown"
                      loadData={pricings}
                      defaultOption={pricings[0]}
                      enableSearch={false}
                      showSelectedOption={true}
                      onOptionSelect={
                        (newDuration) => setDuration(newDuration)
                      }
                      >
                    </DropDown>
                }
                <RaisedButton
                  className={`${pricings && pricings.length > 1? 'col-8 col-sm-6': 'col-sm-12'} btn-hover`}
                  onClick={(e) => subscriptionClicked()}
                  >
                  Select
                </RaisedButton>
              </div>
              {
                currency == "INR" && notesInr && notesInr != "<p><br></p>" &&
                  <div className={`notes  ${isFeatured? 'featured-notes': ''}`}>{ReactHtmlParser(notesInr)}</div>
              }
              {
                currency == "USD" && notesUsd && notesUsd != "<p><br></p>" &&
                  <div className={`notes ${isFeatured? 'featured-notes': ''}`}>{ReactHtmlParser(notesUsd)}</div>
              }
            </div>
        }
      </div>
      <AddressList
        selectedAddress={priceState.selectedAddress}
        addresses={priceState.addresses}
        open={priceState.showAddressSelection}
        hidePopup={() => dispatch({ type: "HIDE_ADDRESSES"})}
        selectAddress={(address) => dispatch({ type: "SET_ADDRESS", payload: address })}
        showPaymentLink={(subId) => {
          dispatch({ type: "SHOW_PAYMENT_LINK"});
          showPaymentPopup(subId);
        }} 
        addNewAddress={(address) => dispatch({ type: "ADD_NEW_ADDRESS", payload: address })}
        fetchSubscriptionPaymentId={async () => await createSubscriptionTemplate()}
      />
    </Wrapper>
    
 )
}));