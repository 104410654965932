import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

import { Redirect } from "react-router";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import ReactHtmlParser from "react-html-parser";
import {
  replaceStaticUrl,
  getEditorName,
} from "../../utils";
import { Container, Row, Col } from "reactstrap";
import Article from "./Article";
import { ProductPricing } from "./ProductPricing.jsx";
import Description from "./Description";
import Breadcrumbs from "../Breadcrumbs";
import BottomBar from './BottomBar';
import { PreviousIssues } from "./LatestIssues";
import { InsideThisIssue } from "./InsideThisIssue";
import useIntersection from './useIntersection'

const Wrapper = styled.div`
  animation: aboutFadeIn ease 2s;
  animation-delay: 0.5s;
  @keyframes aboutFadeIn { 
  0% { 
  opacity: 0;
  transform: translate3d(0, -5%, 0);
  } 
  100% { 
  opacity: 1;
  transform: none;
  } 
  }

  @media (min-width: 1140px) {
    padding: 0px 100px 100px;
  }

  .img-fluid {
    width: 100%;
    
  }

 
  

 

  .carousel {
    box-shadow: 0px 3px 6px #00000029;
  }

  


  .name {
    font-family: ${props => props.theme["$font-secondary-medium"]};
    font-size: 36px;
    line-height: 43px;
    @media (max-width: 1139px ) {
      font-size: 30px;
      line-height: 34px;
    }
    @media (max-width: 767px ) {
      font-size: 28px;
      line-height: 32px;
    }
    font-weight: ${props => props.theme["$weight-medium"]};
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0 !important;
  }

  .subname {
    font-family: ${props => props.theme["$font-secondary-medium"]};
    font-size: 26px;
    margin-bottom: 15px;
    line-height: ${props => props.theme["$font-size-sm"]};
    @media (max-width: 1139px) {
        font-size: 22px;
        margin-bottom: 10px;
    }
    @media (max-width: 767px) {
      font-size: 20px;
      margin-bottom: 5px;
    }
   
    letter-spacing: 1px;
  }

}



  .product-type {
    color: #000000;
    font-family: Lato;
    font-size: 16px;
    @media (max-width: 1139px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
    }

    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .date-issue {
    color: ${props => props.theme.primaryColor};
    font-family: Lato;
    font-size: 16px;
    @media (max-width: 1139px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
    }
    @media (min-width: 1140px) {
      padding-bottom: 5px;
    }
    font-weight: 400;
    text-transform: uppercase;
  }

  .editor-name {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 15px;
    @media (max-width: 1139px) {
      font-size: 18px;
      
      margin-bottom: 13px;
    }
    @media (max-width: 767px) {
       font-size: 16px;
    }
    
    
  }

  // .product-info {
  //   margin:unset;
  //   margin-top: 10px;
  //   margin-bottom: 20px;
  //   @media (min-width: 1140px) {
  //     margin-top: 1.5rem;
  //     margin-bottom: 1.5rem;
      
  //   }


  // }

  .product-info {
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    @media (max-width: 1139px) {
      margin-bottom: 15px;
    }
    @media (max-width: 767px) {
      margin-right: 10px;
    }
  }

  .medium-name {
    color: #37312f;
    font-size: 18px;
    @media (max-width: 1139px) {
      font-size: 16px;
    }
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 20px;
    margin-right: 3rem;
    @media (max-width: 767px) {
      margin-right: 1.5rem;
    }
  }

  .pricing {
    color: #37312f;
    font-family: ${props => props.theme["$font-primary-medium"]};
    font-size: ${props => props.theme["$font-size-xxs"]};
    font-weight: ${props => props.theme["$weight-regular"]};
    line-height: 20px;
  }

  .add-to-bag {
    padding: unset;
    padding: 10px 30px;
    margin: 1.5rem 20px 1.5rem 0px;
    align-self: flex-start;
    display:inline-block;
    @media (max-width: 1139px) {
      padding: 10px 20px;
      margin: 1.5rem 15px 1.5rem 0px;
    }
   

  }

  .availability {
    font-size: ${props => props.theme["$font-size-xxs"]};
    font-weight: ${props => props.theme["$weight-regular"]};
    letter-spacing: 0.59px;
    line-height: 23px;
  }

  h3.section-title {
    color: #37312f;
    font-family: Lato;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 20px;
  }

  .book-review-box {
    padding: 10px 20px 20px;
    border-top: 1px solid #9d9d9d;
    border-bottom: 1px solid #9d9d9d;

    & > .reviewer-detials {
      margin-top: 20px;
      font-size: ${props => props.theme["$font-size-xxs"]};
      font-weight: ${props => props.theme["$weight-regular"]};
      letter-spacing: 0.59px;
      line-height: 23px;
    }

    & > .content {
      font-size: ${props => props.theme["$font-size-xxxs"]};
      font-weight: ${props => props.theme["$weight-regular"]};
      letter-spacing: 0.52px;
      line-height: 21px;
    }
  }

 .heading-title {
    font-family: ${props => props.theme["$font-secondary-medium"]};
    font-size: 28px;
    line-height: 31px;
    font-weight: ${props => props.theme["$weight-regular"]};
    color: ${props => props.theme.mainTextColor};
  }

  .contents {
    padding-bottom: 25px;
    

    & > div:not(.heading) {
      margin-top: 6px;
    }

    

    & > .read-more { 
      position: absolute; 
      bottom: 0; 
      left: 0;
      width: 100%; 
      text-align: center; 
      color: #ec1d24
      margin: 0; 
      padding: 160px 0 15px; 
      z-index: 1;
      /* "transparent" only works here because == rgba(0,0,0,0) */
      background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1) 80%);
    }

    & > .read-more, .read-less a {  
      color: #ec1d24
      cursor: pointer;
      font-size: 18px;
      @media (max-width: 1139px) {
        font-size: 16px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    & > .read-less { 
      width: 100%; 
      text-align: center; 
      color: #ec1d24
      margin: 0; 
      font-size: 18px;
      @media (max-width: 1139px) {
        font-size: 16px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
      }
      padding: 30px 0 30px; 
      z-index: 1;
      /* "transparent" only works here because == rgba(0,0,0,0) */
      background-image: linear-gradient(to bottom, rgba(255,255,255,0.5), rgba(255,255,255,1) 90%);
    }

    



  }

  .breadcrumb-align {
    padding-top: 10px !important;
      margin-left: -110px;
      @media (max-width: 1139px) {
        margin-left:-15px;
      }
  }

  .webview {
    flex-direction: row;
    position: 'relative'
    visibility: collapse;
    display:none
    @media (min-width: 1140px) {
      visibility: visible;
      display: flex;
    }
    padding-bottom: 30px,
    padding-top: 20px,
  }

  .webview-col { 
    display: flex;
    flex-direction: row;
    position: 'relative'
  }

  .tabview {
    display: none;
    flex-direction: row;
    visibility: collapse;
    @media (min-width: 0px) and (max-width: 1139px) {
      visibility: visible;
      display: flex;
    }
    padding-bottom: 30px,
    padding-top: 20px,
    position: 'relative'
  }

  .previous-dots{
    display: none;
    visibility: collapse;
    @media (min-width: 0px) and (max-width: 767px) {
      visibility: visible;
      display: flex;
    }
  }

  .heading-past {
    font-family: ${props => props.theme["$font-secondary-medium"]};
    font-size: 36px;
    line-height: 34px;
    @media (min-width: 768px) and (max-width: 1139px){
      font-size: 28px;
    }
    @media (max-width: 767px) {
      font-size: 26px;
    }

    font-weight: ${props => props.theme["$weight-regular"]};
    color: ${props => props.theme.mainTextColor};
  }

  .heading-current {
    font-family: ${props => props.theme["$font-secondary-medium"]};
    font-size: 32px;
    line-height: 34px;
    @media (min-width: 768px) and (max-width: 1139px){
      font-size: 28px;
    }
    @media (max-width: 767px) {
      font-size: 26px;
    }

    font-weight: ${props => props.theme["$weight-regular"]};
    color: ${props => props.theme.mainTextColor};
  }

  .grad {
    background-image: linear-gradient(357deg, #FFFBE3 100%, #FFFFFF 100%);
    vertical-align: middle;

    & >.header .mb-3{
      margin-bottom: 0px

    }

    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    @media (max-width: 1139px) {
      padding-top: 8px;
      padding-bottom: 5px;
    }
  }

  .nograd {

    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    @media (max-width: 1139px) {
      padding-top: 8px;
      padding-bottom: 5px;
    }
  }

  .buy-more {
    @media (max-width: 767px) {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }

  .arrow-left {

    position: absolute !important;
    height: 30px;
    width: 30px;
    left: -25px;
    @media (max-width: 1139px) {
      left: -10px;
      height: 20px;
      width: 20px;
    }
    top: 32%;
    z-index: 5;
    border: 1px solid grey;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 2px;
    visibility: visible;
    opacity: 0.8;
    cursor: pointer;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    @media (max-width: 767px) {
        visibility: hidden;
        width:0px;
        left:0px
      }
  
    }

    .arrow-right {

      position: absolute !important;
      height: 30px;
      width: 30px;
      right: -25px;
      @media (max-width: 1139px) {
        right: -10px;
        height: 20px;
        width: 20px;
      }
      top: 32%;
      z-index: 5;
      border: 1px solid grey;
      border-width: 0 3px 3px 0;
      display: inline-block;
      visibility: visible;
      @media (max-width: 767px) {
        visibility: hidden;
        width: 0px;
        right: 0px;
      }
      padding: 2px;
      opacity: 0.8;
      cursor: pointer;
      -webkit-transform: rotate(
        -45deg
        );
            transform: rotate(
        -45deg
        );
    
      }

      .subscription {
        font-size: 18px;
        font-weight: 500 !important;
        display:inline-block
        @media (max-width: 1139px) {
          font-size: 14px;
        }
      
      }

      .close-tooltip {
        visibility: hidden;
        @media (max-width: 1139px) {
          visibility: visible;
        }
      }

      .react-multi-carousel-dot button {
        border: unset;
        border: 2px solid transparent;
        background: #CCCCCCF7 ;
       

        
        
    }

    .react-carousel-dots-dot {
      background: #CCCCCCF7 !important;
      border: unset !important;
    }

    .react-carousel-dots-dot.active{
      background: #080808 !important;
      border: unset !important;
    }

    .react-multi-carousel-dot--active button {
      background: #080808;
  }

    .radio-container {
      margin-bottom: 25px;
      @media (max-width: 1139px) {
        margin-bottom: 15px  !important;
      }
      @media (max-width: 767px) {
        margin-bottom: 20px  !important;
      }
      
    }

    .product-pricing-div {
      margin-top: 25px;
    }
      

    .radio-container .radio {
      aspect-ratio: 1/1;
    }

    .description p {
      font-size: 18px;
        @media (max-width: 1139px) {
          font-size: 16px;
        }
    }

    .product-intro {
      flex-direction: row;
      @media (max-width: 767px) {
        flex-direction: column;
        padding-right: 0px !important;
        padding-left: 0px !important;
      }

    }

    .banner-image {
      @media (max-width: 767px) {
        padding-bottom: 20px;
      }


    }

    .details {
      @media (max-width: 767px) {
        padding:0px 0px;
      }


    }


    .view-more {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      text-align: center;
      transform: translate(-50%, -50%);
    }

    .modal {
      z-index: 1500 !important;
    }

    .header-price{
      justify-content: flex-end !important;
    }
  
      

`;

function MagazineProduct(
  { product, saveVariant, MovieMetaDetails, routes, subscriptions, currency, me }) {

  const {
    id: productId,
    name,
    subTitle,
    volumeInfo,
    description,
    isCurrenctIssue: isCurrentIssue,
    images,
    isAvailable,
    editors,
    sections,
    variants = [],
    attributes,
    category,
    thumbnail: { url: thumbnailUrl } = {},
    editorType,
    productReviews,
    videoDirectors,
    videoProducers,
    videoWriters,
  } = product || {};

  const { orders: { edges: orderEdges = [] } = {} } = me || {};

  let currentUserSubscription;
  const isVideo = category && category.name === 'Videos';

  const metaInfo = attributes.reduce(
    (acc, { value, attribute } = {}) => {
      acc[attribute.slug] = value.name;
      return acc;
    },
    {}
  );


  const singularCategoryName =
    category && category.name && category.name.replace(/s$/gi, '');
  const articlesShouldBePurchasable =
    singularCategoryName === "Magazine" ? true : false;

  const childProducts = sections
    .reduce((acc, section) => acc.concat(section.childProducts), [])
    .sort(({ order: orderA }, { order: orderB }) => orderA - orderB);


  const [expanded, setExpanded] = useState(false);

  const [bottomBarVisible, setBottomBarVisible] = useState(false);

  const toggleBottomBar = () => {
    setBottomBarVisible(!bottomBarVisible);
  };

  const toggleExpansion = () => {

    setExpanded(!expanded);
  };

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const ref = useRef();
  const inViewport = useIntersection(ref, '0px'); // Trigger as soon as the element becomes visible
  // const inViewport = useIntersection(ref, '-200px'); // Trigger if 200px is visible from the element

  const anchorPos = window.location.href.indexOf("#");

  const productHeader = useRef(null); //to be able to access the current one

  useEffect(() => {
    if (anchorPos !== 0 && window.location.href.slice(anchorPos + 1) === 'magazine') {
      productHeader.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []) //same as ComponentDidMount


  return (
    <>
      <Wrapper>
        <Container>
          {

            <BottomBar name={name} handleDialogOpen={handleDialogOpen}
              {...product}
              orderEdges={orderEdges}
              subscriptions={subscriptions}
              currentUserSubscription={currentUserSubscription}
              currency={currency}
              isVideo={isVideo}
              saveVariant={saveVariant}
              categoryName={singularCategoryName}
              inViewport={inViewport} />
          }

          <Breadcrumbs routes={routes} />
          <div ref={ref}>
            <Row className="text-center" style={{ alignItems: 'center', justifyContent: 'center' }}>

              <h3 key="past-issues" className="heading-current text-center mt-3" ref={productHeader} >
                {isCurrentIssue ? "Current Issue" : ""}
              </h3>


            </Row>
            <div className="my-3 col-lg-12 d-flex product-intro" >
              <Col className="text-lg-center banner-image" lg="6">
                <img
                  className="img-fluid"
                  src={replaceStaticUrl(
                    images && images.length > 0 ? images[0].url : thumbnailUrl
                  )}
                />
              </Col>
              <Col className="details" lg="6">
                <h1 className="name">{name}</h1>
                {subTitle != undefined && subTitle != "" && <div className="subname">{subTitle}</div>}
                {getEditorName(editors) && (
                  <div className="editor-name">
                    {editorType == "EDITOR" ? "Edited by" : "By"}:&nbsp;{getEditorName(editors)}
                  </div>
                )}
                <div className="d-block ml-0 product-info">

                  <div className="date-issue">
                    {metaInfo && metaInfo.year && <div >{metaInfo.year}</div>}
                  </div>
                  <div className="product-type">{volumeInfo && <div>{volumeInfo}</div>}</div>
                </div>
                {
                  process.env.REACT_APP_SHOW_DESCRIPTION != undefined && process.env.REACT_APP_SHOW_DESCRIPTION == 'true' && description.replace(/(<([^>]+)>)/ig, '') != '' && <Description description={description} isMagazine={true} />
                }



                <MovieMetaDetails label="Directed by:" values={videoDirectors} />
                <MovieMetaDetails label="Produced by:" values={videoProducers} />
                <MovieMetaDetails label="Research & Writing:" values={videoWriters} />
                <div className="product-pricing-div">
                  <ProductPricing



                    {...product}
                    orderEdges={orderEdges}
                    subscriptions={subscriptions}
                    currentUserSubscription={currentUserSubscription}
                    currency={currency}
                    isVideo={isVideo}
                    saveVariant={saveVariant}
                    categoryName={singularCategoryName}
                    ref={ref}
                  />
                </div>
              </Col>
            </div>
            <Row>

              {(!category || category.name !== "Magazine") && (
                <Description description={description} />
              )}
              {(productReviews && productReviews.length > 0) && (
                <Col lg="5">
                  <h3 class="section-title">{category && category.name.replace(/s/gi, "")} review</h3>
                  <div class="book-review-box">
                    <div class="content">"{ReactHtmlParser(productReviews[0].content)}"</div>
                    <div class="reviewer-detials">{productReviews[0].reviewerName},&nbsp;{productReviews[0].reviewerWorkPlace}</div>
                  </div>
                </Col>
              )}
            </Row>
          </div>

          <InsideThisIssue id={productId}></InsideThisIssue>

          <div >
            <Row >
              <Col lg="12" className="px-0" >
                <div className="contents" id="article" name="article">
                  {childProducts && childProducts.length > 0 && (

                    <h3 key="heading" className="heading-past  mb-5 mt-4 text-center" >
                      Buy Individual Articles
                    </h3>


                  )}
                  {expanded ? childProducts.map(product => (
                    <Article

                      expanded={expanded}
                      showParentInfo={false}
                      saveVariant={saveVariant}
                      key={product.id}
                      purchasable={articlesShouldBePurchasable}
                      userSubscriptionId={currentUserSubscription ? currentUserSubscription.id : null}
                      {...product}
                    />
                  )) : childProducts.slice(0, 4).map(product => (
                    <Article
                      onClick={() => setExpanded(true)
                      }

                      expanded={expanded}
                      showParentInfo={false}
                      saveVariant={saveVariant}
                      key={product.id}
                      purchasable={articlesShouldBePurchasable}
                      userSubscriptionId={currentUserSubscription ? currentUserSubscription.id : null}
                      {...product}
                    />
                  ))}
                  {
                    childProducts.length > 4 &&
                    <p className={expanded ? ('read-less') : ('read-more')}>
                      <a onClick={toggleExpansion}>
                        {expanded ? ('VIEW LESS') : ('VIEW MORE')}
                      </a></p>
                  }

                </div>
              </Col>
            </Row>
            <Row className="text-center" style={{ alignItems: 'center', justifyContent: 'center' }}>
              <h3 key="past-issues" className="heading-past  my-5 text-center" style={{ fontWeight: '500' }}>
                Latest Issues
              </h3>
            </Row>

            <PreviousIssues />

          </div>

        </Container>


      </Wrapper>


    </>
  );
}

export default MagazineProduct;

