import React from "react";
import styled from "styled-components";
import FontAwesome from "react-fontawesome";

const Wrapper = styled.div`
  background-color: navajowhite !important;
  width: 100%;
  padding: 0.5em;
  display: flex;
  font-family: Cormorant Garamond, serif;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 1.5em;
    color: #000;
    cursor: pointer;
  }
`;

export const Banner = ({ banner, toggleBanner }) =>
  banner && banner.pageData && banner.pageData.header ? (
    <div className="container-xl p-0 offer__banner__width">
      <Wrapper>
        <div className="container">
          <h6 className="mb-0">{banner && banner.pageData.header}</h6>
        </div>
        {/* <FontAwesome
      id="close-icon"
      name="times"
      onClick={() => toggleBanner(false)}
    /> */}
      </Wrapper>
    </div>
  ) : (
    <div className="d-none"></div>
  );
