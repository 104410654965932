import { connect } from 'react-redux'
import { withApollo } from 'react-apollo';
import CheckoutAddress from './CheckoutAddress.jsx'
import actions from './../../../actions/';

const mapStateToProps = ({
  cart: {
    shippingAddress,
    shippingMethod,
    lines
  },
  auth: {
    authToken,
  }
}, ownProps) => ({
  shippingAddress,
  shippingMethod,
  lines,
  isLoggedIn: authToken? true: false,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  warningNotification: (message) => dispatch(actions.warningNotification(message)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(CheckoutAddress));