import { connect } from 'react-redux'
import {compose} from 'redux';
import actions from './../../actions'
import SignupForm from './SignupForm.jsx'
import {withRouter} from 'react-router-dom';


const mapStateToProps = ({ auth: { currency }}, ownProps) => ({ currency })

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    successNotification: (message) => dispatch(actions.successNotification(message)), 
    errorNotification: (message) => dispatch(actions.errorNotification(message)), 
  }
}

export default compose(withRouter, connect(
  mapStateToProps,
  mapDispatchToProps
))(SignupForm);