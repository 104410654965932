import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './BottomBar.css';
import logo from "./../../images/floating-button.png";
import { RaisedButton } from "../commons";
import FontAwesome from 'react-fontawesome';
import { ProductPricing } from './ProductPricing';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


function BottomBar({ subTitle, name, variants, isAvailable, currency, saveVariant, id, images, thumbnailUrl,
    isVideo, categoryName,
    orderEdges, subscriptions, currentUserSubscription, inViewport }) {

    const [expanded, setExpanded] = useState(false);

    function closeDialog() {
        setIsOpen(false);
    }

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>



            <Modal isOpen={isOpen} style={{ marginTop: 100 }}>
                <ModalHeader toggle={() => setIsOpen(!isOpen)}>
                    <h1 className="px-3">SELECT YOUR COPY </h1>
                </ModalHeader>
                <ModalBody className="px-lg-5 px-sm-3">

                    <ProductPricing
                        variants={variants}
                        isAvailable={isAvailable}
                        orderEdges={orderEdges}
                        name={name}
                        subscriptions={subscriptions}
                        currentUserSubscription={currentUserSubscription}
                        currency={currency}
                        isVideo={isVideo}
                        saveVariant={saveVariant}
                        categoryName={categoryName}
                        id={id}
                        images={images}
                        thumbnailUrl={thumbnailUrl}
                        hideSubscription={false}
                        closeDialog={closeDialog}
                    />
                </ModalBody>
            </Modal>

            <div className="bottom-bar">
                <div className="floating">
                    {
                        !expanded ? <img className="floating-button mr-4" src={logo} onClick={() => setExpanded(true)}></img>
                            : <div className="floating-info flex flex-column">
                                <div className="icon" style={{ cursor: 'pointer' }} onClick={() => setExpanded(false)}>X</div>
                                <div className="description"> Marg is a quarterly Indian art
                                    magazine and a publisher of
                                    books on the arts, based in
                                    Mumbai.
                                </div>
                                <Link to="/aboutus" className="learn-more">LEARN MORE</Link>

                            </div>

                    }
                </div>

                {
                    inViewport != true &&
                    <div className="background-bottom">

                        <div className=" d-flex flex-column">

                            <div className="bottom-title">
                                {name}
                            </div>
                            <div className="bottom-subtitle">
                                {subTitle}
                            </div>
                        </div>
                        <RaisedButton
                            onClick={() => setIsOpen(!isOpen)}
                            className="btn-hover bottom-button"
                        >
                            BUY NOW
                        </RaisedButton>
                    </div>
                }

            </div>

        </>
    );
}

export default BottomBar;

