import React,{ Component,Fragment } from 'react'
import FlatButton from "../../commons/FlatButton";
import TimelineNav from './timelineNav';
import TimelineSection from './timelineSection';

class HistoricalTimeline extends Component {

  constructor(props){
    super(props);
    this.state = {
      currentIndex : 0,
    }
    this.handleToggle = this.handleToggle.bind(this)
  }


   handleToggle = (i) =>{
    this.setState({
      currentIndex : i,
    })
  }

  render(){
    let { currentIndex } = this.state;
    const historyTimeline = this.props.historical.pageData;

    return(
        <Fragment>
          
          <TimelineSection handleToggle={this.handleToggle} historical={historyTimeline} currentIndex={currentIndex} />
        </Fragment>
    )
  }

}
export default HistoricalTimeline
