import React, { useEffect, useState } from 'react';
import './BottomBar.css';
import styled from "styled-components";
import { replaceStaticUrl } from '../../utils';


const CardContainerInsideIssue = styled.div`
  cursor: pointer;
  // & > div {
  //   @media (min-width: ${props => props.theme["mobileBreakpoint"]}) {
  //     padding-left: 0;
  //     padding-right: 0;
  //   }
  // }

  .first .title {
      position: absolute;
      top: 10px;
    color: white;
      padding:15px;
      width: 100%;
      font-weight:300;
      @media (max-width:  ${props => props.theme["mobileBreakpoint"]}) {
        font-size: 11px;
      }
  }

  .carousel-image-div{
    align-items: flex-start;
    margin-right: 10px;
    margin-left: 10px;
  }

  .firstslide {
      align-items: center;
      height: 100%;
  }


  .credits {
    position: absolute;
    font-weight: 300;
    bottom: 10px;
    color: white;
    padding-left: 10px;
    padding-right:10px;
    text-align: left;
    width: 100%;
    max-width: 200px;
    @media (max-width: 767px) {
      font-size: 8px;
      line-height: 9px;
    }
}

.first .credits {
    max-width:unset;
    padding-left: 15px;
    padding-right:15px;
}

.first .title {
    max-width:unset;
}

.leftslide .title{
    color: white;
    padding-bottom: 30px;
    font-weight: 600;
    @media (max-width:  ${props => props.theme["mobileBreakpoint"]}) {
        font-size: 13px;
      }
}

.leftslide .author-name{
    color: white;
    font-weight: 400;
    @media (max-width:  ${props => props.theme["mobileBreakpoint"]}) {
        font-size: 11px;
      }
}

.leftslide .author-profile{
    color: white;
    font-weight: 300;
    @media (max-width:  ${props => props.theme["mobileBreakpoint"]}) {
        font-size: 11px;
      }
}

.rightslide {
    padding: 10px 5px;
    // aspect-ratio: 200/250;
}

.leftslide {
    padding: 5px 10px 5px;
    // aspect-ratio: 200/250;
}



  .carousel-inside {
    @media (max-width: 767px) {
        max-width: 100%;
        width: 200px;
    }
  }

    .first-slide-image {
        width: 400px;
        max-width: 100%;
      }

  .inside-card {
    position: absolute;
    top: 50%;
    left: 50%;
    
    transform: translate(-50%, -50%);
  }

  p {
      font-weight: 500;
      line-height: 14px;
    @media (max-width:  ${props => props.theme["mobileBreakpoint"]}) {
        font-size: 11px;
      }  
  }



 
`;

function CarouselCardMobile({ slide }) {

    const {
        id,
        slideType,
        slideNumber,
        leftCardTitle,
        leftCardAuthorName,
        leftCardAuthorProfile,
        leftCardBackgroundColour,
        leftCardFontColour,
        centerCardTitle,
        centerCardCredits,
        centerCardFontColour,
        rightCardSynopsis,
        centerCardImage,
        rightCardBackgroundColour,
        rightCardFontColour,
    } = slide || {};

    const darkColours = ['#141D60', '#EB2027', '#F05C40'];
    const lightColours =  ['#FFFBE3', '#FAAD42', '#E0E5D9'];

    const leftCardFont = leftCardFontColour != undefined? leftCardFontColour.value: 'white';
    const rightCardFont =  rightCardFontColour != undefined? rightCardFontColour.value: 'black';
    const centerCardFont =  centerCardFontColour != undefined? centerCardFontColour.value: 'white';

    const leftCardBackground = leftCardBackgroundColour != undefined ? leftCardBackgroundColour.value : darkColours[Math.floor(Math.random() * darkColours.length)];
    const rightCardBackground = rightCardBackgroundColour != undefined ? rightCardBackgroundColour.value : lightColours[Math.floor(Math.random() * lightColours.length)];

    // const newCenterCardImage = 'http://ec2-15-207-223-84.ap-south-1.compute.amazonaws.com/'+centerCardImage;
    const newCenterCardImage = replaceStaticUrl(centerCardImage);
    return (
        <CardContainerInsideIssue
            className={'col-lg-12 inside-issue'}  style={{paddingLeft:'5px',paddingRight:'5px' }}
        >
            {
                slideType === 'A_1' &&

                <div className="col-lg-12 d-flex flex-column justify-content-between"  style={{paddingLeft:'0px',paddingRight:'0px' }}>
                    <div className="col-lg-12 " style={{marginBottom:'5px'}}>
                        <div className="d-flex flex-row justify-content-center carousel-image-div" style={{}}>
                            <img
                                alt=""
                                className='image carousel-inside '
                                src={newCenterCardImage}
                            />
                            <div className="credits" style={{ textAlign: 'left', color: centerCardFont }}> {centerCardCredits}</div>
                        </div>

                    </div>

                    <div className="col-lg-12 d-flex flex-row" style={{paddingLeft:'2px',paddingRight:'2px',height:'42%',maxHeight:'180px' }} >
                        <div className="leftslide col-lg-6 d-flex flex-row justify-content-center" style={{ backgroundColor: leftCardBackground}}>
                            <div className="img  text-center d-flex flex-column " style={{ alignContent: 'center', justifyContent: 'center', display: 'flex',padding:'5px 0px' }}>
                                <div className="title" style={{ color: leftCardFont }}>{leftCardTitle}</div>
                                <div className="author-name" style={{ color: leftCardFont }}>{leftCardAuthorName}</div>
                                <div className="author-profile" style={{ color: leftCardFont }}>{leftCardAuthorProfile}</div>
                            </div>
                        </div>
                        <div className="rightslide col-lg-6  d-flex flex-row justify-content-center" style={{ backgroundColor: rightCardBackground }}>
                            <div className="img  text-center d-flex flex-column " style={{ alignContent: 'center', justifyContent: 'center', display: 'flex' }}>
                                <p style={{ color: rightCardFont,margin:'unset' }}>{rightCardSynopsis}</p>
                            </div>
                        </div>

                    </div>
                </div>


            }

            { slideType === 'A_0' &&
                <div className="firstslide d-flex flex-row justify-content-center">
                    <div className="first d-flex flex-row justify-content-center carousel-image-div" style={{position:'relative'}}>
                        <img
                            alt=""
                            className='first-slide-image'
                            src={newCenterCardImage}
                        />
                        <div className="title" style={{ textAlign: 'left', color: centerCardFont }}>{centerCardTitle} </div>
                        <div className="credits" style={{ textAlign: 'left', color: centerCardFont }}> {centerCardCredits}</div>
                    </div>

                </div>
            }

            { slideType === 'A_2' &&
                <div className="firstslide d-flex flex-row justify-content-center carousel-image-div">
                    <img
                        alt=""
                        className='image carousel-inside '
                        src={newCenterCardImage}
                    />
                </div>
            }



        </CardContainerInsideIssue>
    );
}

export default CarouselCardMobile;

