import React, { useReducer , useEffect } from "react";
import { Row,Col } from 'reactstrap';
import styled from 'styled-components'
import FlatButton from "../commons/FlatButton";
import RaisedButton from "../commons/RaisedButton";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {  replaceStaticUrl } from './../../utils';

const SingleEvent = styled.div`
  padding : 0rem 6rem 4em;
  .item {
    padding-bottom: 3em;
    cursor: pointer;
  }
  .EventTitle{
    color: #000000;
    font-family: ${props => props.theme["$font-secondary-medium"]};
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.66px;
    line-height: 23px;
  }
  .EventInfo{
    color: #3a3a3a;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.59px;
    line-height: 23px;
  }
  .EventDesc{
    color: #000000;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.59px;
    line-height: 23px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 70px;
  }
  .EventType{
    color: #37312f;
    font-family: Lato;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    padding : 0rem 2rem;
  }
  img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: top;
  }
`;

const INITIAL_STATE = {
  show_counter: 4,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case 'HANDLE_VIEW_MORE':
      return {
        ...state,
        show_counter : state.show_counter + 4
      }

    default:
      return state
  }
};

const EventList = ({ events , event_type, noEvents, history: { push } }) =>{
  const [ eventState , dispatch ] = useReducer(reducer, INITIAL_STATE);

  return(
    <>
      <SingleEvent>
        {
          !events || events.length === 0 && <p>{noEvents}</p>
        }
        {
          events && events.length > 0 && <p className = "EventType">{event_type}</p>
        }
        <Row>
        {
          events && events.reduce((acc, event, index) => {
            if(index > eventState.show_counter) return acc;
            return acc.concat(
              <Col md="6" className="item">
                <Link to={`/event/${event.slug}`}>
                  <img style= {{ objectFit : "cover" }} src={replaceStaticUrl(event.image)} className="thumbnail"/>
                </Link>
                <div className="my-4" onClick={() => push(`/event/${event.slug}`)}>
                  <p className="EventTitle">{event.eventType} | {event.title}</p>
                  <span className="EventInfo">{event.city}</span><br/>
                  <div className="EventInfo">{event.commenceDate}</div>
                  {
                    event.canRegister && <Link to={`/event/${event.slug}`}><FlatButton>VIEW DETAILS & REGISTER</FlatButton></Link>
                  }
                </div>
                <br/>
              </Col>
            )
          }, [])
        }
        </Row>
      </SingleEvent>
      {
        eventState.show_counter < events.length  ?
        <center><RaisedButton className="btn-hover" onClick={()=>{ dispatch({ type : "HANDLE_VIEW_MORE" })}}>VIEW MORE</RaisedButton></center>
        :
        null
      }
      <br/>
    </>
  );
}

export default withRouter(EventList);
