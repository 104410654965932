import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { FlatButton } from '../commons/';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  animation: collaborateFadeIn ease 1s;
  @keyframes collaborateFadeIn { 
  0% { 
  opacity: 0;
  transform: translate3d(0, -2%, 0);
  } 
  100% { 
  opacity: 1;
  transform: none;
  } 
  }

  display: flex;
  flex-direction: column;

  .rich-text-content {
    img {
      max-width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  a {
    color: inherit;
  }

  & > div.header {
    padding: 50px 100px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div.name {
      font-family: ${props => props.theme['$font-secondary-medium']};
      font-size: ${props => props.theme['$font-size-lg']};
      font-weight: ${props => props.theme['$weight-regular']};
      letter-spacing: 1px;
      line-height: 57px;
    }
  }

  & > div.body {
    display: flex;
    flex-direction: row;
    padding: 0px 150px 150px;

    & > div.text {
      display: flex;
      flex-direction: column;
      width: 50%;

      & > div {
        margin-bottom: 15px;
      }
    }

    & > div.imgs {
      display: flex;
      flex-direction: column;
      padding-left: 30px;

      & > img {
        margin-bottom: 10px;
      }
    }
  }
`;

export const Collaborate = props => (
  <Wrapper>
    <div className="header">
      <div className="name">{props.collaborate.pageData.title}</div>
      <FlatButton>
        <Link to={{
          pathname: '/contactus',
          state: {
            messageType: 'COLLABORATION'
          }
        }}>
          CONTACT US FOR COLLABORATIONS
        </Link>
      </FlatButton>

    </div>
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-8 mx-auto rich-text-content">
          {ReactHtmlParser(props.collaborate.pageData.content)}
        </div>
      </div>
    </div>
  </Wrapper>
)