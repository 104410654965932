import { call, put, select, fork, take, cancel, all } from "redux-saga/effects";
import gql from 'graphql-tag';
import client from './../apollo/';
import actions from './../actions';

const INVALIDATE_AUTH_TOKEN = gql(`
  query InvalidateToken($email: String, $jwtToken: String) {
    validateJwtToken(email: $email, jwtToken: $jwtToken)
  }
`);

const delay = (ms) => new Promise(res => setTimeout(res, ms))
export const getUserFromState = (state) => state.auth;

export function* invalidateAuthToken() {
  while (true) {
    const { email, authToken } = yield select(getUserFromState);
    const {data: { validateJwtToken }} = yield call(
      () => (
        client.query({
          query: INVALIDATE_AUTH_TOKEN,
          variables: {
            email: email,
            jwtToken: authToken,
          }
        })
      )
    );
    try {
      if(!validateJwtToken) {

        yield all([
            put(actions.logout()),
            put(actions.errorNotification("You have logged in through another device or browser and will be logged out of this session")),
        ]);
      }
    } catch (e) {
      console.log("Err: ", e);
    }
    yield delay(10000)
    // yield put(actions.fetch(result.data.zones))
  }
}

export function* invalidateWhenLoggedIn() {
  while ( yield take("SET_USER_DETAILS") ) {
    // starts the task in the background
    const invalidateTask = yield fork(invalidateAuthToken)

    // wait for the user stop action
    yield take("LOGOUT")
    // user clicked stop. cancel the background task
    // this will cause the forked bgSync task to jump into its finally block
    yield cancel(invalidateTask)
  }
}
