import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import { Redirect } from "react-router";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import ReactHtmlParser from "react-html-parser";
import {
  replaceStaticUrl,
  getEditorName,
} from "../../utils";
import { Container, Row, Col } from "reactstrap";
import Article from "../Article";
import { ProductPricing } from "./ProductPricing.jsx";
import Description from "./Description";
import Breadcrumbs from "../Breadcrumbs";
import FbPixel from "../../utils/fbPixel";
import BottomBar from './BottomBar';
import Carousel from "react-multi-carousel";
import ProductCard from './ProductCard'
import { PreviousIssues } from "./LatestIssues";
import { ProductsSection } from "../Home/ProductsSection";

const Wrapper = styled.div`
  animation: aboutFadeIn ease 2s;
  animation-delay: 0.5s;
  @keyframes aboutFadeIn { 
  0% { 
  opacity: 0;
  transform: translate3d(0, -5%, 0);
  } 
  100% { 
  opacity: 1;
  transform: none;
  } 
  }

  @media (min-width: ${props => props.theme["mobileBreakpoint"]}) {
    padding: 50px 100px 100px;
  }

  .img-fluid {
    width: 100%;
  }

  .name {
    font-family: ${props => props.theme["$font-secondary-medium"]};
    font-size: ${props => props.theme["$font-size-sm"]};
    line-height: ${props => props.theme["$font-size-sm"]};
    @media (min-width: ${props => props.theme["mobileBreakpoint"]}) {
      font-size: ${props => props.theme["$font-size-lg"]};
      line-height: ${props => props.theme["$font-size-lg"]};
    }
    font-weight: ${props => props.theme["$weight-regular"]};
    font-weight: 500;
    letter-spacing: 1px;
  }

  .product-type {
    color: #37312f;
    font-family: Lato;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .current-issue {
    padding-left: 20px;
    color: ${props => props.theme.primaryColor};
    font-family: Lato;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 2.5px;
    text-transform: uppercase;
  }

  .editor-name {
    font-size: ${props => props.theme["$font-size-xxs"]};
    font-weight: ${props => props.theme["$weight-regular"]};
    letter-spacing: 1px;
    line-height: 28px;
    margin-bottom: 1.5rem;
  }

  .medium-name {
    color: #37312f;
    font-size: 20px;
    @media (max-width: ${props => props.theme["mobileBreakpoint"]}) {
      font-size: 16px;
    }
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 20px;
    margin-right: 3rem;
  }

  .pricing {
    color: #37312f;
    font-family: ${props => props.theme["$font-primary-medium"]};
    font-size: ${props => props.theme["$font-size-xxs"]};
    font-weight: ${props => props.theme["$weight-regular"]};
    line-height: 20px;
  }

  .add-to-bag {
    margin: 1.5rem 0;
    align-self: flex-start;
  }

  .availability {
    font-size: ${props => props.theme["$font-size-xxs"]};
    font-weight: ${props => props.theme["$weight-regular"]};
    letter-spacing: 0.59px;
    line-height: 23px;
  }

  h3.section-title {
    color: #37312f;
    font-family: Lato;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 60px;
  }

  .book-review-box {
    padding: 10px 20px 20px;
    border-top: 1px solid #9d9d9d;
    border-bottom: 1px solid #9d9d9d;

    & > .reviewer-detials {
      margin-top: 20px;
      font-size: ${props => props.theme["$font-size-xxs"]};
      font-weight: ${props => props.theme["$weight-regular"]};
      letter-spacing: 0.59px;
      line-height: 23px;
    }

    & > .content {
      font-size: ${props => props.theme["$font-size-xxxs"]};
      font-weight: ${props => props.theme["$weight-regular"]};
      letter-spacing: 0.52px;
      line-height: 21px;
    }
  }

  .contents {
    & > .heading {
      font-family: ${props => props.theme["$font-secondary-medium"]};
      font-size: ${props => props.theme["$font-size-sm"]};
      @media (min-width: ${props => props.theme["mobileBreakpoint"]}) {
        font-size: ${props => props.theme["$font-size-lg"]};
      }
      font-weight: ${props => props.theme["$weight-regular"]};
      color: ${props => props.theme.mainTextColor};
    }

    & > div:not(.heading) {
      border-top: 1px solid #9d9d9d;
    }

    & > div:last-child {
      border-bottom: 1px solid #9d9d9d;
    }
  }

  .breadcrumb-align {
      margin-left: -110px;
  }
`;



function OtherProducts(
  { product, saveVariant, MovieMetaDetails, routes, subscriptions, currency, me }) {

  const {
    id: productId,
    name,
    volumeInfo,
    description,
    isCurrenctIssue: isCurrentIssue,
    images,
    isAvailable,
    editors,
    sections,
    variants = [],
    attributes,
    category,
    thumbnail: { url: thumbnailUrl } = {},
    editorType,
    productReviews,
    videoDirectors,
    videoProducers,
    videoWriters,
  } = product || {};

  const { orders: { edges: orderEdges = [] } = {} } = me || {};

  let currentUserSubscription;
  const isVideo = category && category.name === 'Videos';

  const metaInfo = attributes.reduce(
    (acc, { value, attribute } = {}) => {
      acc[attribute.slug] = value.name;
      return acc;
    },
    {}
  );

  const singularCategoryName =
    category && category.name && category.name.replace(/s$/gi, '');
  const articlesShouldBePurchasable =
    singularCategoryName === "Magazine" ? true : false;

  const childProducts = sections
    .reduce((acc, section) => acc.concat(section.childProducts), [])
    .sort(({ order: orderA }, { order: orderB }) => orderA - orderB);

  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Wrapper>
      <Container>
            <Breadcrumbs routes={routes}/>
              <Row className="my-5">
                <Col className="text-lg-center" lg="6">
                  <img
                    className="img-fluid"
                    src={replaceStaticUrl(
                      images && images.length > 0 ? images[0].url : thumbnailUrl
                    )}
                  />
                </Col>
                <Col className="details" lg="6">
                  <div className="d-flex mt-3 mt-lg-0 ml-0">
                    <div className="product-type ">{singularCategoryName}</div>
                    <div className="current-issue">
                      {isCurrentIssue ? "CURRENT ISSUE" : ""}
                    </div>
                  </div>
                  <div className="d-flex mt-1">
                    {volumeInfo && <div>{volumeInfo}</div>}
                    {volumeInfo && metaInfo && metaInfo.year && (
                      <div>,&nbsp;</div>
                    )}
                    {metaInfo && metaInfo.year && <div style={{textTransform: "capitalize"}}>{metaInfo.year}</div>}
                  </div>
                  <h1 className="name my-3">{name}</h1>
                  {getEditorName(editors) && (
                    <div className="editor-name">
                      {editorType == "EDITOR"? "Edited by": "By"}:&nbsp;{getEditorName(editors)}
                    </div>
                  )}
                  <MovieMetaDetails label="Directed by:" values={videoDirectors} />
                  <MovieMetaDetails label="Produced by:" values={videoProducers} />
                  <MovieMetaDetails label="Research & Writing:" values={videoWriters} />
                  <ProductPricing
                    {...product}
                    orderEdges={orderEdges}
                    subscriptions={subscriptions}
                    currentUserSubscription={currentUserSubscription}
                    currency={currency}
                    isVideo={isVideo}
                    saveVariant={saveVariant}
                    categoryName={singularCategoryName}
                  />
                </Col>
              </Row>
              <Row>
                {(!category || category.name !== "Magazine") && (
                  <Description description={ description } />
                )}
                {(productReviews && productReviews.length > 0) && (
                  <Col lg="5">
                    <h3 class="section-title">{category && category.name.replace(/s/gi, "")} review</h3>
                    <div class="book-review-box">
                      <div class="content">"{ReactHtmlParser(productReviews[0].content)}"</div>
                      <div class="reviewer-detials">{productReviews[0].reviewerName},&nbsp;{productReviews[0].reviewerWorkPlace}</div>
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                <Col lg="9" className="px-0">
                  <div className="contents">
                    {childProducts && childProducts.length > 0 && (
                      <h3 key="heading" className="heading my-3 my-5 text-left">
                        Contents
                      </h3>
                    )}
                    {childProducts.map(product => (
                      <Article
                        showParentInfo={false}
                        saveVariant={saveVariant}
                        key={product.id}
                        purchasable={articlesShouldBePurchasable}
                        userSubscriptionId={currentUserSubscription? currentUserSubscription.id: null}
                        {...product}
                      />
                    ))}
                  </div>
                </Col>
              </Row>
            </Container>



      </Wrapper>


    </>
  );
}

export default OtherProducts;

