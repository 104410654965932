import React,{ useReducer , useEffect } from "react";
import { Row,Col } from 'reactstrap';
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import {
  useParams
} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import styled from 'styled-components'
import {  replaceStaticUrl } from './../../../utils';
import FlatButton from "../../commons/FlatButton";

const Event = styled.div`
  padding : 0rem 6rem;
  .EventTitle{
    color: #000000;
    font-family: ${props => props.theme["$font-secondary-medium"]};
    font-size: ${props => props.theme["$font-size-xs"]}
    font-weight: 700;
    letter-spacing: 0.66px;
    line-height: 23px;
  }
  .EventInfo{
    color: #3a3a3a;
    font-family: ${props => props.theme["$font-primary-medium"]};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.59px;
    line-height: 23px;
  }
  .EventDesc{
    color: #000000;
    font-family: ${props => props.theme["$font-primary-medium"]};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.59px;
    line-height: 23px;
  }
  @media (max-width: 768px) {
    padding : 0rem 2rem;
  }
`;


const INITIAL_STATE = {
  event : {}
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case 'HANDLE_EVENT_DATA':
    return {
      ...state,
      event : action.data
    }

    default:
      return state
  }
};

const LOAD_EVENT_DETAILS = gql`
  query event($slug: String!) {
    event(slug: $slug){
      id
      slug
      title
      description
      image
      commenceDate
      eventType
      city
      registerLink
      canRegister
    }
  }
`;


const SingleEvent = ({client, props}) =>{

  const [ singleEvent , dispatch ] = useReducer(reducer, INITIAL_STATE);

  const { slug: eventslug } = useParams();
  useEffect(() => {

    client.query({
      query: LOAD_EVENT_DETAILS,
      variables: {
        slug: eventslug,
      }
    }).then(({ data, loading }) => {
      if(!loading && data) {
        dispatch({ type : "HANDLE_EVENT_DATA", data: data.event });
      }
    });

  },[])

  return(
    <Event>
      <Row>
        <Col md="12" className="mt-5">
          <div>
          <img style= {{ objectFit : "cover", maxHeight: "616px" }} src={replaceStaticUrl(singleEvent.event.image)} width="100%" height="100%"  className="img-fluid"/>
          <div className="my-4">
            <p className="EventTitle">{singleEvent.event.eventType} | {singleEvent.event.title}</p>
            <span className="EventInfo">{singleEvent.event.city}</span><br/>
            <span className="EventInfo">{singleEvent.event.commenceDate}</span>
            {
              singleEvent.event.canRegister && 
                <a href={`${singleEvent.event.registerLink}?entry_1=${singleEvent.event.title}`} target="_blank">
                  <FlatButton>REGISTER</FlatButton>
                </a>
            }
            <div className="row">
              <p className="EventDesc pt-4 col-md-9 col-12">{ReactHtmlParser(singleEvent.event.description)}</p>
            </div>
          </div>
          <br/>
          </div>
       </Col>
      </Row>
    </Event>
  );
}

export default withApollo(SingleEvent);
