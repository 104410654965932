import React from 'react';
import styled from 'styled-components';

import { RaisedButton, FlatButton } from '../commons';
import { Link } from 'react-router-dom';
import advertise1 from '../../images/advertise1.png';
import advertise2 from '../../images/advertise2.png';
import brochure from '../../images/marg-brochure.pdf';
import ReactHtmlParser from 'react-html-parser';

const Wrapper = styled.div`

  animation: advertiseFadeIn ease 1s;

  @keyframes advertiseFadeIn { 
  0% { 
  opacity: 0;
  transform: translate3d(0, -2%, 0);
  } 
  100% { 
  opacity: 1;
  transform: none;
  } 
  }

  display: flex;
  flex-direction: column;

  & > div.header {
    padding: 50px 100px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div.name {
      font-family: ${props => props.theme['$font-secondary-medium']};
      font-size: ${props => props.theme['$font-size-lg']};
      font-weight: ${props => props.theme['$weight-regular']};
      letter-spacing: 1px;
      line-height: 57px;
    }
  }
  
  .contact-ads {
    color: ${props => props.theme[props.colorType ? `${props.colorType}Color` : "primaryColor"]};
    font-size: ${props => props.theme['$font-size-xxs']};
    font-weight: ${props => props.theme['$weight-bold']};
    letter-spacing: 3px;
    text-transform: uppercase;
    cursor: pointer;
    border-bottom: 1px solid transparent;
  }

  a {
    color: inherit;
  }

`;

const AdvertiseBlock = styled.div`

  div.description {

    .name {
      font-family: ${props => props.theme['$font-secondary-medium']};
      font-size: ${props => props.theme['$font-size-lg']};
      font-weight: ${props => props.theme['$weight-regular']};
      letter-spacing: 1px;
      line-height: 57px;
    }

    .meta {
      font-size: ${props => props.theme['$font-size-xxs']};
      font-weight: ${props => props.theme['$weight-regular']};
      letter-spacing: 0.59px;
      line-height: 23px;

      .rates {
        margin-top: 50px;
        .header {
          font-size: ${props => props.theme['$font-size-xxs']};
          font-weight: ${props => props.theme['$weight-bold']};
          letter-spacing: 1px;
          text-transform: uppercase;
        }
        .body {
          ul {
            list-style: none;
            padding-left: 0;
            li {
            margin-bottom: 5px;
            }
          }
        }
        .footer {
          padding-top: 20px;
        }
      }
    }
  }

  div.img-container {
    img {
      width: 100%;
    }
  }
`

export const Advertise = props => (
  <Wrapper>
    <div className="header">
      <h1 className="heading pb-2">Advertise in Marg</h1>
      <FlatButton>
        <Link to={{
          pathname: '/contactus',
          state: {
            messageType: 'ADVERTISING'
          }
        }}>
          CONTACT US FOR ADS
        </Link>
      </FlatButton>

    </div>
    <AdvertiseBlock>
      <div className="container">
        <div className="row">
          <div className="description col-12 offset-lg-1 col-lg-5 my-3">
            {ReactHtmlParser(props.pageData.section1Content)}
          </div>
          <div className="img-container col-12 col-md-6 col-lg-3 my-3">
            <img src={props.pageData.section1Image} alt="advertise in marg" />
          </div>
        </div>
      </div>
    </AdvertiseBlock>
    <AdvertiseBlock>
      <div className="container my-5">
        <div className="row">
          <div className="description col-12 offset-lg-1 col-lg-5 my-3">
            <div className="meta">
              {ReactHtmlParser(props.pageData.section2Content)}
              <div className="footer">
                <a href={props.pageData.file} target="_blank"><FlatButton>DOWNLOAD DETAILS (PDF)</FlatButton></a>
              </div>
            </div>
          </div>
          <div className="img-container col-12 col-md-6 col-lg-3 my-3">
            <img src={props.pageData.section2Image} alt="advertise in marg" />
          </div>
        </div>
      </div>
    </AdvertiseBlock>
  </Wrapper>
);