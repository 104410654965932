import React from "react";

const TimelineNav = ({ handleToggle, historical, index, currentIndex }) => {

  const historyTimeline = historical;

  return (
    <>
      <hr className="flex-grow-1 hr-line" />
      <span onClick={() => handleToggle(index)} className={ currentIndex === index ? "align-self-center bullets colored-bullets" : "align-self-center bullets"}></span>
      <hr className="flex-grow-1 hr-line" />
    </>
  );
};

export default TimelineNav;
