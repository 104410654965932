import React, { Component } from "react";
import { FlatButton } from "../commons";
import ReactHtmlParser from "react-html-parser";
import { Col } from "reactstrap";
import styled from "styled-components";

const Wrapper = styled.div`
.text-limit {
    overflow: hidden;
    max-height: 4.6em;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
  }

  p:not(.text-limit){
    display: table-header-group;
  }

  .text-all {
    overflow: visible;
    max-height: 100%;
  }

  .text-all p {
    display: block;
    margin-bottom: 5px;
   
  }

  .description-font {
    font-size: 18px;
    margin-bottom: 5px;
    @media (max-width: 1139px) {
      font-size: 16px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      margin-bottom: 5px;
    }
    
  }

  .read-btn p {
    font-weight: 400;
  }
`;
class Description extends Component {
  state = {
    maxHeight: true,
  };
  showToggle = () => {
    this.setState({
      maxHeight: !this.state.maxHeight,
    });
  };

  

  render() {
    const desc = this.props.description.replace( /(<([^>]+)>)/ig, '');
    const length = desc.length;
    return (
      <Wrapper>
        
      <Col lg={this.props.isMagazine === undefined? '7' : '12'} className="mb-3 mb-lg-0 pl-0">
        {
          (typeof this.props.isMagazine === 'undefined') &&  <h3 class="section-title">Description</h3>
        }
       
        <p className={` ${length<=170? 'text-all' : this.state.maxHeight  ? "text-limit" : "text-all"} ${this.props.isMagazine === undefined ? "" : "description-font"}`}>
          {ReactHtmlParser(this.props.description)} 
        </p>
        {
          length>170 && <FlatButton
          onClick={this.showToggle}
          className="read-btn"
          colorType="primary"
        >
          {this.state.maxHeight ? <p>Read More</p> : <p>Read Less</p>}
        </FlatButton>
        }
        
      </Col>
      </Wrapper>
    );
  }
}

export default Description;