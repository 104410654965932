import React from 'react';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import styled from "styled-components";
import logo from "./../../images/logo.png";
import SubscriptionItem from "./SubscriptionItem"
import DigitalTerms from '../Checkout/CheckoutBag/DigitalTerms';
import ReactHtmlParser from 'react-html-parser';

const LIST_SUBSCRIPTIONS = gql`
  query ListSubscriptions {
    subscriptions{
      id
      name
      subHeading
      description
      issueType
      isFeatured
      notesInr
      notesUsd
      pricings{
        id
        duration
        accessToIssues
        term
        discountText
        originalInrPrice{
          amount
          currency
          localized
        }
        inrPrice{
          amount
          currency
          localized
        }
        originalUsdPrice{
          amount
          currency
          localized
        }
        usdPrice{
          amount
          currency
          localized
        }
      }
    }
  }
`;


const Wrapper = styled.div`
  animation: subscriptionInAnimation ease 1s;
  @keyframes subscriptionInAnimation { 
  0% { 
      opacity: 0;
      transform: translate3d(0, -5%, 0);
  } 
  100% { 
      opacity: 1;
      transform: none;
   } 
  }
  @media (min-width: ${props => props.theme["mobileBreakpoint"]}) {
    padding: 50px 100px 100px;
  }

  & > .heading {
    font-family: ${props => props.theme['$font-secondary-medium']};
    font-size: ${props => props.theme['$font-size-sm']};
    @media (min-width: ${props => props.theme['mobileBreakpoint']}) {
      font-size: ${props => props.theme['$font-size-lg']};
    }
    font-weight: ${props => props.theme['$weight-regular']};
    letter-spacing: 1px;
    line-height: 42px;
    text-align: center;
    margin-bottom: 30px !important;
  }

  & .section-heading {
    font-family: ${props => props.theme['$font-secondary-medium']};
    font-size: ${props => props.theme['$font-size-sm']};
    @media (min-width: ${props => props.theme['mobileBreakpoint']}) {
      font-size: ${props => props.theme['$font-size-lg']};
    }
    font-weight: ${props => props.theme['$weight-regular']};
    letter-spacing: 1px;
    line-height: 42px;
    text-align: center;
  }
`;


export const Subscriptions = ({
  subscriptions: boughtSubscriptions,
  subscription
}) => {
  console.log("propssss",subscription.pageData.title)
  return (
    <Wrapper className="container">
      <div className="heading">{subscription.pageData.title}</div>
      <p className="mx-auto my-3 col-12 col-md-6 text-center">
        {ReactHtmlParser(subscription.pageData.text)}
      </p>
      <div className="row justify-content-center mt-5">
        <Query
          query={LIST_SUBSCRIPTIONS}
          >
            {
              ({ loading, error, data: { subscriptions = {} } = {} }) => {
                if(loading) {
                  return <div>Loading, please wait.</div>
                }
                console.log("Subs: ", subscriptions);
                const {
                  featuredSubscriptions,
                  regularSubscriptions,
                } = subscriptions.reduce((acc, subscription) => {
                  if(subscription.isFeatured) {
                    acc.featuredSubscriptions = acc.featuredSubscriptions.concat(subscription);
                  } else {
                    acc.regularSubscriptions = acc.regularSubscriptions.concat(subscription);
                  }
                  return acc;
                }, {
                  featuredSubscriptions: [],
                  regularSubscriptions: [],
                })

                const validSubscriptionSection = (title, subscriptions) => ([
                  subscriptions && subscriptions.length > 0 && title && (
                    <div className="col-12 text-center section-heading">{title}</div>
                  ),
                  subscriptions.map((subscription) => (
                    <SubscriptionItem
                      {...subscription}
                      key={subscription.id}
                      className="col-12 col-sm-12 col-md-6 col-lg-4 subscription"
                      pricings={subscription.pricings.map((it, id) => ({ ...it, val: id, name: `${it.duration} ${it.duration > 1? 'years': 'year' }`}) )}
                    />
                  ))
                ]);
                return [
                  validSubscriptionSection(subscription.pageData && subscription.pageData.featuredSubscriptionTitle, featuredSubscriptions),
                  validSubscriptionSection(subscription.pageData && subscription.pageData.regularSubscriptionTitle, regularSubscriptions),
                ]
              }
            }
        </Query>
      </div>
      <div className="row">
        <DigitalTerms/>
      </div>
    </Wrapper>
  )
} 