import React, { useEffect, useState } from "react";
import { withApollo } from 'react-apollo';
import styled from "styled-components";
import gql from "graphql-tag";
import ReactHtmlParser from "react-html-parser";
import axois from 'axios';
import VideoPlayer from "./VideoPlayer.jsx";
import {
  replaceStaticUrl,
} from "./../../utils/";

const Wrapper = styled.div`
  & .header {
    font-family: ${props => props.theme['$font-secondary-medium']};
    font-size: ${props => props.theme['$font-size-lg']};
    font-weight: ${props => props.theme['$weight-regular']};
    letter-spacing: 1px;
    line-height: 57px;
    text-align: center;
    height: ${props => props.theme['$font-size-lg']};
  }
  .description {
    margin-bottom: 100px;  
  }

  .video {
    height: 70vh;
  }
`;

const GET_VIDEO_DETAILS = gql`
  query FetchVideDetails($orderId: ID!, $lineId: ID!) {
    me{
      orders(first:1, orderId: $orderId, lineId: $lineId){
        edges{
          node{
            id
            lines{
              id
              variant{
                id
                product {
                  id
                  name
                  category{
                    name
                  }
                  images{
                    url
                  }
                  description
                  videoWriters {
                    name
                  }
                  videoProducers {
                    name
                  }
                  videoDirectors{
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const MovieDetailsWrapper = styled.div`
  & .value {
    color: ${props => props.theme.mainTextColor};
    font-weight: ${props => props.theme["$weight-regular"]};
  }

  & .key {
    color: ${props => props.theme.secondaryColor};
    font-weight: ${props => props.theme["$weight-bold"]};
  }
`;

const MovieMetaDetails = ({className, label, values}) => {
  if(!values || values.length === 0) return<div></div>;
  return <MovieDetailsWrapper className={className}>
    {values && values.length > 0 && <span className="key">{label}</span>}
    <span className="value">
      &nbsp;
      {
        values && values.map(({name}) => name).join(', ')
      }
    </span>
  </MovieDetailsWrapper>
}

const Player = ({
  client,
  history: {
    push,
  },
}) => {

  const [options, setOptions] = useState();
  const [prod, setProd] = useState();

  const params = (new URL(window.location)).searchParams;
  const orderId = params.get("order-id");
  const lineId = params.get("line-id");
  const token = localStorage.getItem('authToken');


  useEffect(() => {
    if(!prod) return;

    setOptions({
      poster: replaceStaticUrl(
        prod && prod.images && prod.images.length > 0 ? prod.images[0].url : ""
      ),
      controls: true,
      sources: [{
        src: `/movie-player/?order-id=${orderId}&line-id=${lineId}`,
        type: 'video/mp4',
      }]
    });

  }, prod);

  useEffect(() => {
  
    if(!token) {
      return push("/");
    }

    client.query({
      query: GET_VIDEO_DETAILS,
      variables: {
        orderId, lineId
      }
    }).then(({ data }) => {
      if(!data.me) {
        return push("/");
      }
      const {
        orders: {
          edges,
        } = {}
      } = data.me || {};
      if(edges && edges.length === 0) return;
      const order = edges[0];

      order.node.lines.forEach(({id: currLineId, variant: { product } }) => {
        if(lineId === currLineId) {
          setProd(product);
        }
      });
    })
  }, []);

  return (
    <Wrapper>
      <div className="mt-5 container">
        <h1 className="header mb-5">{prod && prod.name}</h1>
        <div className="row">
          {
            options && 
              <VideoPlayer {...options} className="col-12 video" />
          }
        </div>
      </div>
      <MovieMetaDetails className="mt-3" label="Directed by:" values={prod && prod.videoDirectors} />
      <MovieMetaDetails label="Produced by:" values={prod && prod.videoProducers} />
      <MovieMetaDetails label="Research & Writing:" values={prod && prod.videoWriters} />
      <div className="mt-3 description">{ReactHtmlParser(prod && prod.description)}</div>
      
    </Wrapper>
  )
}

export default withApollo(Player);