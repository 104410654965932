import React, { Component } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import ReactHtmlParser from 'react-html-parser';
import gql from 'graphql-tag';
import { getEditorName, getLocalizedAmount, replaceStaticUrl } from './../../../utils/';
import { RaisedButton } from './../../commons/';
import FbPixel from "../../../utils/fbPixel";


const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  span.fa {
    color: ${props => props.theme['primaryColor']};
    display: block;
  }

  


  .body {
    height: 100%;
    max-height: 0;
    overflow: hidden;
    padding: 0px 0px 0px 0px;
    transition: max-height 400ms ease-in-out;

    & > .cart-info > .out-of-stock {
      text-align: right;
      @media (max-width: ${props => props.theme['mobileBreakpoint']}) {
        text-align: left;
      }

    }

    & > .description {

      & .product-heading {
        color: #37312f;
        font-family: ${props => props.theme['$font-primary-medium']};
        font-size: ${props => props.theme['$font-size-xxs']};
        font-weight: ${props => props.theme['$weight-bold']};
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      & .product-type {
        color: ${props => props.theme['mainTextColor']};
        font-family: ${props => props.theme['$font-primary-medium']};
        font-size: ${props => props.theme['$font-size-xxxs']};
        font-weight: ${props => props.theme['$weight-regular']};
        letter-spacing: 0.52px;
        line-height: 21px;
      }
    }

    & .editor-name {
      font-size: ${props => props.theme['$font-size-xxs']};
      font-weight: ${props => props.theme['$weight-regular']};
      color: ${props => props.theme['mainTextColor']};
      letter-spacing: 1px;
      line-height: 28px;
    }
  }

  & > .description p {
    font-size: 18px;
      @media (max-width: 1139px) {
        font-size: 16px;
      }
  }

  .body.isOpen{
    height: 100%;
    max-height: 1000px;
  }

  .hint {
    font-size: 18px;
    @media (max-width: 1139px) {
      font-size: 16px;
    }
    font-weight: ${props => props.theme['$weight-regular']};
  }

  .title {
    color: #1C1C1C
    font-size: 16px;
    @media (min-width: ${props => props.theme['mobileBreakpoint']}) {
      font-size: 18px;
    }
    font-weight: ${props => props.theme['$weight-semibold']};
    line-height: 23px;
    margin: unset;
  }

  .article-price {
    justify-content: space-between;
    @media (min-width: ${props => props.theme['mobileBreakpoint']}) {
      justify-content: flex-end;
    }
  }

  div.header {
    display: flex;
    flex-direction: row;
    align-items: center;

    div.product-editor {
      font-size: ${props => props.theme['$font-size-xxxs']};
      font-weight: ${props => props.theme['$weight-bold']};
      color: ${props => props.theme.underlineColor};
      letter-spacing: 1px;
    }

    & > div.price {
      margin-left: 10%;
      width: 10%;
      max-width: 10%;
      text-align: center;
      font-family: ${props => props.theme['$font-primary-medium']};
      color: ${props => props.theme.underlineColor};
      font-size: ${props => props.theme['$font-size-xxs']};
      font-weight: ${props => props.theme['$weight-regular']};
    }

    & > div.icon {
      color: ${props => props.theme.primaryColor};
    }
  }


  & > .body {
    display: none;

    & > .description {
      width: 70%;
      max-width: 70%;
    }

    & > .action {
      margin-left: 5%;
      width: 25%;
      max-width: 25%;

      & > button {
        width: 100%;
      }

      & > .hint {
        font-size: 10px;
        @media (min-width: ${props => props.theme['mobileBreakpoint']}) {
          font-size: ${props => props.theme['$font-size-xxxs']};
        }
        font-weight: ${props => props.theme['$weight-regular']};
        letter-spacing: 0.52px;
        line-height: 21px;
        margin-top: 10px;
      }
    }
  }

`;

const OutOfStock = styled.div`
  color: ${props => props.theme.primaryColor};
  font-size: ${props => props.theme['$font-size-xs']};
  font-weight: ${props => props.theme['$weight-regular']};
`;

const DEFAULT_QUANTITY = 1;

const ME_QUERY = gql`
  query LoadProduct($id: ID!) {
    me{
      orders(first:10, productId: $id){
        edges{
          node{
            id
            lines{
              id
              variant{
                id
                isDigital
              }
            }
          }
        }
      }
    }
  }
`;

export default class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      loadingOrder: false,
      boughtVariant: null,
    }
    this.toggleBody = this.toggleBody.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {

    if (!prevState.isOpen && this.state.isOpen && this.props.isLoggedIn) {

      const {
        variants,
      } = this.props;
      this.setState({
        loadingOrder: true,
      });
      this.props.client.query({
        query: ME_QUERY,
        variables: {
          id: this.props.id,
        }
      }).then(
        ({
          data: {
            me: {
              orders: {
                edges: orderEdges,
              } = {}
            } = {}
          } = {},
          error,
          loading
        }) => {
          this.setState({
            loadingOrder: false,
          });
          this.setState({
            boughtVariant: orderEdges && orderEdges.length > 0 ?
              orderEdges.reduce((acc, { node: { id: orderId, lines } }) => {
                return {
                  orderId,
                  ...lines.reduce((acc, { id: lineId, variant }) => {
                    if (variants && variants.length > 0 && variants[0].id === variant.id) {
                      return { ...variant, lineId }
                    }
                    return acc;
                  }, {})
                }
              }, {}) : null
          })
        }
      )
    }
  }

  parentCheck(parentSection, property) {
    return (parentSection &&
      parentSection.parentProduct &&
      parentSection.parentProduct[property]);
  }

  toggleBody() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  closeBody() {
    this.setState({
      isOpen: false
    })
  }

  render() {
    const {
      name,
      editors,
      description,
      isAvailable,
      variants = [],
      saveVariant,
      className,
      images,
      expanded,
      currency,
      pageNumber,
      showParentInfo = true,
      parentSection,
      history: {
        push
      },
      userSubscriptionId,
      isMagazine
    } = this.props;

    const {
      id: variantId,
      inrPrice: {
        localized: inrLocalized,
      } = {},
      usdPrice: {
        localized: usdLocalized,
      } = {},
    } = variants && variants.length > 0 ? variants[0] : {};
    // this.parentCheck(parentSection, 'images') ?  parentSection.parentProduct.
    const imageUrl = images.reduce((acc, { url } = {}) => url, "");

    if (expanded != undefined) {
      this.state.isOpen = expanded == false ? false : this.state.isOpen;
    }


    const {
      isOpen,
      loadingOrder,
      boughtVariant,
    } = this.state;

    // console.log("Rendered: " + isOpen + " " + name);


    // console.log('Rendered A: ' + name + " " + isOpen);

    const singularCategoryName = this.parentCheck(parentSection, 'category') && parentSection.parentProduct.category.name && parentSection.parentProduct.category.name.replace(/s/gi, '');

    return (

      <ArticleWrapper className={className}>

        <Container className={`px-5 buy-more ${!(isOpen) ? 'grad' : 'nograd'}`} onClick={this.props.onClick} >

          <Row onClick={this.toggleBody} className="header mb-3">

            <Col xs="9" lg="11">
              <h3 className="title">{name}</h3>
              <span style={{ color: '#1C1C1C' }}>{getEditorName(editors)}</span>
              {
                pageNumber &&
                <span>&nbsp;&nbsp;{pageNumber}</span>
              }
            </Col>



            <Col xs="3" lg="1" className="header-price d-flex align-items-center justify-content-center" >
              <span >{
                isOpen ? '' :
                  getLocalizedAmount({ currency, inr: inrLocalized, usd: usdLocalized })
              }</span>
              <FontAwesome className="ml-3" name={`${isOpen ? 'chevron-up' : 'chevron-down'}`} />
            </Col>


          </Row>
          <Row className={`body ${isOpen ? 'isOpen pb-4 mr-0' : ''}`} style={{ cursor: 'auto' }}>
            <Col lg="6" className={`order-lg-2 cart-info ml-lg-auto`} style={imageUrl == "" ? { height: '0px', cursor: 'auto' } : { cursor: 'auto' }}>
              <img
                className="img-fluid"
                src={replaceStaticUrl(imageUrl)}
                style={{ paddingTop: "10px" }}
              />
            </Col>
            <Col lg="6" className="description" style={{ paddingTop: imageUrl == "" ? '0px' : '10px', cursor: 'auto' }}>
              {ReactHtmlParser(description)}
              {
                showParentInfo &&
                <Row>
                  <Col className="col-12 product-heading my-3 ">This article appears in:</Col>
                  <Col onClick={() => {
                    if (this.parentCheck(parentSection, 'id'))
                      return push(`/product/${parentSection.parentProduct.id}`)
                  }} className="col-12 col-md-2">
                    {imageUrl.length > 0 && (<img
                      alt=""
                      className="img-fluid w-100"
                      src={replaceStaticUrl(imageUrl)}
                    />)}
                  </Col>
                  <Col className="col-md-8 pl-md-0" onClick={() => {
                    if (this.parentCheck(parentSection, 'id'))
                      return push(`/product/${parentSection.parentProduct.id}`)
                  }
                  }>
                    <div className="product-type mt-3 mt-md-0">{singularCategoryName}:</div>
                    <div className="title mt-3 mt-md-0">{this.parentCheck(parentSection, 'name') ? parentSection.parentProduct.name : '--'}</div>
                    <div className="editor-name">Edited by&nbsp;{this.parentCheck(parentSection, 'editors') ? getEditorName(parentSection.parentProduct.editors) : getEditorName([])}</div>
                  </Col>
                </Row>
              }
            </Col>
          </Row>

          <Row className={`body ${isOpen ? 'isOpen pb-4' : ''}`} style={{ cursor: 'auto' }}>

            <Col lg="6" className="order-lg-2 cart-info ml-lg-auto" >
              {
                !isAvailable && this.props.purchasable &&
                <OutOfStock className="out-of-stock">Out of stock</OutOfStock>
              }
              {
                isAvailable &&
                <div className="d-flex action text-lg-center mb-3 article-price">
                  {
                    loadingOrder &&
                    <FontAwesome id="searchIcon" name='spinner' spin className='color-black' />
                  }
                  {
                    !loadingOrder && !boughtVariant && !userSubscriptionId &&
                    <RaisedButton
                      className="btn-hover"
                      onClick={
                        () => {
                          const variant = variants[0];
                          if (!variant) return;
                          saveVariant({ quantity: DEFAULT_QUANTITY, variant: variant });
                          FbPixel.track('AddToCart',
                            {
                              'product_type': 'article',
                              'content_name': name,
                              'content_ids': [variant.id],
                              'content_type': 'product',
                              'currency': currency,
                              'value': currency === "INR" ?
                                variant.inrPricing.price.net.localized.replace('₹', '') : variant.usdPricing.price.net.localized.replace('$', '')
                            });
                        }
                      }
                      style={{
                        padding: '10px 20px'
                      }}
                    >
                      Add to cart
                    </RaisedButton>
                  }
                  {
                    !loadingOrder && boughtVariant &&
                    <RaisedButton
                      onClick={
                        (e) => (
                          window.open(`/reader/?order-id=${boughtVariant.orderId}&line-id=${boughtVariant.lineId}`, '_blank')
                        )
                      }>
                      Digital: Read now
                    </RaisedButton>
                  }
                  {
                    !loadingOrder && !boughtVariant && userSubscriptionId &&
                    <RaisedButton
                      onClick={
                        (e) => (
                          window.open(`/reader/?user-subscription-id=${userSubscriptionId}&variant-id=${variantId}`, '_blank')
                        )
                      }>
                      Digital: Read now
                    </RaisedButton>
                  }

                  <span style={{
                    paddingLeft: '20px',
                    paddingRight: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    alignSelf: 'flex-end',
                    cursor: 'auto'
                  }}>{
                      getLocalizedAmount({ currency, inr: inrLocalized, usd: usdLocalized })
                    }</span>

                </div>
              }
            </Col>
            <Col lg="6" className="description">
              <div className="hint">This is a digital article. You can read it on the Marg website using any device.</div>
            </Col>



          </Row>



        </Container>
      </ArticleWrapper>
    )
  }
}