import React,{ useState,useEffect } from 'react'
import { Row,Col } from 'reactstrap';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import FlatButton from "../../commons/FlatButton";
import {  replaceStaticUrl } from '../../../utils';
import { HeroCard } from "./HeroCard.jsx";
import { Card } from "./Card.jsx";
const SingleBlog = styled.div`
  .CategoryText{
    color: #ec1d24;
    font-family: Lato;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  h6{
    color: #3a3a3a;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.59px;
    line-height: 23px;
  }
  .BlogTitle{
    color: #000000;
    letter-spacing: 0.66px;
    line-height: 23px;
  }
  .hero-title {
    font-family: ${props => props.theme["$font-secondary-medium"]};
    font-size: ${props => props.theme["$font-size-lg"]};
    font-weight: ${props => props.theme["$weight-semibold"]};
  }
  .regular-title {
    font-family: ${props => props.theme["$font-primary-medium"]};
    font-size: ${props => props.theme["$font-size-xs"]};
    font-weight: ${props => props.theme["$weight-bold"]};
  }
  .meta {
    font-size: ${props => props.theme["$font-size-xxs"]} !important;
    font-weight: ${props => props.theme["$weight-medium"]};
    letter-spacing: 0.59px;
    line-height: 23px;
  }
  .post{
    font-weight: ${props => props.theme["$weight-bold"]};
  }
  .description {
    line-clamp: 3;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    line-height: 1.8em;
    font-size: ${props => props.theme["$font-size-xxs"]} !important;
  }
  .img-fluid {
    width: 100%;
  }
  .thumbnail {
    width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: top;
  }
  .ReadPostLink{
    color: #ec1d24;
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  .MobileAdjust{
    float:right;
    width:60%
  }
  @media (max-width: 768px) {
    .tab-content{
      padding:0;
    }
    .MobileAdjust{
      width:100% !important;
    }
  }
  .row {
    max-width: 1179px;
  }
  .date-author-color {
    color:  ${props => props.theme.secondaryColor};
  }
`;
const BlogCard = ({ email, blogs , handle_selected_category }) => {
  return (
    <SingleBlog className="container">
      <div className="row mx-auto">
        {
          blogs.map((blog, i) =>
            i ===0?
            <HeroCard blog={blog} handle_selected_category={handle_selected_category} i={i} />
            : 
            <Card blog={blog} handle_selected_category={handle_selected_category} i={i} />
          )
        }
        </div>
    </SingleBlog>
  )
}
export default BlogCard