import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import {
  replaceStaticUrl,
  getEditorName,
  getLocalizedAmount
} from "./../../../../utils/";

const CardContainer = styled.div`
  cursor: pointer;

  & > div {
    @media (min-width: ${props => props.theme["mobileBreakpoint"]}) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .name,
  .meta,
  .price {
      font-size: ${props => props.theme["$font-size-xxs"]};
  }

  & > .name {
    color: #000000;
    font-weight: ${props => props.theme["$weight-semibold"]};
    padding-top: 15px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
  }

  & > .meta {
    color: #000000;
    font-weight: ${props => props.theme["$weight-regular"]};
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 5px;

    & > .spacer {
      margin: 0px 10px;
    }
  }

  .price,
  .year {
    font-size: 14px;
    color: #808080;
  }
  .strike-through {
    text-decoration: line-through;
    margin-right: 5px;
  }
`;

export default withRouter(({
  id,
  name,
  subTitle,
  variants,
  attributes,
  className,
  thumbnailUrl,
  images,
  editors,
  history: { push },
  currency
}) => {
  let year;
  const imageUrl = images.reduce((acc, { url } = {}) => url, "");
  attributes.forEach(it => {
    if (it.attribute.slug == "year") {
      year = it.value.name;
    }
  });

  const cardEditors = getEditorName(editors);

  const openProductDetails = id => {
    return push(`/product/${id}`);
  };

  const subtitle = subTitle == null ? '' : ": " + subTitle;
  console.log('subtitle: ' + subtitle);

  const { digitalOnSale, digitalPrice, digitalOriginalPrice, printOnSale, printablePrice, printOriginalPrice } = variants.reduce(
    (acc, { inrPrice, usdPrice, isDigital, inrPricing, usdPricing }) => {
      const { localized: localizedOriginalInr } = inrPrice || {};
      const { localized: localizedOriginalUsd } = usdPrice || {};
      const { onSale: onSaleInr, price: { net: {localized: localizedInr} = {} }={} } = inrPricing || {};
      const { onSale: onSaleUsd, price: { net: {localized: localizedUsd} = {} } = {} } = usdPricing || {};

      const localizedAmount = getLocalizedAmount({
        currency,
        inr: localizedInr,
        usd: localizedUsd
      });

      const localizedOriginalAmount = getLocalizedAmount({
        currency,
        inr: localizedOriginalInr,
        usd: localizedOriginalUsd
      });

      if (isDigital) {
        acc.digitalOnSale = currency == "INR"? onSaleInr: onSaleUsd;
        acc.digitalPrice = localizedAmount;
        acc.digitalOriginalPrice = localizedOriginalAmount;
      } else {
        acc.printOnSale = currency == "INR"? onSaleInr: onSaleUsd;
        acc.printablePrice = localizedAmount;
        acc.printOriginalPrice = localizedOriginalAmount;
      }
      return acc;
    },
    {}
  );

  return (
    <CardContainer
      onClick={e => openProductDetails(id)}
      className={`${className} mb-5`}
    >
      <img
        alt=""
        className="img-fluid w-100"
        src={replaceStaticUrl(imageUrl)}
      />
      <div className="name">{name + subtitle}</div>
      <div className="meta">
        <div>{cardEditors}</div>
      </div>
      <div className="meta">
        <div className="year d-block">{year}</div>
      </div>
      <div className="price">
        {printOnSale && <span className="strike-through">{printOriginalPrice}</span>}
        {printablePrice && <span>{printablePrice}</span>}
        {digitalPrice && printablePrice && <span>&nbsp;/&nbsp;</span>}
        {digitalOnSale && <span className="strike-through">{digitalOriginalPrice}</span>}
        {digitalPrice && <span>{digitalPrice}</span>}
      </div>
    </CardContainer>
  );
});
