import React from 'react';
import { replaceStaticUrl, getEditorName } from './../../utils/';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { RaisedButton } from './../commons/';

const CardContainer = styled.div`

  /* cursor: pointer; */

  & > div {
    @media (min-width: ${props => props.theme['mobileBreakpoint']}) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .name, .meta, .price {
    font-size: 12px;
    @media (min-width: ${props => props.theme['mobileBreakpoint']}) {
      font-size: ${props => props.theme['$font-size-xxs']};
    }
  }

  & > .name {
    color: #000000;
    font-weight: ${props => props.theme['$weight-bold']};
    padding-top: 15px;
  }

  & > .meta {
    color: #000000;
    font-weight: ${props => props.theme['$weight-regular']};
    letter-spacing: 0.59px;
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 5px;

    & > .spacer {
      margin: 0px 10px;
    }
  }

  & > .price {
    color: #000000;
    font-weight: ${props => props.theme['$weight-regular']};
  }
`;

const getFileAccessUrl = (paramsArr, isVideo=false) => {
  const mainUrl = isVideo ? "player": "reader";
  const paramsStr = paramsArr.reduce((acc, param) => {
    const {
      key, value
    } = param;
    return `${acc}${key}=${value}&`;
  }, "");
  return `/${mainUrl}/?${paramsStr}`
}

const Library = ({ value, index, orders,  subscriptions }) => {
  let subscriptionItems = subscriptions.map(subscription => (
    subscription.readableDigitalFiles.map(readableDigitalFile => (
      {
        'subscription-id': subscription.id,
        'product': readableDigitalFile.product,
        'variant-id': readableDigitalFile.id,
        'thumbnail-url': readableDigitalFile.product.thumbnail.url,
        'render-url': 
          getFileAccessUrl([
            {
              key: "user-subscription-id",
              value: subscription.id,
            },
            {
              key: 'variant-id',
              value: readableDigitalFile.id,
            }
          ])
      }
    ))
  ))
  subscriptionItems = [].concat(...subscriptionItems)

  let orderedItems = orders.map(order => (
    order.lines.map(line => {
      if(line.variant.isDigital){
        return ({
          "order-id": order.id,
          "line-id": line.id,
          "product": line.variant.product,
          "thumbnail-url": line.thumbnail.url,
          "render-url": getFileAccessUrl([
            {
              key: "order-id",
              value: order.id,
            },
            {
              key: "line-id",
              value: line.id,
            }
          ], line.variant.product.category && line.variant.product.category.name == "Videos")
        })
      }
    })
  ))
  orderedItems = [].concat(...orderedItems)
  orderedItems = orderedItems.filter(value => typeof value !== 'undefined')
  let allItemsUnfiltered = orderedItems.concat(subscriptionItems)
  let allItems = []
  let seen = new Set()
  allItemsUnfiltered.forEach(item => {
    if(!seen.has(item.product.name)){
      seen.add(item.product.name)
      allItems.push(item)
      return true
    }
    else{
      return false
    }
  })
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      <Container>
        <Row>
          <Col xs="12" lg="10" className="mx-auto">
            <Row>
              {                    
                allItems.map(item => {
                    const product = item.product
                    const {name, attributes, images, editors, category} = product
                    const cardEditors = getEditorName(editors);
                    let year = "";
                    attributes.forEach((it) => {
                      if(it.attribute.slug == 'year') {
                        year = it.value.name
                      }
                    });
                    const isVideo = category && category.name == 'Videos'? true: false;
                    return (
                      <Col xs="6" lg="4" className="mb-5">
                        <CardContainer>
                          <img className="img-fluid w-100" src={replaceStaticUrl(images.length > 0 ? images[0].url : item['thumbnail-url'])} />
                          <div className="name">{name}</div>
                          <div className="meta">
                            <div>{cardEditors}</div>
                            {
                              cardEditors && year &&
                              <div className="spacer">|</div>
                            }
                            <div>{year}</div>
                          </div>
                          <div className="col-3 p-0 text-right my-2">
                            {
                                <RaisedButton
                                className="btn-hover"
                                  onClick={
                                    (e) => (
                                      window.open(item['render-url'], '_blank')
                                    )
                                  }
                                >
                                  {isVideo? "Watch": "Read"}
                                </RaisedButton>
                            }
                          </div>
                        </CardContainer>
                      </Col>
                    )
                })
              }
            </Row>
          </Col>
        </Row>
      </Container>
      
    </div>
  )
}

export default Library;
