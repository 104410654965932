import { Collaborate } from './Collaborate';
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo';


const mapStateToProps = ({
	cmsData: {
		collaborate
	}
}, ownProps) => {
	return {
    collaborate: {
        ...collaborate
    }
	}
}

export default connect(
  mapStateToProps,
)(withApollo(Collaborate));

