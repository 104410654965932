import { Banner } from './Banner';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';


const mapStateToProps = ({
	cmsData: {
		banner,
	}
}, ownProps) => {
	return {
    banner: {
				...banner,
    }
	}
}

export default connect(
  mapStateToProps,
)(withApollo(Banner));

