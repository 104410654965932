import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import AddressList from './AddressList/';
const Wrapper = styled.div`
  @media (min-width: 992px) {
    padding: 50px;
  }
`;

const CheckoutAddress = ({
  isLoggedIn,
  lines,
  shippingAddress,
  shippingMethod,
  warningNotification,
  location,
  history: {
    push,
  }
}) => {

  
  useEffect(() => {
    if(isLoggedIn) return;
    const digitalProd = lines.find((line) => line.variant.isDigital);
    if(digitalProd) {
      warningNotification("Please log in to complete your digital order");
      return push(`/login`, {from: location})
    }
  }, [lines]);

  return <Wrapper>
    <AddressList />
  </Wrapper>
}

export default withRouter(CheckoutAddress);

