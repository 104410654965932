import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StoreListingWrapper = styled.div`
    animation: bookstoresFadeIn ease 1s;
    @keyframes bookstoresFadeIn { 
    0% { 
    opacity: 0;
    transform: translate3d(0, -2%, 0);
    } 
    100% { 
    opacity: 1;
    transform: none;
    } 
    }

  margin: 100px 0px;

  h1 {
    color: #000000;
    font-family: "Cormorant Medium";
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 57px;
  }

  .location-name {
    color: #ec1d24;
    font-family: Lato;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .breadcrumb-align {
    padding-top: 0;
    padding-bottom: 20px;
  }
`;

const StoreWrapper = styled.div`

  margin-bottom: 30px;

  span {
    display: block;
  }

  .store-name {
    color: #000000;
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.66px;
    margin: 10px 0px;
  }

  .address-line {
    color: #3a3a3a;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.59px;
  }

  .phone-number {
    margin: 10px 0px;
  }

  .url {
    color: #3a3a3a;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    a {
      color: inherit
    }
  }
`;

const Bookstores = props => {

  const [locations, setLocations] = useState([]);
  const bookstores = props.bookStores.pageData;

  useEffect(() => {
    if (bookstores.length > 0) {
      let storeLocations = []
      bookstores.map(store => {
        storeLocations.push(store.location);
      });
      const validLocations = Array.from(new Set(storeLocations.map((it) => it.name))).map(name => {
        return storeLocations.find(a => a.name === name)
      });
      setLocations([...validLocations]);
    }
  }, [bookstores]);

  return (
    <StoreListingWrapper className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h1>Find Marg at a Bookstore</h1>
        </div>
      </div>
      {bookstores.length > 0 && locations.map(location => {
        return (
          <div className="row my-5">
            <div className="col-12 col-lg-10 mx-auto">
              <h2 class="location-name">{location.name}</h2>
              <hr />
              <div className="row">
                {bookstores
                  .filter(store => store.location.name === location.name)
                  .map(store => <Store store={store} />)}
              </div>
            </div>
          </div>
        )
      })}
    </StoreListingWrapper>
  )
};

const Store = ({ store }) => {
  return (
    <StoreWrapper className="col-6 col-lg-3">
      <span className="store-name">{store.name}</span>
      {store.addressLine1 && <span className="address-line">{store.addressLine1}</span>}
      {store.addressLine2 && <span className="address-line">{store.addressLine2}</span>}
      {store.addressLine3 && <span className="address-line">{store.addressLine3}</span>}
      {store.city && <span className="address-line">{store.city}</span>}
      {store.phoneNumber && <span className="address-line phone-number">{store.phoneNumber}</span>}
      <span className="url">
        <a href={store.url} target="_blank" rel="noopener noreferrer">
          {store.urlLabel}
        </a>
      </span>
    </StoreWrapper>
  );
};

export default Bookstores;