import { Trustees } from './Trustees';
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo';


const mapStateToProps = ({
	cmsData: {
		trustees
	}
}, ownProps) => {
  console.log("Team container: ", trustees)
	return {
    trustees: {
        ...trustees
    }
	}
}

export default connect(
  mapStateToProps,
)(withApollo(Trustees));

