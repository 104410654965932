import { SubmitProposals } from './SubmitProposals';
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo';


const mapStateToProps = ({
	cmsData: {
		proposal
	}
}, ownProps) => {
	return {
    proposal: {
        ...proposal
    }
	}
}

export default connect(
  mapStateToProps,
)(withApollo(SubmitProposals));

