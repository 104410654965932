import { SubscriptionsForBlog } from './SubscriptionsForBlog';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';

const mapStateToProps = ({
	cmsData: {
		blogIndexPage,
	}
}, ownProps) => {
	return {
    blogIndexPage: {
				...blogIndexPage,
		}
	}
}

export default connect(
  mapStateToProps,
)(withApollo(SubscriptionsForBlog));