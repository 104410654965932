import React from "react";
import { withRouter } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { FlatButton } from "../../commons";
import { Link } from "react-router-dom";
import {
  replaceStaticUrl,
} from "./../../../utils/";

function EventSection({ events, history }) {
  return events.map((event) => (
    <div className="row my-5 ">
      <div className="col-10 mx-auto">
        <div className="row">
      <div className="col-lg-4">
      <Link to={`/event/${event.slug}`}>
      <img
        className="img-fluid events-img"
        src={
          replaceStaticUrl(
            event.image
          )
        }
        alt="No image"
      />
      </Link>
      </div>
      <div className="col-lg-8">
      <h6 className="mt-3 mt-lg-0 head-title mb-3">{event.title}</h6>
      <div className="event-time-description">
      <p className="date-time mb-2">{event.city}:&nbsp;{event.commenceDate}</p>
      <p className="description">{ReactHtmlParser(event.textDescription)}</p>
      </div>
      {/* Added HTML parser for quotes to work */}
      <FlatButton onClick={(e) => history.push(`/event/${event.slug}`)}>VIEW DETAILS & REGISTER</FlatButton>
      </div>
      </div>
      </div>
    </div>
  ));
}

export default withRouter(EventSection);
