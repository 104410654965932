import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import {
  replaceStaticUrl,
  getEditorName,
  getLocalizedAmount
} from "./../../../utils/";

const CardContainer = styled.div`
  cursor: pointer;
  min-height: 350px;
  // & > div {
  //   @media (min-width: ${props => props.theme["mobileBreakpoint"]}) {
  //     padding-left: 0;
  //     padding-right: 0;
  //   }
  // }

 

  & > .name-issue {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  }

  & > .meta {
    color: #000000;
    font-weight: ${props => props.theme["$weight-regular"]};
    
    display: flex;
    flex-direction: row;
    padding-top: 5px;

    & > .spacer {
      margin: 0px 10px;
    }

    & > .year {
      color: #EB2027

    }
  }

  .price,.year,.editor{
    font-size: 14px;
    color: #000000;
  }

  .price, .editor {
    opacity: 0.5;
  }

  .editor {
    padding-bottom: 10px;
  }
  .strike-through {
    text-decoration: line-through;
    margin-right: 5px;
  }
`;

export default withRouter(({
  id,
  name,
  subTitle,
  variants,
  attributes,
  className,
  thumbnailUrl,
  images,
  editors,
  editorType,
  history: { push },
  currency
}) => {

  let year;
  const imageUrl = images.reduce((acc, { url } = {}) => url, "");
  attributes.forEach(it => {
    if (it.attribute.slug == "year") {
      year = it.value.name;
    }
  });

  const subtitle = subTitle == null ? '' : ": " + subTitle;

  const cardEditors = getEditorName(editors);

  const openProductDetails = id => {
    return push(`/product/${id}`);
  };

  const { digitalOnSale, digitalPrice, digitalOriginalPrice, printOnSale, printablePrice, printOriginalPrice } = variants.reduce(
    (acc, { inrPrice, usdPrice, isDigital, inrPricing, usdPricing }) => {
      const { localized: localizedOriginalInr } = inrPrice || {};
      const { localized: localizedOriginalUsd } = usdPrice || {};
      const { onSale: onSaleInr, price: { net: { localized: localizedInr } = {} } = {} } = inrPricing || {};
      const { onSale: onSaleUsd, price: { net: { localized: localizedUsd } = {} } = {} } = usdPricing || {};

      const localizedAmount = getLocalizedAmount({
        currency,
        inr: localizedInr,
        usd: localizedUsd
      });

      const localizedOriginalAmount = getLocalizedAmount({
        currency,
        inr: localizedOriginalInr,
        usd: localizedOriginalUsd
      });

      if (isDigital) {
        acc.digitalOnSale = currency == "INR" ? onSaleInr : onSaleUsd;
        acc.digitalPrice = localizedAmount;
        acc.digitalOriginalPrice = localizedOriginalAmount;
      } else {
        acc.printOnSale = currency == "INR" ? onSaleInr : onSaleUsd;
        acc.printablePrice = localizedAmount;
        acc.printOriginalPrice = localizedOriginalAmount;
      }
      return acc;
    },
    {}
  );

  return (
    <CardContainer
      onClick={e => openProductDetails(id)}
      className={`${className}`}
    >
      <img
        alt=""
        className="img-fluid carousel"
        src={replaceStaticUrl(imageUrl)}
      />
      <div className="meta mt-1">
        <div className="year">{year}</div>
      </div>
      <div className="name-issue">{name  + subtitle}</div>

      <div className="editor">
        {getEditorName(editors)}
      </div>

      <div className="price">
        {printOnSale && <span className="strike-through">{printOriginalPrice}</span>}
        {printablePrice && <span>{printablePrice}</span>}
        {digitalPrice && printablePrice && <span>&nbsp;/&nbsp;</span>}
        {digitalOnSale && <span className="strike-through">{digitalOriginalPrice}</span>}
        {digitalPrice && <span>{digitalPrice}</span>}
      </div>
    </CardContainer>
  );
});
