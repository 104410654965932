import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { useRouteMatch } from "react-router-dom";
import FlatButton from "../../commons/FlatButton";
import { replaceStaticUrl } from "../../../utils";
import { withRouter } from "react-router";
const Category = styled.div`
  div:not(:last-of-type)::after {
    content: ",\u00A0";
  }
  div:hover {
    text-decoration: underline;
  }
`;
export const Card = withRouter(({ blog, history, className, handle_selected_category }) => {
  return (
  <Col md={"6"} className={`blog-card-container ${className} mb-4`}>
      <div>
        <Link to={`/blog/${blog.slug}`}>
          <img
            src={replaceStaticUrl(blog.image)}
            style={{ objectFit: "cover" }}
            className="thumbnail thumbnail-in-related-blogs"
          />
          <h3 className="mt-3 BlogTitle regular-title">{blog.title}</h3>
        </Link>
      </div>
      <div className="ml-3 mr-0">
      <Link to={`/blog/${blog.slug}`}>
        <div className="row">
          <div className="meta date-author-color">
          {blog.publicationDate}</div>
          <div className="date-author-color">
            &nbsp;|&nbsp;
          </div>
          <div className="date-author-color">By:&nbsp;</div>
                {
                  blog.authors.map(({name}) => name)
                    .reduce((acc, name, id) => {
                      console.log(id);
                      if(id < blog.authors.length - 1) {
                        return acc.concat(`${name},\u00A0`);
                      }
                      return acc.concat(name);
                    }, [])
                    .map((name) => <div className="date-author-color">{name}</div>)
                }
          </div>
          </Link>
      </div>
    <Category className="row mt-1 ml-0" style={{ color:'grey', cursor: 'pointer', marginBottom: '5px' }}>
      {blog.categories.map((category) => (
        <div
          onClick={() => {
            (handle_selected_category && handle_selected_category(category)) ||
             history.push(`/blog?category-id=${category.id}`);
          }}
          >
          {category.name}
        </div>
      ))}
    </Category>
      <Link to={`/blog/${blog.slug}`}>
        <FlatButton className="pt-1 post mb-3">
          Read Post</FlatButton>
      </Link>
  </Col>
  )});