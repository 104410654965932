import { About } from './About';
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo';


const mapStateToProps = ({
	cmsData: {
		about
	}
}, ownProps) => {
	return {
    about: {
        ...about
    }
	}
}

export default connect(
  mapStateToProps,
)(withApollo(About));

