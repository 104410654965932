import { connect } from 'react-redux'
import { withApollo } from 'react-apollo';
import CheckoutResult from './CheckoutResult.jsx';
import actions from './../../../actions/';

const mapStateToProps = ({
  auth: {
    email,
  } = {},
}, ownProps) => ({
  isAnonymous: email? false: true,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  reloadAuthenticatedUser: () => dispatch(actions.reloadAuthenticatedUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withApollo(CheckoutResult));