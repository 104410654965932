import React, { useState } from "react";
import styled from "styled-components";
import FontAwesome from 'react-fontawesome';
import { Collapse } from 'reactstrap';
import gql from "graphql-tag";
import { FlatButton } from "./../../../commons/"

const Wrapper = styled.div`
  border: 1px solid #cccccc;
  margin-top: 35px;
  padding: 10px 20px;
  & > div > .title {
    color: #000000;
    font-family: ${props => props.theme["$font-primary-medium"]};
    font-size: ${props => props.theme["$font-size-xxs"]};
    font-weight: ${props => props.theme["$weight-bold"]};
    letter-spacing: 0.7px;
    line-height: 34px;
    text-transform: uppercase;
    cursor: pointer;
    margin: 0px !important;
  }

  & .input-container {
    position: relative;
    margin-top: 30px;
    margin-bottom: 15px;

    & .has-error {
      background: #ff000099;
    }

    & input.coupon-box {
      padding-top: 18px;
      padding-bottom: 18px;
      width: 100%;
      border: 1px solid #979797;
    }

    & > div.spinner-container {
      position: absolute;
      top: 0px;
      height: 100%;
      left: 95%;
    }
  }

  & .errors {
    color: red;
  }
`;

const APPLY_DISCOUNT = gql`
  mutation ApplyDiscount($checkoutId: ID!, $voucherCode: String!) {
    checkoutUpdateVoucher(checkoutId: $checkoutId, voucherCode: $voucherCode) {
      errors {
        field
        message
      }
      checkout {
        id
        discountAmount
        voucherCode
        subtotalPrice{
          net{
            currency
            amount
            localized
          }
          gross{
            currency
            amount
            localized
          }
        }
        totalPrice{
          net{
            currency
            amount
            localized
          }
          gross{
            currency
            amount
            localized
          }
        }
      }
    }
  }
`;

const VoucherInput = ({cartId, client, voucherCode, setDiscount, setVoucherCode, }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ couponCode, setCouponCode ] = useState();
  const [ isLoading, setIsLoading ] = useState();
  const [ hasError, setHasError ] = useState();

  const toggle = () => setIsOpen(!isOpen);

  const applyVoucher = () => {
    setIsLoading(true);
    client.mutate({
      mutation: APPLY_DISCOUNT,
      variables: {
        checkoutId: cartId,
        voucherCode: couponCode,
      }
    }).then(({
      data: {
        checkoutUpdateVoucher: {
          checkout,
          errors,
        } = {},
      } = {},
    } = {}) => {
      setIsLoading(false);
      const {
        discountAmount,
        subtotalPrice,
        totalPrice,
        voucherCode,
      }  = checkout? checkout: {};
      if(errors && errors.length > 0) {
        setHasError(true);
        return false;
      }
      setDiscount({
        discountAmount,
        subtotalPrice,
        totalPrice,
      });
      setVoucherCode(voucherCode);
    }, () => {
      setIsLoading(false);
      setHasError(true);
    });
  }
  
  return <Wrapper>
    {
      !voucherCode &&
        <div>
          <div className="title row justify-content-between" onClick={toggle}>
            <div>Have a discount code?</div>
            <div>
              <FontAwesome className="ml-3" name={`${isOpen ? 'minus' : 'plus'}`} />
            </div>
          </div>
          <Collapse isOpen={isOpen}>
            <div>
              <div className="input-container">
                <input
                  className={`coupon-box ${hasError? "has-error": ""} `}
                  type="text"
                  name="couponcode"
                  value={couponCode}
                  onChange={(e) => {
                      setCouponCode(e.target.value)
                    }
                  }
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      applyVoucher()
                    }
                  }}
                  />
                {
                  hasError &&
                  <div class="errors">Coupon invalid</div>
                }
                <div className="row align-items-center spinner-container">
                  <div>
                    {
                      isLoading &&
                      <FontAwesome id="searchIcon" name='spinner' spin />
                    }
                  </div>
                </div>
              </div>
              <FlatButton onClick={() => applyVoucher()}>Apply</FlatButton>
            </div>
          </Collapse>
        </div>
    }
    {
      voucherCode &&
        <div>
          <div>Applied coupon: {voucherCode}</div>
        </div>
    }
  </Wrapper>
};

export default VoucherInput;