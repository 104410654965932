import React, { useEffect } from "react";
import { connect } from 'react-redux';
import styled from "styled-components";
import ReactGA from 'react-ga';
import FbPixel from "../../utils/fbPixel";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import info from "../../images/info.svg";
import { RaisedButton, RadioButtonSet, FlatButton } from "./../commons/";
import FontAwesome from 'react-fontawesome';
import {
  getLocalizedAmount
} from "./../../utils/";
const OutOfStock = styled.div`
  color: ${props => props.theme.primaryColor};
  font-size: ${props => props.theme["$font-size-xs"]};
  font-weight: ${props => props.theme["$weight-regular"]};
`;

const DEFAULT_QUANTITY = 1;

const useStylesBootstrap = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: '#F8F8F8F7 ',
    color: 'rgba(0, 0, 0, 1)',
    maxWidth: 220,
    fontSize: '14px',
    boxShadow: '0px 3px 5px #0000001A'
  },
}));


function HtmlTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} disableTouchListener placement="right" {...props} />;
}




const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
    @media (max-width: 1139px) {
      font-size: 16px;
    }
    
  font-weight: ${props => props.theme["$weight-bold"]};
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${props => props.theme.underlineColor};

  & > .name {
    width: 40%;
  }


  & > .price-container.discounted {
    margin-left: 20px;
    & >.price {
       font-weight: 400;
    }
  }

  & > .price-container.striked {
    color: red;
    text-decoration: line-through;
    & >.price {
      color: black;
      font-weight: 400;
    }
  }

  & > .info-icon-div {
    padding-left: 15px;
      & > .info-icon {
        height: 20px;
        width: 20px;
        @media (max-width: 767px) {
          height: 18px;
          width: 18px;
        }
      }
    }





`;

const TooltipWrapper = styled.div`

padding-left: 10px;
padding-right: 10px;

.close-tooltip {
  visibility: hidden;
  @media (max-width: ${props => props.theme["mobileBreakpoint"]}) {
    visibility: visible;
  }
}


`;

export const ProductPricing = ({
  variants, isAvailable, currency, name, saveVariant, id, images, thumbnailUrl,
  isVideo, categoryName,
  orderEdges, subscriptions, currentUserSubscription, hideSubscription, closeDialog,ref
}) => {

  function closePopup() {
    if  (closeDialog!= undefined){
      closeDialog();
    }
    
  }

  let selectedVariant = {};
  console.log(hideSubscription);

  const getFileAccessUrl = (paramsArr) => {
    const mainUrl = isVideo ? "player" : "reader";
    const paramsStr = paramsArr.reduce((acc, param) => {
      const {
        key, value
      } = param;
      return `${acc}${key}=${value}&`;
    }, "");
    return `/${mainUrl}/?${paramsStr}`
  }

  const productVariants = variants.map(variant => {
    let foundVar = orderEdges.reduce((acc, order) => {
      const foundLine = order.node.lines.find(
        ({ variant: lineVariant }) => {
          return lineVariant.id === variant.id && variant.isDigital;
        }
      );
      if (foundLine && !acc) {
        return {
          ...variant,
          alreadyBought: true,
          url: getFileAccessUrl([
            {
              key: "order-id",
              value: order.node.id,
            },
            {
              key: 'line-id',
              value: foundLine.id,
            }
          ]),
        };
      }
      return acc;
    }, null);
    const subVar = subscriptions.reduce((acc, sub) => {
      if (!variant.isDigital || isVideo) {
        return acc;
      }

      if (
        sub.subscription.categoryType.toUpperCase().match(categoryName.toUpperCase())
        && sub.subscription.issueType === "ALL_ISSUES"
        && sub.subscription.variantType == ["DIGITAL", "DIGITAL_AND_PRINT"].find(
          (it) => it === sub.subscription.variantType
        )
      ) {
        currentUserSubscription = sub;
        return {
          ...variant,
          alreadyBought: true,
          url: getFileAccessUrl([
            {
              key: "user-subscription-id",
              value: sub.id,
            },
            {
              key: 'variant-id',
              value: variant.id,
            }
          ]),
        }
      } else if (sub.subscription.issueType !== "ALL_ISSUES" && sub.readableDigitalFiles.find(({ id: varId }) => varId === variant.id)) {
        currentUserSubscription = sub;
        return {
          ...variant,
          alreadyBought: true,
          url: getFileAccessUrl([
            {
              key: "user-subscription-id",
              value: sub.id,
            },
            {
              key: 'variant-id',
              value: variant.id,
            }
          ]),
        }
      }
      return acc;
    }, null)
    return foundVar ? foundVar : subVar ? subVar : variant;
  });

  const boughtVar = productVariants.find(
    ({ alreadyBought }) => alreadyBought
  );
  const unboughtVariants = productVariants.reduce(
    (acc, it) => (it.alreadyBought ? acc : acc.concat(it)),
    []
  );

  if (unboughtVariants && unboughtVariants.length > 0) {
    selectedVariant = {
      ...unboughtVariants[0],
    };
  }

  if (!isAvailable) return <OutOfStock>Out of stock</OutOfStock>;

  return [
    !boughtVar ? "" : <FlatButton
      onClick={e =>
        window.open(
          boughtVar.url,
          "_blank"
        )
      }
      className={`${boughtVar ? "mt-3 mt-lg-5" : ""}`}
    >
      {isVideo ? "Movie: Watch now" : "Digital: Read now"}
    </FlatButton>,
    !isAvailable ? "" : <div className={`${!boughtVar ? "my-3 my-lg-5" : "mt-3"}`}>
      {
        !isVideo &&
        <RadioButtonSet
          selectedId={unboughtVariants.findIndex(
            variant => !variant.alreadyBought
          )}
          selectOption={id => {
            selectedVariant = {
              ...unboughtVariants[id]
            };
          }}
          className="pricing"
        >
          {unboughtVariants.reduce(
            (
              acc,
              {
                id,
                isDigital,
                inrPrice: { localized: localizedInr } = {},
                usdPrice: { localized: localizedUsd } = {},
                alreadyBought,
                inrPricing: {
                  onSale: inrOnSale,
                  price: {
                    net: {
                      localized: inrLocalized
                    }
                  }
                } = {},
                usdPricing: {
                  onSale: usdOnSale,
                  price: {
                    net: {
                      localized: usdLocalized
                    }
                  }
                } = {},
              }
            ) => {
              if (alreadyBought) {
                return acc;
              }
              return acc.concat(
                <PriceWrapper key={id}>
                  {isDigital ? (
                    <div className="medium-name">Digital</div>
                  ) : (
                    <div className="medium-name">Print</div>
                  )}
                  <div className={`price-container ${inrOnSale || usdOnSale ? 'striked' : ''}`}>
                    <div className={`price`}>
                      {
                        getLocalizedAmount({
                          currency,
                          inr: localizedInr,
                          usd: localizedUsd
                        })
                      }
                    </div>
                  </div>
                  {
                    (inrOnSale || usdOnSale) &&
                    <div className={`price-container discounted`}>
                      <div className={`price`}>
                        {
                          getLocalizedAmount({
                            currency,
                            inr: inrLocalized,
                            usd: usdLocalized
                          })
                        }
                      </div>
                    </div>
                  }

                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit"></Typography>
                        <TooltipWrapper>
                          <div className="d-flex flex-row justify-content-end" style={{ visibility: "hidden" }}>
                            <div className="close-tooltip">X</div>
                          </div>

                          <div>
                            <p>
                              {isDigital ? `PDF can be viewed in the online reader by signing into your account on the website. Only one login is allowed at a time. Features of the digital reader include zoom, find in document, multi-view options. Downloading and printing is not supported.`
                                :
                                `Dimensions: 20 cm (width) x 28 cm (height)`}
                            </p>

                          </div>
                        </TooltipWrapper>

                      </React.Fragment>
                    }
                  >
                    {
                      <div className="info-icon-div">
                        <img src={info} className="info-icon mx-1" style={categoryName === "Magazine" && typeof hideSubscription === 'undefined' ? {} : { visibility: "hidden" }} ></img>
                      </div>
                    }

                  </HtmlTooltip>
                </PriceWrapper>
              );
            },
            []
          )}
        </RadioButtonSet>
      }
      {
        unboughtVariants && unboughtVariants.length > 0 &&
        <div className={` ${typeof hideSubscription === 'undefined' ? '' : 'd-flex flex-row justify-content-center'}`} style={{ verticalAlign: 'middle' }} >
          <RaisedButton
            
            onClick={() => {

              let label = name.concat(" - ").concat(selectedVariant.isDigital ? 'Digital' : 'Print');
              ReactGA.event({
                category: 'E-Commerce Action',
                action: 'Added Item to Cart',
                label: label
              });
              saveVariant({
                variant: {
                  ...selectedVariant,
                  product: {
                    id,
                    name,
                    images,
                    thumbnailUrl
                  }
                },
                quantity: DEFAULT_QUANTITY
              });
              FbPixel.track('AddToCart', {
                'content_name': name,
                'content_ids': [id],
                'content_type': 'product',
                'currency': currency,
                'value': currency === "INR" ? selectedVariant.inrPricing.price.net.localized.replace('₹', '') :
                  selectedVariant.usdPricing.price.net.localized.replace('$', '')
              });
              closePopup();

            }}
            className="add-to-bag btn-hover"
          >
            Add To Cart
          </RaisedButton>
          {
            categoryName === "Magazine" && typeof hideSubscription == 'undefined' &&
            <a href="/subscriptions" className="date-issue subscription" >
              <u>SUBSCRIPTION PLANS</u>
            </a>
          }
        </div>


      }


    </div>,
  ];
}

const mapStateToProps = state => ({
  currency: state.auth && state.auth.currency
});

export default connect(mapStateToProps)(ProductPricing);
