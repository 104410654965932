import History from './HistoricalTimeline/timeline';
import { connect } from 'react-redux'

const mapStateToProps = ({
	cmsData: {
		historical,
	}
}, ownProps) => {
	return {
    historical: {
				...historical,
    }
	}
}

export default connect(
  mapStateToProps,
)(History);

