import React from "react";
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 40px;

  .header {
    font-family: ${props => props.theme['$font-secondary-medium']};
  }
`

export const TermsAndConditions = () => (
  <Wrapper>
    <h1 className="header">Terms of Use</h1>
    <p>This Website www.marg-art.org ("<b>Website</b>") is operated by The Marg Foundation("<b>Marg</b>" or "<b>We</b>"), a public trust registered under the Maharashtra Public Trusts Act, 1950 having its registered office at Army &amp; Navy Building, 3rd Floor, 148, Mahatma Gandhi Road, Mumbai 400001, India. Use of the Website and its services is governed by these Terms of Use ("<b>Terms</b>"). By using the Website, you ("<b>you</b>" or "<b>User</b>") signify you have carefully read, understood and agree to be bound by these Terms. When you transact or browse through the Website or avail any services or products provided by the Website, in present or future, you accept and agree to be bound by these Terms, policies, guidelines and conditions of Marg.</p>
    <p>Marg may modify or substitute these Terms without notice at any time. You should periodically review the Terms because they are binding on you.</p>
    <p>These Terms being an electronic record are generated by a computer system and do not require any physical or digital signatures.</p>
    <div className="space15"> </div>
    <p><b>(1) ELIGIBILITY</b></p>
    <p>You should be competent to enter into a legally binding contract. If any person is using the Website on behalf of any corporation, company, institution, association or other entity, the person should be authorized by such corporation, company, institution, association or other entity to use and access the Website.</p>
    <div className="space15"> </div>
    <p><b>(2) MANNER OF USE OF WEBSITE</b></p>
    <p>Without prejudice to and without limiting anything else stated in these Terms, you agree, undertake and confirm that you will not violate any applicable laws or code of conduct. You agree not to: engage in, facilitate or commit any unlawful activity; defame, insult, annoy or denigrate any person, product, institute or service; use the Website in a way that harms Marg or Marg’s partners, associates, distributors, resellers, vendors, advertisers and customers; use any unauthorized third party software, technology or service; or interfere with anyone's use and enjoyment of the Website. You agree to promptly notify Marg if you learn of a security breach related to the Website.</p>
    <div className="space15"> </div>
    <p><b>(3) USER ACCOUNT</b></p>
    <p>You may visit and browse the Website without registering with the Website. You have to set up an account with the Website before availing any services or products provided through the Website. You can create an account by entering an email address and password. The email address provided is not verified by the Website and hence, it is advisable for you to provide a valid email address. The Website will set up an account and email the details of the account to you at the email address provided. You can then access the account on the Website.</p>
    <p>When you create an account with the Website, you represent and warrant that:</p>
    <ol className="ordered-list lower-alpha">
    <li>all required information ("<b>User Information</b>") you submit is truthful, accurate, current and complete; and</li>
    <li>you will maintain the accuracy currency and completeness of such information. You undertake not to impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with any person or entity. All activities that occur using your password are your responsibility, whether or not authorized by you. You agree to immediately notify us of any unauthorized use of your password or registered account. If you use this Website, you are responsible for maintaining the confidentiality of your account, password and for restricting access to your computer and device and you agree to accept responsibility for all activities that occur under your account. Marg reserves the right to refuse or terminate services, terminate accounts, remove or edit content, or cancel orders in its sole discretion. Marg cannot and will not be liable for any loss or damage arising from your failure to comply with the requirements of these Terms.</li>
    </ol>
    <p>You can create one user account only and you are not permitted to transfer or interchange the user account. The list of User Information required to be provided by you, the manner of usage, protection and confidentiality of User Information are more specifically dealt with under the Website’s Privacy Policy and you are requested to read in detail the Privacy Policy before sharing information or creating an account.</p>
    <div className="space15"> </div>
    <p><b>(4) USE RESTRICTIONS</b></p>
    <p>All content and material on this Website is the property of Marg or its content suppliers and all rights including the copyright in the content and material on this Website vests in Marg or its content suppliers. Marg provides you limited access to the Website for personal use only. This Website or any portion of this Website shall not be copied, reproduced, distributed, republished, modified, downloaded, displayed, posted, transmitted, communicated to the public or otherwise exploited in any form or manner or by any means, including, but not limited to, electronic, digital, mechanical, photocopying, recording, or otherwise, without the prior authorization of Marg or the copyright owner. You shall not, without the permission of Marg, "mirror" any material contained on this Website on any other server. Any unauthorized use of any material contained on this Website may violate copyright laws, trademark laws, and/or other applicable laws, rules and regulations.</p>
    <div className="space15"> </div>
    <p><b>(5) USERS COMMUNICATIONS AND INFORMATION</b></p>
    <p>Any material, information or content you transmit to or post or upload or submit on this Website ("<b>User Content</b>") by any means will be treated as non-confidential and non-proprietary, and may be disseminated, used, published or exploited by Marg or its partners for any purpose whatsoever, including, but not limited to, developing, creating and marketing products. Notwithstanding the foregoing, all personal data provided to Marg will be handled in accordance with our Privacy Policy. You are prohibited from posting or transmitting to or from this Website any unlawful, objectionable, threatening, libellous, defamatory, obscene, scandalous, inflammatory, pornographic or profane material, or any other material that could give rise to any civil or criminal liability under any applicable law. Marg disclaims all liabilities arising in relation to such User Content on the Website. Any User if exposed to such User Content as aforesaid is requested to immediately report the same to Marg in the manner provided under these Terms and Marg will endeavor to remove such User Content as soon as possible after investigation.</p>
    <p>You represent and warrant that you own or otherwise control all of the rights to the User Content and that the User Content:</p>
    <ol className="ordered-list lower-roman" type="i">
    <li>is true, accurate, current and complete information, or</li>
    <li>does not infringe any copyright trademark, trade secret, patent or other proprietary rights of any third parties, or</li>
    <li>does not contain any software, virus or other computer code, files or programmes designed to interrupt, destroy or limit the functionality of the Website, any computer software, hardware or telecommunication equipment, or</li>
    <li>does not threaten the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states or public order or cause any incitement, or</li>
    <li>does not cause injury to any person or entity; and that you will indemnify Marg for all claims resulting from any content that you supply or any loss or damage caused to Marg or any third party due to your act or omission. Marg has the sole right but not the obligation to monitor any activity or remove any content at any time without notice. Marg takes no responsibility and assumes no liability for any content transmitted, posted, uploaded or submitted by you or any third party.</li>
    </ol>
    <p>You grant Marg and its associates and partners non-exclusive, royalty-free, irrevocable, perpetual and fully sub-licensable rights to use, reproduce, publish, distribute and display all User Content throughout the world in any mode, medium or manner now known or developed in future.</p>
    <p>You waive all rights to be identified as the author of User Content and materials and your right to object to derogatory treatment of such content or material. You agree to perform all further acts and execute deeds and documents necessary to perfect the above rights granted by you to Marg.</p>
    <p>Marg reserves the right, at any time, to modify, suspend, or discontinue the Website or any magazine, book, event, offer, film or article or any part thereof with or without notice. You agree that Marg will not be liable to you or to any third party for any modification, suspension, or discontinuance of the Website or any magazine, book, event, offer, film or article or any part thereof.</p>
    <p>You acknowledge that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Website are owned by Marg or its content suppliers. The provisions of the use of the Website do not transfer to you or any third party any rights, title or interest in or to such intellectual property rights. Marg and its suppliers reserve all rights not granted in these Terms.</p>
    <div className="space15"> </div>
    <p><b>(6) FEEDBACK</b></p>
    <p>If you provide Marg any feedback or suggestions regarding the Website ("Feedback"), you hereby assign to Marg all rights in the Feedback and agree that Marg shall have the right to use such Feedback and related information in any manner it deems appropriate. Marg will treat any Feedback you provide to Marg as non-confidential and non-proprietary. You agree that you will not submit to Marg any information or ideas that you consider to be confidential or proprietary.</p>
    <div className="space15"> </div>
    <p><b>(7) INFRASTRUCTURE</b></p>
    <p>To use the Website, you require internet connectivity or appropriate telecommunication links and the Website’s services do not include the provision of any computer system, resource, terminal, server, equipment, mobile or any devices. The Website shall not have any responsibility or liability for any computer system, resource, terminal, server, equipment, mobile or any devices necessary for you to use and access the Website nor shall be liable for any costs incurred by you on account of internet or appropriate telecommunication links usage.</p>
    <p>Marg would not be liable for damages, viruses or other code that may affect your computer system, resource, terminal, server, equipment, mobile or any other devices while using the Website and Marg does not ensure that the Website will be compatible with all hardware and software used by you.</p>
    <p>You must comply with applicable third party terms, if any, such as mobile device agreement or wireless data service agreement, etc. while using the Website.</p>
    <div className="space15"> </div>
    <p><b>(8) LINKS</b></p>
    <p>The Website may contain links to other sites. The linked websites are not necessarily under the control of Marg. Please be aware that Marg is not responsible for the terms of use or privacy practices of such other websites. Marg encourages you to be aware when you leave this Website and to read the privacy policies of each and every linked website that collects your personally identifiable information. If you decide to access any of the third-party websites linked to this Website, you do so entirely at your own risk. Any links to any associate or partner of the Website is the responsibility of the linking party, and Marg shall not be responsible for notification of any change in name or location of any information on the site or liable for any actions, claims, losses or damages arising thereto.</p>
    <div className="space15"> </div>
    <p><b>(9) INDEMNITY</b></p>
    <p>You agree to indemnify and hold Marg (and its associates and partners and their respective officers, trustees, employees, contractors, suppliers and agents) saved, protected, harmless and indemnified from any claim, action or demand including costs and attorneys' fees, due to or arising out of</p>
    <ol className="ordered-list lower-alpha">
    <li>your use of the Website,</li>
    <li>any content or material provided by you,</li>
    <li>your violation of the Terms of Use or Privacy Policy; or</li>
    <li>your violation of applicable laws or regulations, or</li>
    <li>defamation or trade disparagement or other wrongful acts. Marg reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of Marg. Marg will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</li>
    </ol>
    <div className="space15"> </div>
    <p><b>(10) COMPLIANCE WITH APPLICABLE LAWS</b></p>
    <p>This Website may be accessible from places that are out of the geographical boundaries of the Republic of India. Marg makes no representation that the material available on the Website is appropriate for use in other locations. Visitors and users that access the Website from locations beyond the geographical boundaries of India do so at their own risk, and shall be responsible for compliance with the laws of India and the laws of their local jurisdiction.</p>
    <div className="space15"> </div>
    <p><b>(11) DISCLAIMER OF WARRANTY</b></p>
    <ol className="ordered-list lower-roman">
    <li>The use of the Website and the material is at your own risk. Changes are periodically made to the Website and may be made at any time.</li>
    <li>The Website and the information, content, material, books, magazines, articles, films products and services included on or otherwise made available to you through this Website are provided on an "as is" basis and "as available" basis without any warranties of any kind. Marg and its associates and partners, to the fullest extent permitted by law, disclaim all warranties, including the warranty of merchantability, non-infringement of third parties rights, warranty of fitness for a particular purpose, uninterrupted or timely availability of the Website or Marg products, materials, books, magazines, articles, films, products and services.</li>
    <li>Marg does not warrant that the Website, information, content, materials, services included on or otherwise made available to you through this Website, will be accurate, reliable, free of viruses or other harmful code, complete, or safe. Any errors or omission that are discovered in the books, magazines, articles, films or products will be published in an errata or be rectified in a reprint but Marg will not be responsible for any loss or damage due to any such errors or omission. Due to the inherent nature of the internet, Marg cannot guarantee that information, during transmission through the internet or while stored on our system or otherwise in our care, will be absolutely safe from intrusion by others, such as hackers. Marg assumes no liability for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, any User Information or User Content. Marg is not responsible for any problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software or failure of any email due to technical problems or traffic congestion on the internet or on the Website, including any injury or damage to you or to any person's computer related to or resulting from participation in any activities on the Website or downloading materials. You understand that if you download any material, you do so at your sole risk and under no circumstances shall Marg be responsible for any loss or damage, including personal injury or death, resulting from use of the Website or its products, any content posted on or through the Website, or conduct of any users of the Website, whether online or offline.</li>
    <li>The Website hereby disclaims any guarantees of exactness as to the finish and appearance of the book, magazine, film or product as ordered by and delivered to you. The quality or quantity of any book, magazine, film or products, information or other material purchased or obtained by you through the Website may not meet your expectations.</li>
    <li>The Website could also be linked to third party websites and Marg assumes no responsibility for results obtained from the use of these websites.</li>
    <li>When you delete any kind of information you must understand that the removed content may continue in backup copies.</li>
    <li>Marg will not be liable for any loss that you may incur as a consequence of unauthorized use of the user account or User Information in connection with the Website or any services or materials, either with or without your knowledge. Marg shall not be liable for any third party product, the advertisements available on e-mail or Website with respect to the third party website and the products and services are for information purposes only.</li>
    </ol>
    <div className="space15"> </div>
    <p><b>(12) LIMITATION OF LIABILITY</b></p>
    <p>In no event shall Marg, its associates and partners and their respective officers, trustees, employees, suppliers, contractors and agents be liable for any losses or damages whatsoever including without limitation, direct, indirect, punitive, special, incidental, remote and consequential damages, lost profits, goodwill, other intangible losses or damages resulting from the use or inability to use the Website, its information, content, materials, books, magazines, articles, films, products, services; unauthorized access to or alteration of your data; statements or conduct of any third party; or in any other matter, whether based on warranty, contract, tort, or any other legal theory, and whether or not Marg is advised of the possibility of such damages.</p>
    <p>Without prejudice, it is agreed by you that the Website including associates, partners and their respective officers, trustees, employees, suppliers, contractors and agents shall not be held cumulatively liable for any damages or claims in excess of INR 1,000/- (Rupees One Thousand) or the last payment made by you; whichever is lesser, subject to applicable law.</p>
    <div className="space15"> </div>
    <p><b>(13) TERMINATION</b></p>
    <p>Marg reserves the right to terminate the services provided to you without prior notice in the event of any default committed by you including but not limited to the breach of any applicable law, policies, guidelines and conditions of the Website or these Terms or the Privacy Policy (including any modifications or additional notifications or instructions). On such termination, the User Information, User Content and other details shall be blacklisted by the Website and shall be taken into consideration whenever necessary.</p>
    <p>Marg will terminate the services provided to you if it is required under any applicable law. Without prejudice, Marg reserves its right to terminate the services if such provision of services to you is not economically viable for Marg. You may choose to discontinue availing services and facilities provided through the Website by closing your account with the Website.</p>
    <p>On closure of your account or termination of services you are not eligible to purchase our products through the Website. Once your account is closed or services terminated for any reason whatsoever, the User Contents posted on the Website by you shall be removed by the Website.</p>
    <p>It is clarified that certain terms and conditions of the policies, guidelines and conditions of the Website’s services or these Terms and the Privacy Policy continue to be binding on you and if you by default or otherwise are found using or dealing with the Website post termination of the service provided through the Website, you shall be prosecuted by Marg. You shall be also liable to compensate Marg for any unauthorized use of the Website.</p>
    <div className="space15"> </div>
    <p><b>(14) PRIVACY POLICY</b></p>
    <p>Our Privacy Policy is incorporated herein by reference and shall be deemed to be part of the Terms.</p>
    <div className="space15"> </div>
    <p><b>(15) COPYRIGHT POLICY</b></p>
    <p>If you believe your copyright has been infringed, you may provide us with notice. To be effective, the notification must be a written communication that includes the following:</p>
    <ul className="bullet-list">
    <li>A physical or electronic signature of the person authorized to act on behalf of the owner or exclusive licensee of the copyright that is allegedly infringed;</li>
    <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works are covered by a single notification, a representative list of such works;</li>
    <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;</li>
    <li>Information reasonably sufficient to permit us to contact you, including an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted;</li>
    <li>A statement that the complaining party has a good-faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
    <li>A statement that the information in the notification is accurate.</li>
    </ul>
    <p>You shall not make any untrue or false information or allegation and if you are found misusing the notifying procedure, Marg reserves the right to terminate your account and services and/or take appropriate legal actions.</p>
    <p>Upon receipt of a notice, Marg will after investigations if deemed appropriate take steps for removing the infringing or objectionable content all of which are taken without any admission as to liability and without prejudice to any rights, remedies or defenses, all of which are expressly reserved. Marg shall also endeavour to forward a copy of any notice to the alleged infringer or defaulter. You agree to indemnify Marg for all claims brought by a third party against us as a result of or arising out of or in connection with your notice.</p>
    <p>You agree that Marg’s role and responsibility is completed on blanking or taking down the infringing or objectionable content as alleged in the notice or upon notifying the alleged infringer, as the case may be and Marg shall in no manner be liable for or in relation to the infringing or objectionable content. Marg shall in no manner be involved in trying, contesting, mediating, negotiating or conciliating the dispute or differences between the person who notifies the objections and the person against whom the objections are raised.</p>
    <div className="space15"> </div>
    <p><b>(16) TRADEMARKS</b></p>
    <p>The trademarks, service marks, brand names, domain names and logos (including all translations and variations thereto) used and displayed on this Website are registered and/or unregistered trademarks of Marg. Nothing on this Website should be construed as granting, by implication, estoppel, or otherwise, any licence or right to use or exploit in any manner whatsoever, any trademarks, service marks, brand names, logos or domain names of Marg whether or not displayed on the Website and whether or not registered, without the written permission of Marg. The Website may display certain trademarks belonging to third parties. Use of these trademarks may be subject to licence granted by third parties to Marg. You shall, in no event use, such trademarks and nothing herein shall be construed to grant you any right in relation to such trademarks.</p>
    <div className="space15"> </div>
    <p><b>(17) MERGER, ACQUISITION OR DISSOLUTION</b></p>
    <p>Information collected from you may be shared or transferred to a third party as a result of merger, acquisition or dissolution of Marg.</p>
    <div className="space15"> </div>
    <p><b>(18) FORCE MAJEURE </b></p>
    <p>Marg shall not be liable for delays or inabilities in performance or nonperformance in whole or in part of its obligations due to any causes that are beyond its reasonable control, such as acts of God, fire, flood, explosion, civil commotion, strikes, lock outs or industrial action of any kind, riots, insurrection, war, embargo or acts of government, change in government policy, orders of court or any other binding authority, power failure, sabotage, computer hacking, unauthorized access to computer data and storage device, system failure, virus, attacks, bugs, computer crashes, breach of security and encryption and other similar events.</p>
    <div className="space15"> </div>
    <p><b>(19) HEADINGS</b></p>
    <p>The headings herein are included for convenience and identification only and are not intended to describe, interpret, define or limit the scope, extent or intent of these Terms of Use. In the event that any of the terms, conditions and notices contained herein conflict with Privacy Policy then these Terms of Use shall control.</p>
    <div className="space15"> </div>
    <p><b>(20) SEVERABILITY</b></p>
    <p>Each of the provisions in these Terms are severable and distinct from the others and if at any time one or more such provisions is or becomes invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions of these Terms shall not in any way be affected or impaired thereby.</p>
    <div className="space15"> </div>
    <p><b>(21) GOVERNING LAW AND JURISDICTION</b></p>
    <p>We control and operate the Website from our offices in Mumbai, India. We do not represent that materials on the Website are appropriate for use in other locations. If you choose to access the Website from other locations you do so on your own initiative, and you will be responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
    <p>The Terms of Use, and the relationship between you and us, shall be governed by the laws of India. You agree that any cause of action that may arise under the Terms shall be commenced and be heard only in the appropriate courts in Mumbai, India. You agree to submit to the exclusive jurisdiction of the courts located within Mumbai in the State of Maharashtra, India. Our failure to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or provision.</p>
    <div className="space15"> </div>
    <p><b>(22) NOTICES</b></p>
    <p>You agree that Marg may communicate with you through your account or through other means including email or mobile. You are required to update your account settings regularly to ensure that all notices and communications from Marg are received by you, timely and accurately.</p>
    <p>For purposes of service messages and notices about the services to you, the Website may place a banner notice across its pages to alert you to certain changes such as modifications to these Terms of Use. Alternatively, notice may consist of an email from the Website to you. You agree that the Website may communicate with you through the user account or through other means including email or mobile. You are required to update your account settings regularly to ensure that the Website’s service messages and notices are received by you timely and accurately.</p>
    <p>The Marg Foundation including associates and partners and their respective officers, trustees, employees, suppliers, contractors and agents shall have no liability for the non receipt of the Website’s service messages and notices.</p>
    <p>In case of any complaints or grievances, you may contact The Marg Foundation at the following address: <br /> The Grievance Officer, The Marg Foundation, <br /> Army &amp; Navy Building, 3rd Floor, <br /> 148, Mahatma Gandhi Road, <br /> Mumbai 400001, India. <br /> E-mail Id: <a className="txt_666" href="mailto:foundation@marg-art.org"><u>foundation@marg-art.org</u></a></p> 
    <h1 className="header">Terms of Use</h1>
    <p><b>Terms and Conditions of Sale</b></p>
    <p>The Terms and Conditions of Sale ("<b>Terms of Sale</b>") detail the terms on which you may subscribe to or purchase print versions of books, magazines, and films or subscribe to or purchase digital versions of magazines and magazine articles ("<b>Product</b>"/"<b>Products</b>") from The Marg Foundation through our Website at www.marg-art.org ("<b>Website</b>").</p>
    <p>In ordering any Products, you agree to be bound by our Terms of Sale, Terms of Use and Privacy Policy. The Terms of Use and Privacy Policy shall be deemed to be part of the Terms of Sale.</p>
    <p>Prior to placing an order for any Products you must click on a check box to declare that you have read and accept the Terms of Sale. Your acceptance would constitute this to be a legal, valid and binding Contract between The Marg Foundation and you.</p>
    <p>Please understand that if you refuse to accept these Terms of Sale, you will not be able to order any Products from our Website. In such a case you could send your comments or queries to The Marketing and Sales Department, email: <a className="txt_666" href="mailto:foundation@marg-art.org"><u>foundation@marg-art.org</u></a> to know more about other means and methods of ordering our Products.</p>
    <p>Prior to placing any order with us and for future reference, you should print a copy of and carefully consider the Terms of Sale, Terms of Use and Privacy Policy.</p>
    <p>These Terms of Sale being an electronic record are generated by a computer system and do not require any physical or digital signatures.</p>
    <p>Any information contained in this Website is not intended to advertise or solicit any of our Products in any manner whatsoever.</p>
    <div className="space15"> </div>
    <p><b>(1) DEFINITION OF TERMS</b></p>
    <p>The "Seller", "Marg" or "We" shall mean The Marg Foundation, a public trust registered under the provisions of the Maharashtra Public Trusts Act, 1950, and having its registered office at Army &amp; Navy Building, 3rd Floor, 148, Mahatma Gandhi Road, Mumbai 400001, India, and includes its successors and assigns.</p>
    <p>The "Buyer" or "You" shall mean you as the other party to this Contract, your heirs, legal representatives, successors, and permitted assigns.</p>
    <div className="space15"> </div>
    <p><b>(2) ORDER CONFIRMATION AND PAYMENT TERMS</b></p>
    <ol className="ordered-list lower-alpha">
    <li>The order submitted will be confirmed by the Seller through the Website or by email. If the Buyer does not receive an order confirmation, the Buyer can contact us before trying to place another order for the same Product.</li>
    <li>Terms of payment are within the Seller's sole discretion, and unless otherwise agreed to by it, payment must be received by the Seller prior to dispatch of an order. Payment for the Products will be made by credit card, or some other payment method notified on the Seller’s Website. If the Buyer’s initial payment authorization is later revoked, the Buyer’s subscription or purchase of the Products, or access will be terminated.</li>
    <li>Products’ price does not include delivery cost, excise, customs, octroi, or other duties, taxes on sale, purchase, assignments, levies, or additional expenses occasioned by causes beyond the Seller's control (including statutory impositions) or by Force Majeure Event. Applicable taxes may vary. The Seller is not able to notify the Buyer in advance of changes in applicable taxes.</li>
    <li>If a stated price for a Product is determined by the Seller in its sole discretion to be in error, the Seller is not under any obligation to offer the Buyer the subscription or Product at that price. The Seller will notify the Buyer of the error and give the Buyer the opportunity to cancel its order and obtain a refund if payment already has been made.</li>
    <li>The Seller reserves the right to change prices and fees at any time.</li>
    <li>Discount eligibility is determined at the time of placement of the order for the Products. Discounts cannot be applied with retrospective effect.</li>
    <li>If the Buyer believes someone else has used its account or the Buyer is being charged for a Product it did not purchase or subscribe to, the Buyer must contact Customer Service by e-mail at <a className="txt_666" href="mailto:foundation@marg-art.org"><u>foundation@marg-art.org</u></a>.</li>
    <li>By subscribing to or purchasing a Product, the Buyer submits that all information provided during the order process is true and correct (including all credit card information), and that it is the authorized holder of the credit card.</li>
    <li>The Seller reserves the right to reject any order or purchase at any time. <br /> The Buyer will be notified in advance of upcoming renewals. Digital Product subscribers will be sent a renewal reminder at the end of the cycle.</li>
    <li>When a Buyer uses the digital Product on a mobile device or a computer, it may incur charges from its service provider, such as telecommunications fees or data fees. These fees are not included in the price, and the Buyer is solely responsible for paying them separately. The Seller will accept no liability in respect of any additional costs under any circumstances.</li>
    </ol>
    <div className="space15"> </div>
    <p><b>(3) GIFT SUBSCRIPTIONS</b></p>
    <p>The Seller allows subscriptions to be purchased as gifts. The person responsible for payments and fees is the person who initially purchased the gift subscription and only that person may receive any refunds or other payments that may be due in respect of the gift subscription.</p>
    <p>These Terms of Sale will apply to the beneficiary of any gift subscription, who may use the Website and access or receive delivery of our Products in accordance with these Terms of Sale. All references to "Buyer" or "You" (other than in relation to renewal, payment, fees, refunds and cancellations) shall be read as references to the beneficiary of the gift subscription.</p>
    <div className="space15"> </div>
    <p><b>(4) DELIVERY</b></p>
    <ol className="ordered-list lower-alpha">
    <li>By placing an order for purchase or subscription for a digital Product, the Buyer agrees that the Seller may start its digital subscription or deliver its paid Product immediately and the Buyer acknowledges that it will lose its right to a refund in relation to any issue that has been published or delivered before the Buyer cancels the order.</li>
    <li>Printed Products shall be delivered within approximately fifteen working days at the address provided. However, time of delivery is not guaranteed and will be extended by any delays occasioned by causes beyond the Seller's control, including but not limited to a Force Majeure Event.</li>
    <li>Delivery may be in one lot or in several lots at the Seller's option.</li>
    <li>The Buyer shall inspect the printed Products immediately on arrival and shall within 7 (seven) days from date of their delivery give the Seller notice of any matter or thing by reason whereof it may allege that the Products are not in accordance with the Contract. If the Buyer fails to give such notice within 7 (seven) days as aforesaid, the Buyer shall not be entitled thereafter to put in any claim against the Seller in respect thereof.</li>
    </ol>
    <div className="space15"> </div>
    <p><b>(5) PROPERTY</b></p>
    <p>Notwithstanding anything to the contrary in any of the terms and conditions governing this Contract, with the delivery of the Products, the property in the Products will vest in the Buyer unless otherwise expressly stipulated.</p>
    <p>The Buyer shall not assist third parties in accessing digital subscriptions or paid Products on an unauthorized basis, including by sharing the Buyer’s access credentials or providing any content or other materials that the Buyer obtained through a digital subscription or purchase of the Products.</p>
    <p>In the event of any fraudulent, abusive, or otherwise illegal activity relating to the Buyer’s digital subscription or Products purchased, the Seller reserves the right to, in its sole discretion, terminate those accounts and refer the Buyer to appropriate law enforcement agencies, without any prior notice. The Buyer will be solely responsible for any loss or damage arising from any such fraudulent, abusive, or otherwise illegal activity.</p>
    <div className="space15"> </div>
    <p><b>(6) MOBILE ACCESS AND JURISDICTIONAL AVAILABILITY</b></p>
    <p>Some portions or components of digital subscriptions or other digital Products may be accessible only using a particular kind of device – for example, an Apple iPad. The Buyer should investigate digital subscriptions or purchase of digital Products before the Buyer pays for access to them to ensure that they will work with the Buyer’s device. The Seller does not refund any fees the Buyer paid if the digital subscription or paid digital Product ordered by the Buyer is not compatible with its device.</p>
    <p>Some components of digital subscriptions or paid digital Products may not be available for use in every jurisdiction, and we will not refund any fees you paid if the digital subscription or paid digital Products you ordered are not available. For information on compatibility and availability, please visit the applicable supplier or provider’s website or contact us at <a className="txt_666" href="mailto:foundation@marg-art.org"><u>foundation@marg-art.org</u></a> before you subscribe or order.</p>
    <div className="space15"> </div>
    <p><b>(7) DISCLAIMER OF WARRANTIES</b></p>
    <p>The Seller makes no express warranties except those stated in these Terms of Sale. The Seller disclaims all warranties, express or implied, including without limitation, implied warranties of merchantability and fitness for a particular purpose.</p>
    <p>The Seller reserves the right to make changes to digital or print Products, without any prior warning or notice, at any time. If the Seller temporarily reduces or eliminates the charge for content or access that the Buyer is currently paying for under different terms, the Buyer will not be eligible for a refund.</p>
    <div className="space15"> </div>
    <p><b>(8) LIMITATION OF LIABILITY</b></p>
    <p>Under no circumstances shall the Seller or its partners, associates, suppliers, vendors, officers, trustees, agents, employees and contractors be liable for any losses or damages whatsoever including without limitation, direct, indirect, punitive, special, incidental, remote and consequential damages, loss of profits, goodwill, other tangible losses or damages whatsoever arising out of or resulting from the use of the Products or inability to use the Products; unauthorized access to your registered account or password; any other matter, whether based on warranty, contract, tort, or any other legal theory; or otherwise whatsoever in respect of this Contract.</p>
    <p>Without prejudice, it is agreed by you that the Seller including its partners, associates, suppliers, vendors, officers, trustees, employees and contractors shall not be held cumulatively liable for any damages or claims in excess of a sum equal to the amount paid or payable by the Buyer for the subscriptions or purchases in respect of one incident or series of incidents attributable to the same cause.</p>
    <div className="space15"> </div>
    <p><b>(9) EXCLUSIONS AND LIMITATIONS</b></p>
    <p>The Buyer acknowledges, agrees and confirms that the disclaimers and exclusions of liability set forth in sections 7 and 8 represent a fair and reasonable allocation of the risks and benefits of the Contract between the Buyer and Seller, taking all relevant factors into consideration, including without limitation the value of the consideration provided by the Buyer and Seller. The Buyer further agrees that these disclaimers and limitations shall be enforceable to the fullest extent permitted under the applicable law.</p>
    <div className="space15"> </div>
    <p><b>(10) EXPORT-IMPORT</b></p>
    <p>The Buyer shall be solely responsible for compliance of all laws, rules and regulations for import or export of Products within the Buyer's local jurisdiction and payment of any duties, taxes and charges levied or charged. The Seller has no knowledge or control over these duties, taxes and charges that may be levied on the Buyer in the Buyer's local jurisdiction and cannot predict their amount. The Buyer undertakes to contact its local authorities for any further information prior to placing an order for the Seller's Products.</p>
    <div className="space15"> </div>
    <p><b>(11) NOTICES AND COMMUNICATION</b></p>
    <p>When using the Seller's Website, the Buyer accepts that communication with the Buyer will be mainly in electronic form. For contractual purposes, the Buyer agrees to this electronic means of communication and acknowledges that all contracts, notices, information and other communications that the Seller provides to the Buyer electronically at the email address provided to the Seller, complies with any legal requirement that such communications be in writing.</p>
    <div className="space15"> </div>
    <p><b>(12) LINKS</b></p>
    <p>The Buyer agrees and understands that in ordering any Products, the Buyer may decide to access third-party websites including partner and associate websites linked to the Seller's Website. The Buyer undertakes that it shall read the privacy policies and terms and conditions of such websites after which the Buyer shall decide to access any third-party sites linked to this Website, entirely at the Buyer's own risk. Any links to any partner and associate of the Website is the responsibility of the linking party, and the Seller shall not be responsible for notification of any change in name, location of any information on the site and not liable for any actions, claims, losses or damages arising thereto.</p>
    <div className="space15"> </div>
    <p><b>(13) COMPLIANCE WITH APPLICABLE LAWS</b></p>
    <p>This Website may be accessible from places that are out of the geographical boundaries of the Republic of India. Marg makes no representation that the content or material available on the Website is appropriate for use in other locations. Visitors and users that access the Website from locations beyond the geographical boundaries of India do so at their own risk, and shall be responsible for compliance with the laws of India and the laws of their local jurisdiction.</p>
    <div className="space15"> </div>
    <p><b>(14) INDEMNITY</b></p>
    <p>The Buyer hereby agrees to indemnify and hold the Seller, its trustees, employees and partners, safe and harmless from, against and in respect of all actions, notices, claims, allegations, suits, investigations, judgments, settlements, demands, losses, damages, expenses, costs, liabilities or other proceedings of whatsoever nature (including attorney’s fees) arising from: (i) the Buyer's use of and access of the Website; (ii) violation by the Buyer of any term of these Terms of Sale; (iii) the Buyer’s violation of third party rights; (iv) breach of any applicable laws.</p>
    <div className="space15"> </div>
    <p><b>(15) EVENTS BEYOND SELLER'S CONTROL</b></p>
    <p>The Seller will not be liable or responsible for any failure to perform, or delay in performance of, any of its obligations under any Contract, that is caused by events beyond the Seller's reasonable control or a Force Majeure Event.</p>
    <p>A Force Majeure Event includes any act, event, non-happening, omission or accident beyond the reasonable control of the Seller and includes (without limitation)</p>
    <ol className="ordered-list lower-alpha">
    <li>strikes, lock-outs or other industrial action;</li>
    <li>civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war;</li>
    <li>fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster;</li>
    <li>impossibility of the use of railways, shipping, aircraft, motor transport or other means of public or private transport;</li>
    <li>impossibility of the use of public or private telecommunications networks;</li>
    <li>technical failures, server problems, virus attacks;</li>
    <li>the acts, decrees, legislation, regulations or restrictions of any government.</li>
    </ol>
    <p>The Seller's performance under any Contract with the Buyer is deemed to be suspended for the period that the Force Majeure Event continues, and the Seller will have an extension of time for performance for the duration of that period.</p>
    <div className="space15"> </div>
    <p><b>(16) NO THIRD-PARTY BENEFICIARIES</b></p>
    <p>The Buyer agrees that, except as otherwise expressly provided in these Terms of Sale, the Buyer shall not be entitled to have any third-party beneficiaries to this Contract.</p>
    <div className="space15"> </div>
    <p><b>(17) GENERAL INFORMATION</b></p>
    <ol className="ordered-list lower-alpha">
    <li><b><i>Entire Agreement.</i></b> These Terms of Sale constitute the entire agreement between the Seller and the Buyer and supersedes any prior understandings and agreements between them on the subject matter of the Contract. The Buyer may also be subject to additional terms and conditions that may apply to the Buyer when using the Seller-partner and associate services.</li>
    <li><b><i>Waiver and Severability of Terms.</i></b> The failure of the Seller to exercise or enforce any right or provision of these Terms of Sale shall not constitute a waiver of such right or provision. If any provision of these Terms of Sale is found by a court of competent jurisdiction to be invalid, such provision shall be severed from the Contract and the other provisions of the Terms of Sale remain in full force and effect.</li>
    <li><b><i>Headings.</i></b> The headings in these Terms of Sale are included for convenience and identification only and are not intended to describe, interpret, define or limit the scope, extent or intent of these Terms of Sale.</li>
    <li><b><i>Severability.</i></b> Each of the provisions in these Terms of Sale are severable and distinct from the others and if at any time one or more such provisions is or becomes invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions of these Terms of Sale shall not in any way be affected or impaired thereby.</li>
    </ol>
    <div className="space15"> </div>
    <p><b>(18) GOVERNING LAW AND JURISDICTION</b></p>
    <p>The Terms of Sale (including the documents referred to and thus incorporated herein) are governed solely and exclusively by the laws of India. The Courts in Mumbai only shall have the exclusive jurisdiction in respect hereof.</p>
    <div className="space15"> </div>
    <p><b>(19) CHANGE OF TERMS OF SALE</b></p>
    <p>The Seller may change these Terms of Sale without notice to the Buyer. The Seller recommends that the Buyer visits these Terms of Sale from time to time to understand and be fully aware of any changes.</p>
    <div className="space15"> </div>
    <p><b>(20) THIRD PARTIES</b></p>
    <p>If the Buyer has purchased or subscribed to a Product offline or through a third party, these Terms of Sale may not apply to it. The Seller is not liable to the Buyer for any claims related to purchases or subscriptions made through third parties. Please contact the third party directly which would include application providers or suppliers.</p> 
  </Wrapper>
)