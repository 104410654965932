import React, { Component } from "react";
import gql from "graphql-tag";
import { RaisedButton } from "./../../commons/";
import styled from "styled-components";
import logo from "./../../../images/logo.png";
import AddressBox from "../CheckoutAddress/AddressBox";
import { Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import FbPixel from "../../../utils/fbPixel";


const SAVE_PAYMENT = gql`
  mutation SavePayment($checkoutId: ID!, $input: PaymentInput!) {
    checkoutPaymentCreate(checkoutId: $checkoutId, input: $input) {
      errors {
        field
        message
      }
      checkout {
        id
      }
    }
  }
`;

const CREATE_DRAFT_ORDER = gql`
  mutation CreateDraftOrder($checkoutId: ID!) {
    createDraftOrderForCustomer(checkoutId: $checkoutId) {
      errors {
        field
        message
      }
      razorpayOrderId
    }
  }
`;

const COMPLETE_CHECKOUT = gql`
  mutation CompleteCheckout($checkoutId: ID!) {
    checkoutComplete(checkoutId: $checkoutId) {
      errors {
        field
        message
      }
      order {
        id
        visibleOrderId
        fulfillments {
          fulfillmentOrder
          status
          trackingNumber
          shippingDate
          statusDisplay
        }
        statusDisplay
        lines {
          quantity
          variant {
            id
            product {
              id
              name
            }
          }
        }
        shippingAddress {
          id
          firstName
          lastName
          streetAddress1
          streetAddress2
          city
          cityArea
          postalCode
          country {
            country
            code
          }
          countryArea
          phone
        }
        totalInr{
          net{
            amount
            currency
            localized
          }
        }
        totalUsd{
          net{
            amount
            currency
            localized
          }
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  padding: 20px;
  & > div {
    & > .under-progress {
      font-size: 40px;
      font-weight: bold;
    }

    & > .success {
      & > .header {
        font-size: 40px;
      }
    }

    & > .failure {
      font-size: 40px;
    }
  }
`;

export default class CheckoutPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: "",
      shippingAddress: {},
      totalPrice: {},
      status: ""
      //PAYMENT_STARTED, PAYMENT_SUCCESS, PAYMENT_FAILURE, PAYMENT_PERSISTED, PAYMENT_PERSISTENCE_FAILURE
    };
    
    this.initiatePayment = this.initiatePayment.bind(this);
    this.resetStatus = this.resetStatus.bind(this);
  }

  resetStatus() {
    this.setState({
      status: ""
    });
  }

  componentWillMount() {
    this.updatePaymentOptions();
  }

  componentDidUpdate() {
    this.updatePaymentOptions();
  }

  updatePaymentOptions() {
    const self = this;
    const {
      email,
      currency,
      cart: {
        id,
        lines,
        totalPrice: {
          net: { amount }
        },
        totalQuantity,
        email: cartEmail,
        shippingAddress: {
          firstName,
          lastName,
          phone,
        } = {},
      } = {}
    } = this.props;
    const contentIds = lines.map((line) => line.variant.product.id);
    const contents = lines.map(
      (line) => ({
        "id": line.variant.product.id,
        "quantity": line.quantity,
      })
    );
    this.options = {
      key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
      //TODO: use this in .env file.
      name: "Marg",
      image: logo,
      handler: function(response) {
        FbPixel.trackCustom('PublicationPurchaseSuccess', {
          'product_type': 'publication',
          'content_type': 'product',
          'content_ids': contentIds,
          'contents': contents,
          'currency': currency, 
          'value': amount,
          'num_items': totalQuantity
        });
        FbPixel.track('Purchase', {
          'product_type': 'publication',
          'content_type': 'product',
          'content_ids': contentIds,
          'contents': contents,
          'currency': currency, 
          'value': amount,
          'num_items': totalQuantity
        });
        self.setState({
          status: "PAYMENT_SUCCESS"
        });
        ReactGA.event({
          category: 'E-Commerce Action',
          action: `Razorpay cart payment complete`,
          label: response.razorpay_payment_id,
        });
        self.persistPayment(response.razorpay_payment_id);
      },
      prefill: {
        email: cartEmail || email,
        "contact": phone,
        "name": `${firstName} ${lastName}`,
      },
      theme: {
        color: "#F37254"
      },
      modal: {
        ondismiss: function() {
          ReactGA.event({
            category: 'E-Commerce Action',
            action: `Razorpay cart payment failed`,
            label: id,
          });
          FbPixel.trackCustom('PublicationPurchaseCancelled', {
            'product_type': 'publication',
            'content_type': 'product',
            'content_ids': contentIds,
            'contents': contents,
            'currency': currency, 
            'value': amount,
            'num_items': totalQuantity
          });
          self.setState({
            status: ""
          });
        }
      }
    };
  }

  createDraftOrder(checkoutId){
    return this.props.client
      .mutate({
        mutation: CREATE_DRAFT_ORDER,
        variables: {
          checkoutId,
        }
      })
  }

  async initiatePayment() {
    const {
      cart: { checkoutId, lines, totalPrice: { net: { amount } = {} } = {} } = {},
      currency,
    } = this.props;
    if(amount <= 0) {
      return this.completeCheckout();
    }
    this.createDraftOrder(checkoutId)
      .then(({ data, errors }) => {
        const {
          currency,
          cart: {
            lines,
            totalPrice: {
              net: { amount }
            },
            totalQuantity,
          }
        } = this.props;
        const contentIds = lines.map((line) => line.variant.product.id);
        const contents = lines.map(
          (line) => ({
            "id": line.variant.product.id,
            "quantity": line.quantity,
          })
        );
        if (this.errors && errors.length > 0) return this.props.warningNotification("Something went wrong, please refresh")
        this.options.currency = currency;
        this.options.amount = amount * 100;
        this.options.order_id = `${data.createDraftOrderForCustomer.razorpayOrderId}`;
        // this.options.order_id = "order_FT2yUNJET1n1Xj";
        this.options.notes = lines.reduce((acc, line) => (
          acc.concat(`${line.variant.product.name}(Digital: ${line.variant.isDigital ? "Yes" : "No"})(Quantity: ${line.quantity})`)
        ), []);
        this.options.notes = this.options.notes.slice(0,14)
        const razpay = new window.Razorpay(this.options);
        razpay.on('payment.failed', (response) => {
          ReactGA.event({
            category: 'E-Commerce Action',
            action: `Razorpay cart payment failed`,
            label: response.razorpay_payment_id,
          });
          FbPixel.trackCustom('PublicationPurchaseFailed', {
            'product_type': 'publication',
            'content_type': 'product',
            'content_ids': contentIds,
            'contents': contents,
            'currency': currency, 
            'value': amount,
            'num_items': totalQuantity
          });
          return this.setState({
            status: "PAYMENT_FAILURE"
          });
        });
        razpay.open();
        this.setState({
          status: "PAYMENT_STARTED"
        });
      });
  }

  persistPayment(paymentId) {
    const self = this;
    const {
      client,
      cart: {
        checkoutId,
        shippingAddress,
        // TODO: use this.
        totalPrice: {
          net: { amount }
        }
      }
    } = this.props;
    client
      .mutate({
        mutation: SAVE_PAYMENT,
        variables: {
          checkoutId,
          input: {
            gateway: "RAZORPAY",
            token: paymentId,
            amount
          }
        }
      })
      .then(({ errors }) => {
        if (errors && errors.length > 0) {
          ReactGA.event({
            category: 'E-Commerce Action',
            action: `Failed to save payment to saleor`,
            label: errors,
          });
          return self.setState({
            status: "PAYMENT_PERSISTENCE_FAILURE"
          });
        }
        ReactGA.event({
          category: 'E-Commerce Action',
          action: `Saved payment to saleor`,
          label: checkoutId,
        });
        self.setState({
          status: "PAYMENT_SUCCESS"
        });
        self.completeCheckout();
      });
  }

  completeCheckout() {
    const self = this;
    const {
      client,
      cart: { checkoutId },
      resetCart,
      clearCartCache,
    } = this.props;
    client.mutate({
      mutation: COMPLETE_CHECKOUT,
      variables: {
        checkoutId,
      }
    }).then(({
      data: {
        checkoutComplete: {
          order: {
            id,
            visibleOrderId,
            shippingAddress,
            totalInr: {
              net: netInr,
            },
            totalUsd: {
              net: netUsd,
            },
            lines,
          },
          errors,
        }
      }
    }) => {
      if(errors.length > 0) {
        self.setState({
          status: "PAYMENT_PERSISTENCE_FAILURE",
        });
        ReactGA.event({
          category: 'E-Commerce Action',
          action: `Failed to complete checkout to saleor`,
          label: errors,
        });
        return false;
      }
      self.setState({
        shippingAddress,
        visibleOrderId,
        totalPrice: netInr.amount > 0 ? netInr : netUsd,
        orderId: id,
        status: "PAYMENT_PERSISTED",
      });

      this.props.setCheckoutStatus('SUCCESS', visibleOrderId)
      ReactGA.event({
        category: 'E-Commerce Action',
        action: `Order created successfully`,
        label: id,
      });
      
      ReactGA.plugin.execute('ecommerce', 'addTransaction', {
        'id': id,
        'affiliation': 'Marg',
        'revenue': netInr.amount > 0 ? netInr.amount : netUsd.amount,
        'shipping': 0,
        'tax': 0,
        'currency': this.props.currency
      });

      ReactGA.plugin.execute('ecommerce', 'send');
      clearCartCache();
      return resetCart();
    })
  }

  shippingAddressIsSelected() {
    const {
      cart: {
        shippingAddress
      } = {},
    } = this.props;
    return shippingAddress && Object.entries(shippingAddress).length > 0
  }

  checkIfBagHasPrintAndShippingMethodIsSelected() {
    const {
      lines,
      shippingMethod,
    } = this.props.cart;
    const printIsAvailable = lines.find(({variant: {isDigital}}) => !isDigital);
    if(!printIsAvailable) {
      return null
    }
    return printIsAvailable && Object.keys(shippingMethod).length > 0;
  }

  render() {
    const {
      status,
    } = this.state;
    const {
      cart: {
        checkoutId = null,
        totalPrice: { net: { amount } = {} } = {}
      } = {}
    } = this.props;
    return (
      <Wrapper className="container">
        <div className="row align-items-center justify-content-center">
          {
            <RaisedButton
            className="btn-hover"
              onClick={e => {
                this.initiatePayment();
              }}
              disabled={
                !checkoutId
                ||
                status === "PAYMENT_STARTED"
                ||
                !this.shippingAddressIsSelected()
                ||
                this.checkIfBagHasPrintAndShippingMethodIsSelected() == false
              }
            >
              {
                status === "PAYMENT_STARTED" && amount > 0 && "PAYMENT IN PROGRESS..."
              }
              {
                status !== "PAYMENT_STARTED" && amount > 0 && "MAKE PAYMENT"
              }
              {
                amount <= 0 && "Complete order"
              }
            </RaisedButton>
          }
        </div>
      </Wrapper>
    );
  }
}


