import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';


const Wrapper = styled.div`

  animation: supportFadeIn ease 1s;

  @keyframes supportFadeIn { 
  0% { 
  opacity: 0;
  transform: translate3d(0, -2%, 0);
  } 
  100% { 
  opacity: 1;
  transform: none;
  } 
  }
`;

export const Supporters = props => (
  <Wrapper>
    <div className="container mt-5 text-center">
      <h1 className="heading my-5">{props.supporters && props.supporters.pageData && props.supporters.pageData.title}</h1>
      {ReactHtmlParser(props.supporters.pageData.content)}
    </div>
  </Wrapper>
)










