import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';

import { RaisedButton, FlatButton } from '../commons';
import { Link } from 'react-router-dom';

import default_profile from '../../images/default_profile.jpeg';
import anand from '../../images/anand.png';
import ReactHtmlParser from 'react-html-parser';


const Wrapper = styled.div`
  
  animation: teamFadeIn ease 1s;

  @keyframes teamFadeIn { 
  0% { 
  opacity: 0;
  transform: translate3d(0, -2%, 0);
  } 
  100% { 
  opacity: 1;
  transform: none;
  } 
  }

  div.header {
    font-family: ${props => props.theme['$font-secondary-medium']};
    font-size: ${props => props.theme['$font-size-lg']};
    font-weight: ${props => props.theme['$weight-regular']};
    letter-spacing: 1px;
    line-height: 57px;
    padding: 100px 0px 60px;
    text-align: center;
  }

  div.img-container {
    width: 50%;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 40px;
  
    img {
      object-fit: contain;
      object-position: right;
    }
  }

  div.meta {
    padding-top: 30px;

    div.sub-info {
      color: ${props => props.theme.secondaryColor};
      font-size: ${props => props.theme['$font-size-xxs']};
      font-weight: ${props => props.theme['$weight-regular']};
      letter-spacing: 0.59px;
      line-height: 23px;
    }

    div.name {
      font-size: ${props => props.theme['$font-size-xs']};
      font-weight: ${props => props.theme['$weight-bold']};
      line-height: 23px;
    }

    div.description {
      font-size: ${props => props.theme['$font-size-xxs']};
      font-weight: ${props => props.theme['$weight-regular']};
      letter-spacing: 0.59px;
      line-height: 23px;
      padding-top: 20px;
    }
  }

`;

const PersonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  img {
    width: 100%;
    object-fit: cover;
    height: 275px;
  }
  
  @media (min-width: 768px) {
    img {
      height: 225px;
    }
  }

  .sub-info {
    letter-spacing: 0.59px;
    line-height: 23px;
    text-transform: uppercase;
    font-size: ${props => props.theme['$font-size-xxxs']};
    font-weight: ${props => props.theme['$weight-regular']};
  }

  .name {
    font-size: ${props => props.theme['$font-size-xxxs']};
    font-weight: ${props => props.theme['$weight-bold']};
    line-height: 23px;
  }
`;


const PersonDetails = ({ name, bio, designation, openBio, photo }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (<PersonWrapper className="col-6 col-md-4 col-lg-3 col-xl-2 my-4">
        <img className="img-fluid" src={photo} />
        <div className="name mt-2">
            {name}
        </div>
        <div className="sub-info">
            {designation}
        </div>
        {
            openBio &&
            <FlatButton onClick={(e) => setIsOpen(!isOpen)}>Know More</FlatButton>
        }
        <Modal isOpen={isOpen} style={{ marginTop: 100 }}>
            <ModalHeader toggle={() => setIsOpen(!isOpen)}>
                <div>{name}</div>
                <p className="mb-0" style={{ fontSize: 16 }}>{designation}</p>
            </ModalHeader>
            <ModalBody>
                {ReactHtmlParser(bio)}
            </ModalBody>
        </Modal>
    </PersonWrapper>);
}


const PersonsCategory = ({title, categoryName, persons, openBio}) => {

  const visiblePersons = persons
  .sort(({ sortOrder: sortOrderA }, { sortOrder: sortOrderB }) => sortOrderA - sortOrderB)
  .filter(person => person.category.name === categoryName);
  if(!visiblePersons || visiblePersons.length === 0) return null;

  return [
    <h1 className="heading my-5">{title}</h1>,
    <Row className="justify-content-center">
      {
        visiblePersons.map((employee) => (<PersonDetails {...employee} openBio={openBio} />))
      }
    </Row>
  ]
}

export const Team = props => {
    return <Wrapper>
        <div className="container my-5">
            <h1 className="heading mb-5">Marg Team</h1>
            <Row className="content justify-content-center">
                <Col xs={12} md={4} className="img-container">
                    <img className="img-fluid" src={anand} />
                </Col>
                <Col xs={12} md={4}>
                    <div className="meta">
                        <div className="sub-info">
                            1905–2004
                        </div>
                        <div className="name">
                            Mulk Raj Anand
                        </div>
                        <div className="sub-info">
                            FOUNDER EDITOR
                        </div>
                        <div className="description">
                            Philosopher, litterateur, and social activist, he was the Founder Editor of Marg. An avowed
                            nationalist and modernist, under his leadership, for over 30 years, Marg undertook the
                            massive task of identifying, cataloguing, and publicizing the nation’s heritage in the
                            built, visual, and performing arts, seeking to engender public debates about museums,
                            monuments, urban planning, art education and questions of heritage.
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-center my-5">
                {
                    // props.team.persons.map(person => <p>HELLO</p>)
                    props
                      .team
                      .persons
                      .sort(({sortOrder: sortOrderA}, {sortOrder: sortOrderB}) => sortOrderA - sortOrderB)
                      .filter(person => person.category.name === 'DIRECTOR')
                      .map((employee) => (<PersonDetails {...employee} openBio />))
                }
            </Row>
            <Row className="justify-content-center">
                {
                    props
                      .team
                      .persons
                      .sort(({sortOrder: sortOrderA}, {sortOrder: sortOrderB}) => sortOrderA - sortOrderB)
                      .filter(person => person.category.name === 'TEAM')
                      .map((employee) => (<PersonDetails {...employee} />))
                }
            </Row>
            <Container>
            <PersonsCategory title={"Consultants"} persons={props.team.persons} categoryName="CONSULTANT" />
            <PersonsCategory title={"Past Leadership"} persons={props.team.persons} categoryName="PAST LEADERSHIP" openBio={true} />
            </Container>
        </div>

    </Wrapper>
}