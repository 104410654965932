import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { withRouter } from "react-router";
import FlatButton from "../../commons/FlatButton";
import { replaceStaticUrl } from "../../../utils";
const Container = styled.div`
  .cover-blog-img {
    object-fit: cover;
    cursor: pointer;
    max-width: 100%;
    max-height: 450px;
    min-height: 450px;
    height: 450px;
  }
  .hero-title {
    line-height: 40px !important;
  }
  .date-author-color {
    color: ${(props) => props.theme.secondaryColor};
  }
`;
const Category = styled.div`
  div:not(:last-of-type)::after {
    content: ",\u00A0";
   }
   div:hover {
    text-decoration: underline;
  }
`;
export const HeroCard = withRouter(
  ({ blog, handle_selected_category, history: { push } }) => {
    return (
      <Container className="col-12">
        <div className="Container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-7 col-md-5">
              <Link to={`/blog/${blog.slug}`}>
                <img
                  className="cover-blog-img img-fluid"
                  src={replaceStaticUrl(blog.image)}
                />
              </Link>
            </div>
            <div className="col-lg-5 col-md-7">
              <Link to={`/blog/${blog.slug}`}>
                <h3 className="mt-2 BlogTitle hero-title">{blog.title}</h3>
              </Link>
              <Link to={`/blog/${blog.slug}`}>
                <div className="row ml-1 mt-3">
                  <div className="meta date-author-color">
                    {blog.publicationDate}
                  </div>
                  <div className="date-author-color">
                    &nbsp;|&nbsp;
                  </div>
                  <div className="date-author-color">By:&nbsp;</div>
                  {
                    blog.authors.map(({name}) => name)
                      .reduce((acc, name, id) => {
                        console.log(id);
                        if(id < blog.authors.length - 1) {
                          return acc.concat(`${name},\u00A0`);
                        }
                        return acc.concat(name);
                      }, [])
                      .map((name) => <div className="date-author-color">{name}</div>)
                  }
                </div>
              </Link>
              <div className="mt-2 ml-1">
                <p className="mb-0 description">
                  {ReactHtmlParser(blog.textDescription)}
                </p>
                <Category className="row mt-2 ml-0">
                  {blog.categories.map((category, i) => (
                    <div style={{color:'grey', cursor: 'pointer'}}
                      onClick={() => {
                        handle_selected_category(category);
                      }}
                    >
                      {category.name}
                    </div>
                  ))}
                </Category>
                  <Link to={`/blog/${blog.slug}`}>
                  <FlatButton className="mt-2 post post-btn">
                    Read Post
                  </FlatButton>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
);