import React,{ Component,Fragment } from 'react'
import {  Row, Col } from "reactstrap";
import gql from "graphql-tag";
import styled from 'styled-components';
import { Link, animateScroll } from 'react-scroll';
import FlatButton from "../../commons/FlatButton";
import FoundingMagazineBackgroungImg from '../../../images/Ourhistory/Bitmap.png';
import MulkRajAnand from '../../../images/Ourhistory/0.png';
import FirstIssue  from '../../../images/Ourhistory/01.png';
import MargCommunity  from '../../../images/Ourhistory/02.png';
import Architecture  from '../../../images/Ourhistory/03.png';
import TimelineNav from './timelineNav';

const Wrapper = styled.div`

  padding:2%;
  & h6{
    color: #000000;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.18px;
    text-transform: uppercase;
  }
  & h2{
    color: #000000;
    font-family: ${props => props.theme['$font-secondary-medium']};
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 57px;
  }

  & section {
    padding:0;
    background-image:url(${FoundingMagazineBackgroungImg});
    width:100%;
    height:100%;
    .layer {
      background-color: rgba(255, 255, 255,0.95);
      width: 100%;
      height: 100%;
      padding:2% 10% 0%;
  }
  }

  & h1{
    color: #ec1d24;
    font-family: ${props => props.theme['$font-secondary-medium']};
    font-size: 3.5rem;
    font-weight: 500;
    letter-spacing: 1.36px;
    line-height: 57px;
    margin:2rem 0;
  }
  .founded{
    padding: 10% 10%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media (max-width: 768px) {
    padding:3%;
    }
    h6{
      color: #3a3a3a;
      font-family: Lato;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .bullets:before {
    content: "◉";
    cursor: pointer;
    color: grey;
  }

  .navigation {
    max-width: 500px;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .historical-img {
    width: 430px;
    height: 330px;
    object-fit: cover;
    object-position: top;
    @media (max-width: ${props => props.theme["mobileBreakpoint"]}) {
      width: 100%;
      height: 80%;
    }
  }

  .colored-bullets:before {
    color: black;
  }

  .hr-line:first-child, .hr-line:last-child{
    display: none;
  }
`;



const timelineSection = ({ currentIndex, historical, handleToggle }) => {
  


    const historyTimeline = historical;

        var historicalData=[];
        for(var i=0; i<historyTimeline.length; i++){
          historicalData.push(
              <>
              <TimelineNav index={i} historical={historyTimeline} handleToggle={handleToggle} currentIndex={currentIndex} />
              </>
            )
  }

    return(
        <Fragment>
          {
            historyTimeline.reduce((acc, timeline, i) => {
              if(i !== currentIndex) {
                return acc;
              }
              return(
                <Wrapper>
                  
                  <section>
                    <div className="layer">
                    <div className="row justify-content-center mt-5">
                      <h6>The Marg Foundation</h6>
                      <h6>,&nbsp;</h6>
                      <h6>A Historical Timeline</h6>
                    </div>
                    <h1 className="text-center pb-5 mt-0 mb-4">{timeline.sectionHeader}</h1>
                    <Row className="pb-5">
                      <Col md='6' style={{ display: 'flex'}}>
                        <div className="text-left founded">
                          <h6>
                            {timeline.header}
                          </h6>
                          <p>
                          {
                            timeline.description
                          }
                          </p>
                          <FlatButton onClick={() => handleToggle((i + 1) % historyTimeline.length)}>
                            NEXT
                            {
                              timeline.nextText && <b><span className="text-dark"> - {timeline.nextText}</span></b>
                            }
                          </FlatButton>
                        </div>
                      </Col>
                      <Col md="6">
                          <img src={timeline.image} className="image-fluid historical-img"  />
                      </Col>
                    </Row>
                    <div className="row text-center d-flex navigation">
                    {historicalData}
                      </div>
                    </div>
                  </section>
                </Wrapper>
              )
            }, null)
          }

        </Fragment>
    )

}
export default timelineSection;
