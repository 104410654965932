import { takeLatest, call, put, select } from "redux-saga/effects";
import gql from 'graphql-tag';
import axios from 'axios';
import { getLocalizedAmountBySymbol } from "./../utils/";

import actions from './../actions';

const TOKEN_VERIFY = gql(`
  mutation TokenVerify($token: String!) {
    tokenVerify(token: $token) {
      payload,
    }
  }
`);

const GET_VARIANTS = gql`
  query GetVariants($ids: [ID]!, $first: Int){
    productVariants(ids: $ids, first: $first){
      edges{
        node{
          id
          isDigital
          inrPrice{
            currency
            amount
            localized
          }
          usdPrice{
            currency
            amount
            localized
          }
          product{
            id
            name
            thumbnail{
              url
            }
            images{
              url
            }
          }
        }
      }
    }
  }
`

export const getUserFromState = (state) => state.auth;

export function* stateRehydrateSaga() {
  yield takeLatest("REHYDERATE_STATE_FROM_CACHE", rehyderateUserFromSession);
}

function fetchLoggedInUser(client) {
  const authToken = localStorage.getItem('authToken');
  if (authToken === null) {
    return undefined;
  }
  return client.mutate({
    mutation: TOKEN_VERIFY,
    variables: {
      token: authToken,
    }
  })
}

function* listVariants(client, variantIds) {
  return yield call(
    () => client.query({
      query: GET_VARIANTS,
      variables: {
        ids: variantIds,
        first: 100,
      }
    })
  )
}

function getCountryInfo() {
  return axios.get("https://api.ipdata.co/?api-key=3b163c4a51bbeffc1bdb85cbbbf317cfe4ae7e90aac562de43cc5c23")
}

function* setCurrencyPreference() {
  try {
    const {
      data: {
        country_code,
      }
    } = yield call(getCountryInfo);
    yield put(
      actions.setCurrency(country_code === "IN"? "INR": "USD")
    )
  } catch (e) {
    console.log("Error fetching currency.", e);
    yield put(
      actions.setCurrency("INR")
    )
  }
}

function* rehyderateUserFromSession({ client }) {
  yield call(
    () => setCurrencyPreference()
  );
  const {currency} = yield select(getUserFromState);
  try {
    const {
      data: {
        tokenVerify: {
          payload: {
            email,
          }
        }
      }
    } = yield call(fetchLoggedInUser, client);
    const authToken = localStorage.getItem('authToken');

    yield put(
      actions.persistAuthenticatedUserToState({
        email,
        authToken,
      })
    );
  
  } catch (e) {
    yield put(actions.loginFailure());

    //Fetch cart info from local storage
    const lines = JSON.parse(localStorage.getItem('cart')) || [];
    if(!lines || lines.length === 0) {
      return;
    }
    yield put(
      actions.persistCartFromLocalCache({
        lines,
      })
    );
  }
}

export function* sessionPersistanceSaga() {
  yield takeLatest("PERSIST_AUTHENTICATED_USER", setSessionInCache);
}

function* setSessionInCache({ user }) {
  try {
    localStorage.setItem('authToken', user.authToken);
    yield put(actions.persistAuthenticatedUserToState(user));
  } catch(e) {
    yield put(actions.loginFailure());
  }
}

export function* logoutUser() {
  yield takeLatest("LOGOUT", removeUserFromLocalCache);
}

function* removeUserFromLocalCache() {
  localStorage.removeItem('authToken');
  yield put(
    actions.resetCart()
  );
}