import React from "react";
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 40px;

  .header {
    font-family: ${props => props.theme['$font-secondary-medium']};
  }
`

export const PrivacyPolicy = () => (
  <Wrapper>
    <h1 className="header">Privacy Policy</h1>
    <p>Please read this Privacy Policy carefully before using our website - www.marg-art.org ("<b>Website</b>"). This Website is operated by The Marg Foundation ("<b>Marg</b>" or "<b>We</b>"), a public trust registered under the Maharashtra Public Trusts Act, 1950 having its registered office at Army &amp; Navy Building, 3rd Floor, 148, Mahatma Gandhi Road, Mumbai 400001, India. By using or accessing the services provided by the Website you agree to the collection and use of your information by the Website in the manner provided in this Privacy Policy. This Privacy Policy is binding on every user of the Website and users who do not agree with the terms of this Privacy Policy should not use or access the Website.</p>
    <p>This Privacy Policy being an electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
    <div className="space15"> </div>
    <p><b>(1) VISIT</b></p>
    <p>Generally, you may visit our Website and obtain information about us and our products and services without providing any personal information, such as your phone number or postal or e-mail address.</p>
    <div className="space15"> </div>
    <p><b>(2) INFORMATION</b></p>
    <p>In a few areas on our Website, we ask you to provide information that will enable us to enhance your Website visit, or to follow up with you after your visit.</p>
    <p>For example, we request information from you when you:</p>
    <ol className="ordered-list lower-roman">
    <li>Register with us</li>
    <li>Place an order</li>
    <li>Provide feedback</li>
    <li>Request your order status</li>
    <li>Participate in any offers or contests or communicate with our customer support</li>
    </ol>
    <p>In each or any of the above instances, your first and last name, e-mail address, telephone and mobile number, postal address, city, country, and other similar contact data, that is needed to register or subscribe to services or offers provided on the Website shall be required by Marg.</p>
    <p>You agree to provide true, accurate, current and complete information. If you provide any information that is untrue, inaccurate, not current or incomplete, or Marg has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Marg has the right to refuse any service (or any portion thereof) without any liability to it.</p>
    <p>Our Website automatically receives and records information on our server logs from your browser, including your IP address. Our Website automatically receives and stores certain types of information whenever you interact with us. For example, like many websites, we use "cookies", and we obtain certain types of information when your web browser accesses our Website. "Cookies" are small data files that your browser gathers on your computer's hard drive. A cookie file can contain information, such as a user identification code, that a website will use to track the pages and number of times you have visited. Our Website server draws on these cookies inter alia to recognize our repeat customers and keep a tab on usage, through our Website. Cookies are a useful element in the service process and help us to improve and develop the services and orientation towards our valued customers or Website users.</p>
    <p>You may, at any time, prevent the setting of cookies through our Website by means of a corresponding setting of the Internet browser used, and may thus permanently deny the setting of cookies. Furthermore, already set cookies may be deleted at any time via an Internet browser or other software programs. This is possible in all popular Internet browsers. If you deactivate the setting of cookies in the Internet browser used, not all functions of our Website may be entirely usable.</p>
    <div className="space15"> </div>
    <p><b>(3) USE OF PERSONAL INFORMATION</b></p>
    <p>The personal information you provide will be kept confidential and used to support your customer relationship with Marg.</p>
    <p>We may use the details you provide to:</p>
    <ol className="ordered-list lower-roman">
    <li>inform you of special offers, updates, upgrades</li>
    <li>respond to your queries, requests and inquiries, improve and customize Website services, detect and prevent fraud and abuse of the Website, examine identity of user;</li>
    <li>administer the Website, send surveys and marketing communication, personalize user experience on the Website, help the user address its problems with the Website;</li>
    <li>complete and fulfill your orders, process payment, customer service, protect the integrity of the Website and other services that may be of interest to you.</li>
    </ol>
    <p>We may use the information for these purposes for which consent is taken appropriately or another appropriate lawful basis for processing is identified. If you do not want to receive information about special offers, updates, upgrades, newsletters, mailing lists from us or our partners and other organizations, you will be given the opportunity to "opt out".</p>
    <p>You can make choices about our collection and use of your data. For instance you may wish to edit or remove the personal information, change your password or change your user name. When you are asked to provide personal information you may choose to decline. The data we collect depends upon the choices you make and the privacy setting you opt for. You have the right to withdraw consent at any time, but this will not affect any prior processing of your personal information. You may request that such information be taken down and we may attempt to remove any such information if it is technically feasible to do so and as per the applicable laws. Please write to our Grievance Officer on the email id mentioned in this Privacy Policy.</p>
    <p><b>Personal Information will not be retained for a period more than necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law or for directly related legitimate business purposes.</b></p>
    <p>The personal data of customers and Website visitors is stored and may be collected and processed in one or more databases maintained directly or indirectly by Marg. By providing personal information to Marg, you agree and understand that Marg and its partners and associates may access, store and use this information for a variety of purposes including identification, registration, authentication, online transactions and distribution in any of the countries in which Marg and its partners and associates maintain offices or have activities.</p>
    <p>We use third party service providers to provide various incidental and ancillary services, such as payment gateway facilitators, processing bank(s), credit card processing, e-mail service providers and hosting services on our Website. We will share your sensitive personal data or information or personal information as necessary for the third party to provide service. These third parties are prohibited from using your personally identifiable information for any other purpose.</p>
    <p>We may disclose personal information if required to do so by law or in the good-faith belief that such disclosure is reasonably necessary to respond to notices, court orders or other legal process. We may disclose personal information to law enforcement offices, third party rights owners or others in the good-faith belief that such disclosure is reasonably necessary to: enforce our Terms of Sale, Terms of Use and Privacy Policy; respond to claims; or protect the rights, property or personal safety of our users or the general public. We will share or transfer some or all of your personal information with another entity should we merge with, or be acquired by, another entity.</p>
    <p>We believe it is necessary to share information in order to investigate, prevent or take action regarding illegal or fraudulent activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Terms of Sale, Terms of Use and Privacy Policy or as otherwise required by law for protection of our rights or property or of those associated or concerned, including payment gateway facilitators, processing bank(s), customers and/or those connected with our Website. In response to a request by law enforcement or other government officials relating to any investigation or alleged illegal activity that may expose us or you to legal liability, or as directed by a court, or other government or regulatory authority, we will disclose your personal data and such other information.</p>
    <p>You consent to the sharing of your personal information in the manner as aforesaid for using our Website or availing our services.</p>
    <p>You agree and acknowledge that personal information collated through the Website could be stored and processed in any other country across the world. You acknowledge that for effective provision of the Website’s services in respective territories including territories outside India, the personal information may be stored or processed by trusted third parties. Whenever the personal information is shared with trusted third parties in countries outside India, Marg endeavours that the personal information is transferred in accordance with the applicable laws of India, and such third parties have in place adequate and reasonable security systems and measures to protect personal data.</p>
    <p>You agree and acknowledge Marg’s right to utilize the user’s posts, contents, information and other personal data for the purpose of providing services through the Website. The contents on the Website may contain comments, posts and reviews posted by you which may be searchable on the internet and viewed by the general public in which case your comments, posts, reviews and information and personal data provided to the Website may be viewed by other users and Marg cannot guarantee that other users or third parties have not made copies of or used the ideas and information that you have shared on the Website.</p>
    <p>Our Website may contain links to other websites. The linked websites are not necessarily under the control of Marg. Marg is not in any way responsible for the privacy practices or content of such websites and hereby expressly disclaims all liability associated with the content and use thereof. Marg encourages you to be aware when you leave this Website and to read the privacy policies of each and every linked website that collects your personally identifiable information. If you decide to access any of the third party websites linked to this Website, you will do so entirely at your own risk. Any links to any associate or partner of this Website is the responsibility of the linking party, and Marg shall not be responsible for notification of any change in name or location of any information on the website or liable for any actions, claims, losses or damages arising thereto.</p>
    <p>Notwithstanding anything contained in this Privacy Policy or elsewhere, Marg shall not be held responsible for any loss, damage or misuse of the personal information, if such loss, damage or misuse is attributable to a Force Majeure Event. A "Force Majeure Event" means any event that is beyond the reasonable control of Marg and includes, without limitation, fire, flood, explosion, acts of God, civil commotion, strikes, lock outs or industrial action of any kind, riots, insurrection, war, embargo or acts of government, change in government policy, orders of court or any other binding authority, power failure, sabotage, computer hacking, unauthorized access to computer data and storage device, system failure, virus attacks, bugs, computer crashes, breach of security and encryption.</p>
    <p><b>GLOBAL OPERATIONS</b></p>
    <p>Marg complies with data protection laws in the European Economic Area, which if applicable includes the following rights:</p>
    <ol className="ordered-list lower-roman">
    <li>You have a right to withdraw consent at any time for future processing in a case where processing of personal information is based on your consent;</li>
    <li>You have right to object to the processing of your personal information and to request access to and rectification of your personal information;</li>
    <li>You have a right to request that your personal information is erased, subject to certain exceptions; and</li>
    <li>You have a right to lodge a complaint under the said data protection law.</li>
    </ol>
    <p><b>POLICY TOWARDS CHILDREN</b></p>
    <p>Our Service is not directed to children under 13 years of age. If you are below 13 years of age you should not use or access our Service unless supervised by an adult. We do not knowingly collect personally identifiable information from children under 13 years. If a parent or guardian becomes aware that his or her child has provided us with Personal Information without their consent, he or she should contact us. If we become aware that a child under 13 years has provided us with personal information, we will delete such information from our files. By availing our services you represent and warrant that you are 13 years of age or older.</p>
    <div className="space15"> </div>
    <p><b>(4) CHANGES TO THIS POLICY</b></p>
    <p>Marg may revise or substitute this Privacy Policy at any time. We encourage you to periodically review this page for the latest information on our privacy practices. Your continued use of the Website after we make any modifications to the Privacy Policy will constitute your acknowledgement of the modifications and your consent to abide and be bound by the modified Policy.</p>
    <p>This Privacy Policy will be read in conjunction with and shall be deemed to be part of the Terms of Use.</p>
    <div className="space15"> </div>
    <p><b>(5) GRIEVANCE OFFICER</b></p>
    <p>In case of any complaints or grievances, you may contact The Marg Foundation at the following address: <br /> The Grievance Officer, The Marg Foundation, <br /> Army &amp; Navy Building, 3rd Floor, <br /> 148, Mahatma Gandhi Road, <br /> Mumbai 400001, India. <br /> E-mail Id: <a className="txt_666" href="mailto:foundation@marg-art.org"><u>foundation@marg-art.org</u></a></p>
    <div className="space15"> </div>
    <p><b>(6) GOVERNING LAW AND JURISDICTION</b></p>
    <p>The terms and conditions of this Privacy Policy shall be governed by Indian law and the courts in Mumbai only shall have exclusive jurisdiction to resolve any disputes.</p>
  </Wrapper>
)